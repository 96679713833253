import React, { useState } from "react";
import styled from "styled-components";
import { Link } from "@reach/router";
import { IconBoxStyled } from "../IconBox/styled";

export const ContextMenuLink = styled((props) => {
  const [active, setActive] = useState(false);
  const isActive = ({ isPartiallyCurrent }) => {
    setActive(false);
    if (isPartiallyCurrent) {
      setActive(true);
      return {
        style: {
          backgroundColor: "rgba(0, 69, 245, 0.2)",
          color: "#0045F5",
        },
      };
    }
  };

  return (
    <>
      {props.newTab ? (
        <a
          href={props.to}
          target="_blank"
          rel="noopener noreferrer"
          className={`${props.className} ${active ? "active-link" : ""}`}
        >
          {props.children}
        </a>
      ) : (
        <Link
          getProps={isActive}
          to={props.to}
          className={`${props.className} ${active ? "active-link" : ""}`}
        >
          {props.children}
        </Link>
      )}
    </>
  );
})`
  display: block;
  display: flex;
  color: ${(props) => props.theme.colors.navy};
  font-size: ${(props) => props.theme.fontSizes.md};
  font-weight: 400;
  display: flex;
  align-items: center;
  height: 60px;
  min-height: 60px;
  background-color: transparent;

  &::hover {
    background-color: rgba(0, 69, 245, 0.2);
    color: ${(props) => props.theme.colors.royalBlue};
    svg {
      color: ${(props) => props.theme.colors.royalBlue};
      rect {
        stroke: ${(props) => props.theme.colors.royalBlue};
      }
    }
    ${(props) =>
      props.admin &&
      `
            background-color: ${props.theme.colors.royalBlue};
        `}
  }

  ${IconBoxStyled} {
    margin-right: ${(props) => props.theme.space.xs};
    margin-left: ${(props) => props.theme.space.xs};
    padding: 0;
  }
`;
