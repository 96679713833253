import React from "react";
import { CustomTableContainer } from "components/CustomTable";
import InvoiceHeaderRow from "./InvoiceHeaderRow";
import InvoiceContentRow from "./InvoiceContentRow";

export const InvoiceTable = ({ datas, ...props }) => {
  console.log("testing table", datas);
  return (
    <CustomTableContainer
      datas={datas}
      perPage={3}
      Header={InvoiceHeaderRow}
      Content={InvoiceContentRow}
      {...props}
    ></CustomTableContainer>
  );
};

export default InvoiceTable;
