import React, { useState } from "react";
import { GuessMenuLink } from "./GuestMenuLink";
import { ReactComponent as InfoIcon } from "../../icons/info2.svg";
import { ReactComponent as SearchIcon } from "../../icons/search3.svg";
import { ReactComponent as DocumentIcon } from "../../icons/document2.svg";
import {
  GuestSidebarWrapper,
  InfoWrapper,
  PaidFeaturesWrapper,
} from "./styled";
import { Divider } from "../Divider";
import { Button } from "../Button";
import { SearchUpgradeModal } from "../GuestModal/SearchUpgradeModal/SearchUpgradeModal";
import { ShortlistUpgradeModal } from "../GuestModal/ShortlistUpgradeModal/ShortlistUpgradeModal";
import { UpgradeAccountModal } from "../GuestModal/UpgradeAccountModal/UpgradeAccountModal";

export const GuestSidebar = ({ guestUser }) => {
  const [isSearchModalOpen, setIsOpenSearchModal] = useState(false);
  const [isShortlistModalOpen, setIsShortlistModalOpen] = useState(false);
  const [isUpgradeAccountModalOpen, setIsUpgradeAccountModalOpen] =
    useState(false);
  const sharedProfiles = guestUser.shared_profiles || [];
  return (
    <GuestSidebarWrapper>
      <div>
        <div className="company-list">
          {sharedProfiles.map((profile) => (
            <>
              <GuessMenuLink to={`/company/${profile.company_id}`}>
                <img
                  src={
                    profile.company.logo_path ||
                    `https://ui-avatars.com/api/?name=${profile.company?.name}&color=fff&background=000`
                  }
                  alt="logo"
                  height="56px"
                  width="56px"
                />
                {profile.company.name}
              </GuessMenuLink>
              <Divider color="#E9ECF1" />
            </>
          ))}
        </div>
        <InfoWrapper>
          <InfoIcon />
          <span>
            Your Guest Account is restricted to 3 profiles. To view more
            companies please upgrade your Account.
          </span>
        </InfoWrapper>
      </div>
      <PaidFeaturesWrapper>
        <Divider color="#E9ECF1" />
        <div id="menu1">
          <GuessMenuLink
            text
            onClick={() => setIsOpenSearchModal(true)}
            height="60px"
          >
            <SearchIcon />
            Search & Compare
          </GuessMenuLink>
        </div>
        <Divider color="#E9ECF1" />
        <div id="menu2">
          <GuessMenuLink
            text
            onClick={() => setIsShortlistModalOpen(true)}
            height="60px"
          >
            <DocumentIcon />
            Shortlist Report
          </GuessMenuLink>
        </div>
        <Divider color="#E9ECF1" />
        <div className="account-details">
          <div className="details">
            <p>Guest Account</p>
            <p className="email">{guestUser.email}</p>
          </div>
          <Button
            size="sm"
            type="button"
            width="260px"
            height="56px"
            boxshadow={false}
            onClick={() => setIsUpgradeAccountModalOpen(true)}
          >
            UPGRADE ACCOUNT
          </Button>
        </div>
      </PaidFeaturesWrapper>
      <SearchUpgradeModal
        guestEmail={guestUser.email}
        isOpen={isSearchModalOpen}
        setIsOpen={setIsOpenSearchModal}
      />
      <ShortlistUpgradeModal
        guestEmail={guestUser.email}
        isOpen={isShortlistModalOpen}
        setIsOpen={setIsShortlistModalOpen}
      />
      <UpgradeAccountModal
        guestEmail={guestUser.email}
        isOpen={isUpgradeAccountModalOpen}
        setIsOpen={setIsUpgradeAccountModalOpen}
      />
    </GuestSidebarWrapper>
  );
};
