import React from "react";
import { Heading } from "../Heading";
import { Button } from "components/Button";
import { ConfirmCancelStyled, ButtonRow, DialogConfirm } from "./styled";


function ChangeSubscriptionModal({ isOpen, setIsOpenSubs }) {

  const handleClose = () => {
    setIsOpenSubs(false);
  };

  function redirectToEmail() {
    window.location.href = "mailto:support@procuracon.com.au";
  }

  return (
    <ConfirmCancelStyled
      isOpen={isOpen}
      onRequestClose={() => setIsOpenSubs(false)}
      width="650"
      height="570px"
      closeIconHeight="24px"
      closeIconWidth="24px"
      closeIconTop="20px"
    >
      <Heading>Confirmation</Heading>
      <DialogConfirm>
          If you would like to change your Subscription Plan or find out more please get in touch with us at support@procuracon.com.au
      </DialogConfirm>
      <ButtonRow>

          <Button
            size={"md"}
            danger
            className="cancel-btn"
            btntype={"secondary"}
            width={"218px"}
            height={"48px"}
            textfont={"14px"}
            boxshadow={false}
            onClick={handleClose}
            type="button"
          >
            Close
          </Button>
        <Button
          size={"md"}
          danger
          className="confirm-btn-changesubs"
          btntype={"secondary"}
          width={"218px"}
          height={"48px"}
          textfont={"14px"}
          boxshadow={false}
          onClick={redirectToEmail}
          type="button"
          style={{
            background: '#0045F5'
          }}
        >
          Contact Us
        </Button>
      </ButtonRow>
    </ConfirmCancelStyled>
  );
}

export default ChangeSubscriptionModal;
