import api from "api";

import * as constants from "./actionTypes";
import { processFormData, sortArrayByName } from "../utils/helpers";
import { toast } from "react-toastify";
import cloneDeep from "lodash.clonedeep";
import { navigate } from "@reach/router";
import { getSavedProjectField } from "../utils/getSavedProjectField"
import { getSavedCompanyField } from "utils/getSavedCompanyField";

function getApiWithToken() {
  const token = localStorage.getItem("access_token");
  if(token){
    return api.extend({
      hooks: {
        beforeRequest: [
          (request) => {
            request.headers.set("Authorization", `Bearer ${token}`);
          },
        ],
      },
    });
  }else {
   throw new Error("Token not found")}
}

export const getCompanies = (filtername,sort) => {
  if(!sort){
    sort="name"
  }
  return async (dispatch) => {
    // dispatch({ type: constants.IS_LOADING, payload: true });

    (async () => {
      try {
        const companies = await getApiWithToken().get(`companies?page_size=10000&filter[name]=${filtername}&sort=${sort}`, ).json();
        await dispatch({
          type: constants.GET_COMPANIES,
          payload: companies.data,
        });
      } catch (error) {
        console.error(error) 
      }
      // await dispatch({ type: constants.IS_LOADING, payload: false });
    })();
  };
};

export const getCompany = (companyId) => {
  let guest_email = JSON.parse(localStorage.getItem("guest_user")) || null;
  return async (dispatch) => {
    dispatch({ type: constants.IS_LOADING, payload: true });
    try {
      const company = await getApiWithToken()
        .get(
          `companies/${companyId}?past_projects_published=true&email=${guest_email?.email || ""
          }`
        )
        .json();
      await dispatch({ type: constants.GET_COMPANY, payload: company.data });
      await dispatch({ type: constants.IS_LOADING, payload: false });
    } catch (e) {
      const error = await e.response?.json();
      return error;
    }
  };
};

export const getDocumentAccessRequests = (company_id) => {
  return async (dispatch) => {
    (async () => {
      const access_requests = await getApiWithToken()
        .get(`documents/companies/${company_id}/request`)
        .json();
      await dispatch({
        type: constants.DOCUMENT_ACCESS_REQUEST,
        payload: access_requests,
      });
    })();
  };
};

//additional 'published' parameter to retrieve only published projects - used on the frontend display
export const getPastProjects = (companyId, published = false) => {
  let guest_email = JSON.parse(localStorage.getItem("guest_user")) || null;
  return async (dispatch) => {
    dispatch({ type: constants.FETCHING_PAST_PROJECTS, payload: true });

    (async () => {
      try {
        const pastProjects = await getApiWithToken()
          .get(
            `past-projects?filter[company_id]=${companyId}${`&published=${!!published}`}&email=${guest_email?.email
            }`
          )
          .json();
        await dispatch({
          type: constants.GET_PAST_PROJECTS,
          payload: pastProjects.data,
        });
        return pastProjects;
      } catch (e) {
        console.log(e);
      }

      await dispatch({
        type: constants.FETCHING_PAST_PROJECTS,
        payload: false,
      });
    })();
  };
};

export const updateProjectOrderings = async (form) => {
  const formData = processFormData(form);
  const res = await getApiWithToken()
    .patch(`past-projects-order`, { body: formData })
    .json()
  return res
}

export const addPastProject = (values) => {
  return async (dispatch) => {
    const formData = processFormData(values);
    const company = await getApiWithToken()
      .post(`past-projects`, { body: formData, timeout: 30000 })
      .json();
    await dispatch({ type: constants.ADD_PAST_PROJECT, payload: company.data });
    return company;
  };
};

export const addDraftPastProject = (values) => {
  return async (dispatch) => {
    dispatch({ type: constants.SAVING_DRAFT_PAST_PROJECT, payload: true });
    const formData = processFormData(values);
    const company = await getApiWithToken()
      .post(`past-projects`, { body: formData, timeout: 30000 })
      .json();
    await dispatch({ type: constants.ADD_PAST_PROJECT, payload: company.data });
    await dispatch({
      type: constants.SAVING_DRAFT_PAST_PROJECT,
      payload: false,
    });
    return company;
  };
};

export const editPastProject = (projectId, values, isNotAuto, setSavedField, companyId, tradeValues, visibilityEdit) => {
  return async (dispatch) => {
    const formData = processFormData(values);
    const company = await getApiWithToken()
      .patch(`past-projects/${projectId}`, { body: formData, timeout: 30000 })
      .json();
    if (visibilityEdit) {
      await dispatch({
        type: constants.EDIT_PAST_PROJECT,
        payload: company.data,
      });
    } else if (company && isNotAuto) {
      await navigate(`/account/${companyId}/company-profile/projects`);
      toast.success("Project updated successfully.");
    } else {
      const savedValues = getSavedProjectField(company, tradeValues)
      setSavedField(savedValues)
    }
    return company;
  };
};

export const deletePastProject = (projectId) => {
  return async (dispatch) => {
    dispatch({ type: constants.IS_REQUESTING, payload: true });
    const response = await getApiWithToken()
      .delete(`past-projects/${projectId}`)
      .json();
    await dispatch({ type: constants.DELETE_PAST_PROJECT, payload: response });
    await dispatch({ type: constants.IS_REQUESTING, payload: false });
    return response;
  };
};

export const searchPastProjects = (value, companyId = null) => {
  let guest_email = JSON.parse(localStorage.getItem("guest_user")) || null;
  return async (dispatch) => {
    dispatch({ type: constants.IS_SEARCHING_PROJECTS, payload: true });
    const response = await getApiWithToken()
      .get(
        `past-projects?company_id=${companyId}&search=${value}&email=${guest_email?.email}`
      )
      .json();
    await dispatch({
      type: constants.SEARCH,
      payload: { response, searchEntity: "projects" },
    });
    await dispatch({ type: constants.IS_SEARCHING_PROJECTS, payload: false });
    return response;
  };
};

export const searchCompanies = (value) => {
  return async (dispatch) => {
    const companies = await getApiWithToken()
      .get(`companies/search?search=${value}`)
      .json();
    let companiesArray = [];

    Object.keys(companies).forEach((company, index) => {
      companiesArray[index] = { id: company, name: companies[company] };
    });

    await dispatch({
      type: constants.SEARCH,
      payload: {
        response: { data: companiesArray },
        searchEntity: "companies",
      },
    });
  };
};

export const submitCompanyInformationForm = (id, values, setSavedField) => {
  const submitValues = cloneDeep(values);
  if (submitValues.global_presence) {
    submitValues.global_presence = submitValues.global_presence?.map(
      (obj) => obj.id || obj.value
    );
  }

  return async (dispatch) => {
    //need a check here to see if the url for images have changed
    //if they have, then we don't want to re-submit them to the api - as they'll just be url's
    const formData = processFormData(submitValues);
    const company = await getApiWithToken()
      .patch(`companies/${id}`, { body: formData })
      .json();
      const savedValues = getSavedCompanyField(company.data)
      await dispatch({
        type: constants.SUBMIT_COMPANY_PROFILE_FORM,
        payload: company.data,
      })
      setSavedField(savedValues)
    return company;
  };
};

export const submitCompanyLocationForm = (id, values) => {
  return async (dispatch) => {
    const formData = processFormData(values);
    const company = await getApiWithToken()
      .patch(`companies/${id}/update-locations`, { body: formData })
      .json();
    await dispatch({
      type: constants.SUBMIT_COMPANY_PROFILE_FORM,
      payload: company,
    });
    return company;
  };
};

export const deleteCompanyAddress = (companyId, addressId) => {
  return async (dispatch) => {
    dispatch({ type: constants.IS_REQUESTING, payload: true });
    const locations = await getApiWithToken()
      .delete(`companies/${companyId}/delete-address/${addressId}`)
      .json();
    await dispatch({ type: constants.DELETE_ADDRESS, payload: locations });
    dispatch({ type: constants.IS_REQUESTING, payload: false });
    return true;
  };
};

export const updateCardDetails = (values, setSubmitting, last4) => {
  console.log(values);
  return async (dispatch) => {
    setSubmitting(true);
    const formData = processFormData(values);
    const result = await getApiWithToken()
      .patch(`auth/users/update-card-details`, { body: formData })
      .json();
    if (result.success) {
      toast.success("Details Saved Successfully");
      navigate("../account-management");
    } else {
      toast.error("Update Card Details Failed");
    }
    setSubmitting(false);
    dispatch({ type: constants.UPDATE_LAST4, payload: last4 });
    return result;
  };
};

export const updateSubscription = (
  values,
  setSubmitting,
  prevId,
  accountTypes,
  setStatus,
  setIsOpen
) => {
  console.log("value subs", values);
  console.log("parsing id action", prevId);
  const planData = {
    plan: accountTypes[values.account_type_id - 1].package_name,
    description: accountTypes[values.account_type_id - 1].description,
  };
  return async (dispatch) => {
    setSubmitting(true);
    setStatus(null);
    try {
      setSubmitting(true);
      const formData = processFormData(values);
      const result = await getApiWithToken()
        .post(`subscription/change`, { body: formData })
        .json();
      setSubmitting(false);
      console.log("res", result);
      await dispatch({
        type: constants.UPDATE_SUBSCRIPTION_ACCOUNT,
        payload: values,
      });
      await dispatch({
        type: constants.UPDATE_SUBSCRIPTION_PROFILE,
        payload: values,
      });
      await dispatch({ type: constants.UPDATE_PLAN_DATA, payload: planData });
      if (result.message === "Success to change subscription.") {
        toast.success("Success to change subscription.");
        navigate("edit-subscription/success-message", {
          state: { idClicked: prevId },
        });
        return result;
      }
    } catch (e) {
      const error = await e.response.json();
      toast.error(error.message);
      setSubmitting(false);
      setStatus("Stripe");
      setIsOpen(false);
    }
  };
};

export const getResources = (companyId) => {
  let guest_email = JSON.parse(localStorage.getItem("guest_user")) || null;
  return async (dispatch) => {
    (async () => {
      dispatch({ type: constants.REQUESTING_RESOURCES, payload: true });
      try {
        const resources = await getApiWithToken()
          .get(
            `companies/${companyId}/key-personnel?filter[company_id]=${companyId}&email=${guest_email?.email}`
          )
          .json();
        console.log("resources", resources);
        await dispatch({
          type: constants.GET_RESOURCES,
          payload: resources.data,
        });
      } catch (e) {
        console.log(e);
      }
      await dispatch({ type: constants.REQUESTING_RESOURCES, payload: false });
    })();
  };
};

export const addResource = (values) => {
  return async (dispatch) => {
    const formData = processFormData(values);
    try {
      const resource = await getApiWithToken()
        .post("companies/key-personnel/create", { body: formData, timeout: 60000 })
        .json();
      await dispatch({ type: constants.ADD_RESOURCE, payload: resource.data });
      return resource;
    } catch (e) {
      const error = await e.response.json()
      throw error;
    }
  };
};

export const editResource = (resourceId, values, setErrors, setStatus) => {
  return async (dispatch) => {
    const formData = processFormData(values);
    try {
      const resource = await getApiWithToken()
        .patch(`companies/key-personnel/update/${resourceId}`, { body: formData, timeout: 60000 })
        .json();
      await dispatch({ type: constants.EDIT_RESOURCE, payload: resource });
      return resource;
    } catch (e) {
      const error = await e.response.json()
      throw error
    }

  };
};

export const deleteResource = (resourceId) => {
  return async (dispatch) => {
    dispatch({ type: constants.IS_REQUESTING, payload: true });
    const response = await getApiWithToken()
      .delete(`companies/key-personnel/delete/${resourceId}`)
      .json();
    await dispatch({
      type: constants.DELETE_RESOURCE,
      payload: response,
      id: parseInt(resourceId),
    });
    await dispatch({ type: constants.IS_REQUESTING, payload: false });
    return response;
  };
};

export const getDocumentCategories = (profileTypeId) => {
  return async (dispatch) => {
    (async () => {
      try {
        const categories = await getApiWithToken()
          .get(
            `document-categories?filter[exclude_from_profile_id]=${profileTypeId}`
          )
          .json();
        await dispatch({
          type: constants.GET_DOCUMENT_CATEGORIES,
          payload: categories.data,
        });
      } catch (e) {
        console.log(e);
      }
    })();
  };
};

export const getDocumentSubCategories = () => {
  return async (dispatch) => {
    (async () => {
      try {
        const subcategories = await getApiWithToken()
          .get("document-subcategories")
          .json();
        let data = sortArrayByName(subcategories?.data)
        await dispatch({
          type: constants.GET_DOCUMENT_SUBCATEGORIES,
          payload: data,
        });
      } catch (e) {
        console.log(e);
      }
    })();
  };
};

export const getDocuments = (companyId) => {
  let guest_email = JSON.parse(localStorage.getItem("guest_user")) || null;
  return async (dispatch) => {
    dispatch({ type: constants.IS_REQUESTING, payload: true });

    (async () => {
      try {
        const documents = await getApiWithToken()
          .get(
            `documents?filter[documentable_id]=${companyId}&filter[documentable_type]=App\\Models\\Company&email=${guest_email?.email}`
          )
          .json();
        await dispatch({
          type: constants.GET_DOCUMENTS,
          payload: documents.data,
        });
      } catch (e) {
        console.log(e);
      }
      await dispatch({ type: constants.IS_REQUESTING, payload: false });
    })();
  };
};

export const addDocument = (companyId, values) => {
  return async (dispatch) => {
    const formData = processFormData(values);
    const document = await getApiWithToken()
      .post(`documents/companies/${companyId}`, {
        body: formData,
        timeout: false,
      })
      .json();
    await dispatch({ type: constants.ADD_DOCUMENT, payload: document });
    return document;
  };
};

export const sendDocumentRequest = (companyId, values) => {
  return async (dispatch) => {
    const formData = processFormData(values);
    const request = await getApiWithToken()
      .post(`documents/companies/${companyId}/request`, {
        body: formData,
        timeout: false,
      })
      .json();
    await dispatch({
      type: constants.SEND_DOCUMENT_ACCESS_REQUEST,
      payload: request,
    });
  };
};
export const updateDocumentRequest = (companyId, values) => {
  return async (dispatch) => {
    const formData = processFormData(values);
    const request = await getApiWithToken()
      .put(`documents/companies/${companyId}/request`, { body: formData })
      .json();
    await dispatch({
      type: constants.UPDATE_DOCUMENT_ACCESS_REQUEST,
      payload: request.data,
    });
  };
};

export const editDocument = (documentId, values) => {
  return async (dispatch) => {
    const formData = processFormData(values);
    const document = await getApiWithToken()
      .patch(`documents/${documentId}`, { body: formData })
      .json();
    await dispatch({ type: constants.EDIT_DOCUMENT, payload: document });
    return document;
  };
};

export const deleteDocument = (documentId) => {
  return async (dispatch) => {
    dispatch({ type: constants.DOCUMENT_IS_DELETING, payload: true });

    try {
      const document = await getApiWithToken()
        .delete(`documents/${documentId}`)
        .json();
      await dispatch({ type: constants.DELETE_DOCUMENT, payload: document });
      toast.success("Document successfully deleted.");
      dispatch({ type: constants.DOCUMENT_IS_DELETING, payload: false });
    } catch (e) {
      const error = await e.response.json();
      dispatch({ type: constants.DOCUMENT_IS_DELETING, payload: false });
      toast.error(error.error);
    }
  };
};

export const toggleDocumentDisplay = (documentId, values) => {
  return async (dispatch) => {
    const document = await getApiWithToken()
      .patch(`users/${documentId}`, { json: values })
      .json();
    await dispatch({ type: constants.EDIT_DOCUMENT, payload: document });
    return document;
  };
};

export const getProcurementMethods = () => {
  return async (dispatch) => {
    const methods = await getApiWithToken().get("procurement-methods").json();
    await dispatch({
      type: constants.GET_PROCUREMENT_METHODS,
      payload: methods.data,
    });
    return methods;
  };
};

export const getExpertiseDisciplines = () => {
  return async (dispatch) => {
    const methods = await getApiWithToken().get("expertise-disciplines").json();
    let dataObj = {};

    methods &&
      Array.isArray(methods.data) &&
      methods.data.forEach((method) => {
        let disciplineData = method.disciplines.map((discipline) => ({
          value: discipline.id,
          label: discipline.name,
        }));

        dataObj[method.name] = disciplineData;
      });

    await dispatch({
      type: constants.GET_EXPERTISE_DISCIPLINES,
      payload: dataObj,
    });
  };
};

export const getDisciplinesByProfileType = (profileTypeId) => {
  return async (dispatch) => {
    let disciplines = await getApiWithToken()
      .get(`disciplines/by-profile-type/${profileTypeId}`)
      .json();

    let disciplineOptions = Object.keys(disciplines).reduce(
      (accumulator, discipline) => {
        const values = disciplines[discipline];
        accumulator[discipline] = Object.values(values).reduce((acc, d) => {
          if (d.name && d.id) {
            acc.push({ label: d.name, value: d.id });
          }
          return acc;
        }, []);
        return accumulator;
      },
      {}
    );

    await dispatch({
      type: constants.GET_DISCIPLINES_BY_PROFILE_TYPE,
      payload: disciplineOptions,
    });
  };
};

export const getCompanySizeOptions = (profileType) => {
  return async (dispatch) => {
    let sizes = [];
    if (profileType) {
      sizes = await getApiWithToken()
        .get(`companies/sizes/${profileType}`)
        .json();
    }
    await dispatch({
      type: constants.GET_COMPANY_SIZE_OPTIONS,
      payload: sizes,
    });
  };
};

export const getLocalGovtAssociations = () => {
  return async (dispatch) => {
    let lgas = [];
    lgas = await getApiWithToken()
      .get(`companies/local-govt-associations`)
      .json();
    await dispatch({
      type: constants.GET_LOCAL_GOVT_ASSOCIATION,
      payload: lgas,
    });
  };
};

export const getTrades = () => {
  return async (dispatch) => {
    let trades = [];

    try {
      trades = await getApiWithToken().get("trades?page_size=9999").json();

      if (trades.data) {
        trades = trades.data.map((trade) => ({
          label: trade.name,
          value: trade.id,
        }));
      }

      await dispatch({ type: constants.GET_TRADES, payload: trades });
    } catch (e) {
      console.log(e);
    }
  };
};
export const getTradeValues = () => {
  return async (dispatch) => {
    let tradeValues = [];
    try {
      tradeValues = await getApiWithToken().get("trade-values").json();
      console.log("trade val", tradeValues);
      await dispatch({ type: constants.GET_TRADES_VALUE, payload: tradeValues.data });
    } catch (e) {
      console.log(e);
    }
  };
};
export const getSpecialities = () => {
  console.log("sepecial");
  return async (dispatch) => {
    let specialities = [];

    try {
      specialities = await getApiWithToken().get("speciality").json();

      if (specialities.data) {
        specialities = specialities.data.map((trade) => ({
          label: trade.name,
          value: trade.id,
        }));
      }
      console.log("sepecial", specialities);
      await dispatch({
        type: constants.GET_SPECIALITIES,
        payload: specialities,
      });
    } catch (e) {
      console.log(e);
    }
  };
};

export const getPostcodeRegions = () => {
  return async (dispatch) => {
    (async () => {
      const postcodeRegions = await getApiWithToken()
        .get("postcode-regions")
        .json();
      await dispatch({
        type: constants.GET_POSTCODE_REGIONS,
        payload: postcodeRegions,
      });
    })();
  };
};

export const getProrateAmount = () => {
  return async (dispatch) => {
    const amount = 0;
    await dispatch({
      type: constants.GET_PRORATE_AMOUNT,
      payload: amount,
    });
  };
};
