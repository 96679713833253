import React from "react";
import { connect } from "react-redux";
import { logoutUser, logoutGuestUser } from "actions/authActions";

import {
  GlobalNavStyled,
  GlobalNavLink,
  IconButtons,
  ProfileButton,
  PopUp,
  CompanyLogoImg,
} from "./styled";
import { ReactComponent as SearchIcon } from "../../icons/search.svg";
import { ReactComponent as UsersIcon } from "../../icons/user-group.svg";
import { ReactComponent as ProfileIcon } from "./../../icons/profile.svg";
import { ReactComponent as UserIcon } from "./../../icons/user-b.svg";
import { ReactComponent as SettingIcon } from "./../../icons/settings-b.svg";
import { ReactComponent as EditIcon } from "./../../icons/edit-b.svg";
import { ReactComponent as UserAdminIcon } from "./../../icons/users-b.svg";
import { ReactComponent as LogoutIcon } from "./../../icons/logout-b.svg";
import logo from "./logo.png";
import { ReactComponent as Edit } from "../../icons/edit.svg";
import { IconBox } from "../IconBox";
import {
  isAdmin,
  isProcurerNBidder,
  isProcurer,
  isSuperAdmin,
} from "../../utils/helpers";
import { Link } from "@reach/router";
import { useSelector } from "react-redux";

const GlobalNavComponent = ({
  user,
  company,
  showPopUp,
  setShowPopUp,
  guestUser,
  ...props
}) => {
  const logout = async () => {
    await props.logoutUser();
  };
  const logoutGuest = async () => {
    await props.logoutGuestUser();
  };

  const company_id = localStorage.getItem("company_id");
  let { allow_companies_listing_access } = useSelector(
    (state) => state?.auth?.current_company
  );
  allow_companies_listing_access = parseInt(allow_companies_listing_access);

  return (
    <GlobalNavStyled>
      <GlobalNavLink
        to={`/account/${company_id}/company-profile/edit-user-profile`}
        logo
        icon={<img src={logo} alt="Procuracon Logo" width="60" height="60" />}
      />

      <IconButtons>
        {(isSuperAdmin() ||
          isProcurer(company) ||
          isProcurerNBidder(company)) && (
          <GlobalNavLink label="Search" to="/search" icon={<SearchIcon />} />
        )}
        {isSuperAdmin() ? (
          <GlobalNavLink
            to="/companies"
            label="Companies"
            icon={<UsersIcon />}
          />
        ) : (
          <>
            {allow_companies_listing_access === 1 ? (
              <GlobalNavLink
                to="/companies"
                label="Companies"
                icon={<UsersIcon />}
              />
            ) : (
              ""
            )}
          </>
        )}
      </IconButtons>

      {showPopUp && (
        <PopUp>
          <div className="header">
            <CompanyLogoImg
              src={
                company?.logo_path ||
                `https://ui-avatars.com/api/?name=${
                  company?.name || guestUser.email
                }&color=fff&background=000`
              }
            />
            <p className="title">{company?.name}</p>
            <span className="tag">
              {company?.plan?.package_name || guestUser.login ? "guest" : ""}
            </span>
          </div>
          <div className="body">
            {!guestUser.login && (
              <li>
                {" "}
                <Link
                  to={`/account/${company_id}/company-profile/edit-user-profile`}
                >
                  <UserIcon /> My Profile
                </Link>
              </li>
            )}
            {(isAdmin(user) || isSuperAdmin()) && (
              <>
                <li>
                  {" "}
                  <Link
                    to={`/account/${company_id}/company-profile/account-management`}
                  >
                    <SettingIcon /> Account Management
                  </Link>
                </li>
                <li>
                  {" "}
                  <Link
                    to={`/account/${company_id}/company-profile/company-information`}
                  >
                    <EditIcon /> Edit Company Details
                  </Link>
                </li>
              </>
            )}
            {localStorage.getItem("is_admin") === "true" && (
              <li>
                {" "}
                <Link to="/companies">
                  <UserAdminIcon /> Companies Admin
                </Link>
              </li>
            )}
            <li>
              {" "}
              <button onClick={guestUser.login ? logoutGuest : logout}>
                <LogoutIcon /> Sign Out
              </button>
            </li>
          </div>
        </PopUp>
      )}

      <IconButtons admin>
        {isSuperAdmin() ? <GlobalNavLink to="/accounts" icon={<Edit />} /> : ""}
        <ProfileButton onClick={() => setShowPopUp(!showPopUp)}>
          <IconBox icon={<ProfileIcon id="profile-icon" />} />
        </ProfileButton>
      </IconButtons>
    </GlobalNavStyled>
  );
};

const mapStateToProps = (state) => {
  return {
    access_token: state.auth.access_token,
    guestUser: state.auth.guestUser,
    user: state.auth.user,
    company: state.auth.current_company,
  };
};

export const GlobalNav = connect(mapStateToProps, {
  logoutUser,
  logoutGuestUser,
})(GlobalNavComponent);
