import React from 'react';

import { ActionButtonStyled } from './styled';
import { ReactComponent as CloseIcon } from '../../icons/close.svg';
import { ReactComponent as PlusIcon } from '../../icons/plus.svg';
import { ReactComponent as MinusIcon } from '../../icons/minus.svg';
import { ReactComponent as ArrowIcon } from '../../icons/arrow-open.svg';

export const ActionButton = ({ onClick, icon, open, ...rest }) => {
    // const iconSwitch = icon => {
    //     switch (icon) {
    //         case 'expand':
    //             return <PlusIcon />;
    //         case 'arrow':
    //             return <ArrowIcon />;
    //         default:
    //             return <CloseIcon />;
    //     }
    // };

    return (
        <ActionButtonStyled {...rest} onClick={onClick}>
            {open && icon === 'arrow' && <CloseIcon />}
            {!open && icon === 'arrow' && <ArrowIcon />}

            {icon === 'close' && <CloseIcon />}

            {open && icon === 'expand' && <PlusIcon />}
            {!open && icon === 'expand' && <MinusIcon />}
        </ActionButtonStyled>
    );
};
