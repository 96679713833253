import React, { useCallback, useEffect, useState } from 'react';

import { connect } from "react-redux";
import { PageWrap, ContentContainer } from '../components/Layout';
import { Heading } from '../components/Heading';
import { TabContent } from 'components/Tabs/styled';
import Loader from 'react-spinners/BeatLoader';
import { shareProfile } from 'actions/authActions';
import { navigate } from '@reach/router';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { getCurrentUser } from 'actions/authActions';
import { getCompany } from 'actions/companyActions';


const ShareProfileComponent = (props) => {
    const [loading, setLoading] = useState(true);
    const queryParams = new URLSearchParams(window.location.search);
    const invitetoken = queryParams.get("sc");
    const dispatch = useDispatch();


    const handleShareProfile = useCallback(async () => {
        const result = await props.shareProfile(invitetoken);
        if (result.success){
            toast.success(result?.message, { toastId: 'share-profile-success' });
            if(result?.message === "You are already connected to this profile!"){
                navigate(`/`);
                await dispatch(getCurrentUser());
                await dispatch(getCompany());
            } else {
                navigate(`/company/${result?.data?.companyId}/projects`);
            }
            await dispatch(getCurrentUser());
        }else{
            toast.error(result?.errors?.invitetoken[0] || result?.message, { toastId: 'share-profile-error' });
            navigate(`/`);
            await dispatch(getCurrentUser());
            await dispatch(getCompany());
        }
        setLoading(false);
    }, [props, invitetoken, dispatch]);

    useEffect(() => {
        if(loading){
            handleShareProfile();
        }
        
    }, [loading, handleShareProfile])
    
    return (
        <PageWrap>
            <TabContent>
                <ContentContainer>
                    <Heading size='xl'>Share Profile</Heading>
                    <>
                        <p>Connecting profile to your account...</p>
                        <Loader />
                    </>

                    
                </ContentContainer>
            </TabContent>
        </PageWrap>
    );
};

const mapStateToProps = (state) => {
    return {
        user: state.auth.user,
        company: state.auth.current_company,
        companyId: state.search.activeCompany.id
    };
};

export const ShareProfile = connect(mapStateToProps, {
    shareProfile, getCurrentUser, getCompany
})(ShareProfileComponent);
