import styled from "styled-components";

export const CompanySectorStyled = styled.div`
  h2 {
    font-size: 1.5rem;
    color: black;
  }
  h3 {
    font-size: 1.2rem;
    color: black;
  }
`;

export const TagContainerStyled = styled.div`
  margin-bottom: 2.5rem;
`;

export const ImageBanner = styled.img`
  width: 100%;
  height: 300px;
  object-fit: cover;
`;

export const AllTagContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
`;

export const Tag = styled.div`
  padding: 0.75rem 1rem;
  background: #ebebeb;
  color: black;
  border-radius: 6px;
`;
