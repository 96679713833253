import React from 'react';

import { CompanyHeaderMediaStyled, CompanyBannerImage, CompanyHeaderLogo } from './styled';

export const CompanyHeaderMedia = (props) => {
    return (
        <CompanyHeaderMediaStyled>
            <CompanyBannerImage>
                <source media='(min-width: 1000px)' srcSet={props.bg} />
                <source media='(max-width: 799px)' srcSet={props.bg} />
                <img src={props.bg} alt='' />
            </CompanyBannerImage>
            <CompanyHeaderLogo src={props.logo} alt='' />
        </CompanyHeaderMediaStyled>
    );
};
