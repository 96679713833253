import React, { useEffect } from "react";
import { Router, Redirect } from "@reach/router";
import { connect } from "react-redux";
import Skeleton from "react-loading-skeleton";
import { CompanyInformation } from "./CompanyInformation";
import { Documents } from "./Documents";
import { Resources } from "./Resources";
import { ProjectListing } from "./ProjectListing";
import {
  getResources,
  getDocuments,
  getProcurementMethods,
  getExpertiseDisciplines,
  getCompanySizeOptions,
  getPostcodeRegions,
} from "actions/companyActions";
import { getSectors } from "actions/sectorActions";
import { getGlobalPresence } from "./../../../actions/globalPresenceActions";

import { AddProject } from "../../../components/ProjectForm/AddProject";
import { EditProject } from "../../../components/ProjectForm/EditProject";
import { AddResource } from "../../../components/ResourceForm/AddResource";
import { EditResource } from "../../../components/ResourceForm/EditResource";
import { CompanyLocationForm } from "../../../components/CompanyLocationForm";
import { EditUserProfile } from "../../../components/EditUserProfile/EditUserProfile";
import { AccountManagement } from "../../../components/AccountManagement/AccountManagement";
import { AccountUsers } from "components/AccountUsers";
import { EditPlan } from "components/EditPlan/EditPlan";
import { PlanMessage } from "components/PlanMessage/PlanMessage";
import { EditUserAccount } from "../../../components/EditUserAccount/EditUserAccount";
import { UpdateCreditCard } from "../../../components/CreditCardDetail/CreditCardDetail";
import { Logs } from "../../../components/Logs";
import { CreateUserForm } from "../../../components/UserProfile";
import {
  isAdmin,
  isBidder,
  isProcurer,
  isProcurerNBidder,
  isSuperAdmin,
} from "../../../utils/helpers";
import { DocumentRequest } from "../../../components/DocumentRequest/DocumentRequest";
import { Invoice } from "../../../components/Invoice/Invoice";
import { CompaniesListing } from "../../../components/CompaniesListing/CompaniesListing";

export const CompanyProfileComponent = ({
  company,
  getResources,
  pastProjects,
  getDocuments,
  getProcurementMethods,
  getSectors,
  getGlobalPresence,
  getCompanySizeOptions,
  getExpertiseDisciplines,
  getPostcodeRegions,
  documents,
  isRequesting,
  user,
  ...props
}) => {
  const { resources } = props;
  const companyId = company.id;

  useEffect(() => {
    getResources(companyId);
    getDocuments(companyId);
    getSectors();
    getGlobalPresence();
    getPostcodeRegions();
    getProcurementMethods();
    getExpertiseDisciplines();
  }, [
    getResources,
    getDocuments,
    getSectors,
    getGlobalPresence,
    getPostcodeRegions,
    getProcurementMethods,
    getExpertiseDisciplines,
    companyId,
  ]);

  const profileTypeId = company ? company.profile_type.id : "";

  return (
    <div>
      {!isRequesting ? (
        <>
          <Router primary={false}>
            <EditUserProfile path="edit-user-profile" company={"My Profile"} />
            <CompaniesListing path="shared-companies" />
            {(isAdmin(user) || isSuperAdmin()) && (
              <>
                <AccountUsers path="account-users" />
                <CompanyInformation
                  path="company-information"
                  company={company}
                />
                <AccountManagement
                  path="account-management"
                  company={"Account Management"}
                />
                <UpdateCreditCard path="account-management/update-card-details" />
                <EditPlan path="account-management/edit-subscription" />
                <PlanMessage path="account-management/edit-subscription/success-message" />
                <EditUserAccount path="account-users/edit-user-account" />
                <DocumentRequest path="documents/request-view" />
                <CreateUserForm path="account-users/create-user" />
                <Invoice
                  path="account-management/invoice-view"
                  company={company}
                />
                {(isProcurerNBidder(company) ||
                  isBidder(company) ||
                  isProcurer(company) ||
                  isSuperAdmin()) && (
                  <CompanyLocationForm
                    path="company-locations"
                    company={company}
                  />
                )}
                <Logs path="logs" />
              </>
            )}

            {((isAdmin(user) && isProcurerNBidder(company)) ||
              isBidder(company) ||
              isSuperAdmin()) && (
              <>
                <ProjectListing
                  path="projects"
                  projects={pastProjects}
                  companyId={company.id}
                />
                <AddProject path="projects/new" profileTypeId={profileTypeId} />
                <EditProject
                  path="projects/edit/:projectId"
                  profileTypeId={profileTypeId}
                />
                <Resources
                  path="resources/*"
                  resources={resources}
                  isRequesting={props.requestingResources}
                  companyId={company.id}
                />
                <AddResource path="resources/new" />
                <EditResource
                  path="resources/edit/:resourceId"
                  resources={resources}
                />
              </>
            )}
            {((isAdmin(user) && !isProcurer(company)) || isSuperAdmin()) && (
              <Documents path="documents" documents={documents} editable />
            )}
            <Redirect from="/" to="company-information" noThrow />
          </Router>
        </>
      ) : (
        <Skeleton count={5} duration={0.5} />
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    resources: state.companyProfile.resources,
    pastProjects: state.companyProfile.pastProjects,
    documents: state.companyProfile.documents,
    isRequesting: state.companyProfile.isRequesting,
    requestingResources: state.companyProfile.requestingResources,
    sectors: state.sectors.sectorData,
  };
};

export const CompanyProfile = connect(mapStateToProps, {
  getResources,
  getDocuments,
  getSectors,
  getGlobalPresence,
  getPostcodeRegions,
  getProcurementMethods,
  getExpertiseDisciplines,
  getCompanySizeOptions,
})(CompanyProfileComponent);
