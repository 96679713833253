import React from "react";

import { ConfirmDeleteStyled, ConfirmHeading, ButtonRow } from "./styled";
import { Button } from "../Button";
import { Heading } from "../Heading";
import { ReactComponent as Trash } from "./../../icons/trash-2.svg";

export const ConfirmDelete = ({
  itemName,
  heading,
  onConfirm,
  onCancel,
  reverse,
  isRequesting,
  btnText,
  ...props
}) => {
  return (
    <ConfirmDeleteStyled {...props}>
      <Heading marginBottom={"16px"} fontSize="2rem">
        {heading}
      </Heading>
      <ConfirmHeading {...props}>
        Are you sure you wish to {btnText[0] === "Delete" ? "delete" : "change"}{" "}
        {itemName || "record"}?
      </ConfirmHeading>
      <ButtonRow {...props}>
        <div>
          <Button
            size={"lg"}
            danger
            className="cancel-btn"
            btntype={"secondary"}
            width={"218px"}
            height={"60px"}
            textfont={"14px"}
            boxshadow={false}
            onClick={onCancel}
            type="button"
          >
            Cancel
          </Button>
          <Button
            size={"lg"}
            danger
            className="delete-btn"
            btntype={"secondary"}
            width={"218px"}
            height={"60px"}
            textfont={"14px"}
            boxshadow={false}
            onClick={onConfirm}
            type="button"
          >
            <Trash />
            <span>{!isRequesting ? btnText[0] : btnText[1]}</span>
          </Button>
        </div>
      </ButtonRow>
    </ConfirmDeleteStyled>
  );
};
