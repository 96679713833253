import React from "react";
import { ReactComponent as DocumentIcon } from "../../icons/document.svg";
import { ReactComponent as ExpiredArrow } from "../../icons/arrow-expired.svg";
import { ReactComponent as ExpiredArrow30 } from "../../icons/arrow-expired30.svg";
import { ReactComponent as Check } from "../../icons/small-check.svg";
import { DocumentItemStyled } from "./styled";
import {
  isDateExpired,
  isDateWithin30Days,
  shortenDigits,
} from "../../utils/helpers";
import moment from "moment";

export const DocumentItem = ({
  document,
  title,
  children,
  noIcon,
  check,
  current_company,
  openDocumentModal,
  hideExpireDate,
  ...props
}) => {
  const cross = props.cross;
  let documentName =
    (props.editable || document.display === "public") && document.storage_path
      ? document.name
      : title
      ? title
      : document.name;
  let documentNameArr = documentName.split(" ");
  let lastWord = documentNameArr[documentNameArr.length - 1];

  const isUnderInsuranceCerts = () => {
    return document.category?.parent_id === 4 || document.category?.id === 4;
  };

  if (
    isUnderInsuranceCerts() &&
    document.display === "public" &&
    lastWord.includes("$") === false
  ) {
    documentName =
      documentName + " $" + shortenDigits(document.extra_info.value);
  } else if (
    document.display === "private" &&
    documentNameArr.length > 1 &&
    documentNameArr[documentNameArr.length - 2].includes("$") === true
  ) {
    documentNameArr.splice(documentNameArr.length - 2, 1);
    documentName = documentNameArr.toString().replaceAll(",", " ");
  }

  // const messageExpired = () => {
  //   let message;
  //   switch (props?.parentTitle) {
  //     case "Insurance Certificates":
  //       message = "Insurance Certificates";
  //       break;
  //     default: message = "Document";
  //   }
  //   return message
  // }

  const ExpireComp = ({ text, isExpired, document }) => {
    return (
      <>
        <div style={{ width: "122px", textAlign: "center" }}>{text}</div>
        {window.location.href.includes("company-profile/documents") && (
          <div style={{ flex: 1 }}>
            <div
              style={{
                background: "#FFFFFF",
                border: `solid 1px ${isExpired ? "" : "#FEDF89"}`,
                color: `${isExpired ? "#B42318" : "#B54708"}`,
                borderRadius: "50px",
                display: "flex",
                padding: "5px 10px",
                textAlign: "center",
                width: "180px",
                cursor: "pointer",
              }}
            >
              <div
                style={{ flex: 1 }}
                onClick={() => {
                  openDocumentModal(true, document);
                }}
              >
                Upload new document
              </div>
              <div style={{ width: "35px" }}>
                {isExpired ? <ExpiredArrow /> : <ExpiredArrow30 />}
              </div>
            </div>
          </div>
        )}
      </>
    );
  };

  const ShowExpireComp = ({ documentDate, document }) => {
    return (
      <div style={{ marginLeft: "10px" }}>
        {documentDate && (
          <>
            {isDateWithin30Days(documentDate) ? (
              <>
                <div
                  style={{
                    background: "#FFFAEB",
                    borderRadius: "50px",
                    minWidth: "auto",
                    minHeight: "30px",
                    color: "#B54708",
                    display: "flex",
                    alignItems: "center",
                    fontSize: "12px",
                    fontFamily: "roboto",
                    padding: "5px 20px",
                    gap: 2,
                    fontWeight: "500",
                  }}
                >
                  <ExpireComp
                    text="Expires within 30 days"
                    isExpired={false}
                    document={document}
                  />
                </div>
              </>
            ) : (
              <>
                {isDateExpired(documentDate) && (
                  <div
                    style={{
                      background: "#FEF3F2",
                      borderRadius: "50px",
                      minWidth: "auto",
                      minHeight: "30px",
                      color: "#B42318",
                      display: "flex",
                      alignItems: "center",
                      fontSize: "12px",
                      fontFamily: "roboto",
                      padding: "5px 20px",
                      gap: 2,
                      fontWeight: "500",
                    }}
                  >
                    <ExpireComp
                      text="Document expired"
                      isExpired={true}
                      document={document}
                    />
                  </div>
                )}
              </>
            )}
          </>
        )}
      </div>
    );
  };

  return (
    <DocumentItemStyled {...props}>
      {!noIcon && <DocumentIcon />}
      {/* {document?.isExpireSoon && 
      <div className={`alert-icon alert-doc-${document.id}`}>
        <ExpiredIcon data-tip data-for={`expire-${document.id}`} />
        <ReactTooltip
            className="guide"
            id={`expire-${document.id}`}
            place="top"
            arrowColor="transparent"
          >
            {messageExpired()} is Expire soon
        </ReactTooltip>
      </div>} */}

      {document && (
        <>
          {(props.editable ||
            document.display === "public" ||
            document?.approved_companies?.includes(current_company?.id)) &&
          document.storage_path ? (
            <a
              href={document.storage_path}
              target="_blank"
              rel="noopener noreferrer"
              className="document__title"
            >
              {documentName} {check ? <Check /> : cross ? <>&mdash;</> : ""}
            </a>
          ) : (
            <p className="document__title">
              {documentName} {check ? <Check /> : cross ? <>&mdash;</> : ""}{" "}
            </p>
          )}
          {children}
          {document.category?.parent_id === 4 && !hideExpireDate && (
            <ShowExpireComp
              documentDate={moment(document?.extra_info?.expiry_date).format(
                "YYYY-MM-DD"
              )}
              document={document}
            />
          )}
        </>
      )}
    </DocumentItemStyled>
  );
};
