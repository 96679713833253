import React from "react";

import {
  LoginWrapper,
  LoginSection,
  Logo,
  Tagline,
} from "./styled";
import { Heading } from "../Heading";
import logo from "./../../icons/logo-text.svg";
import tagline from "./../../icons/tagline.svg";
import { navigate } from "@reach/router";
import { Button } from "components/Button";



export const GuestSignUpWelcome = ({ ...props }) => {
  const handleOnClick = () => {
    navigate("/guest/login", { replace: true });
  };
  return (
    <LoginWrapper>
      <a href="/" className="pro-logo">
        <Logo src={logo} alt="Procuracon Logo" />
        <Tagline src={tagline} alt="Build Possible" />
      </a>

      <LoginSection>
        <Heading marginBottom="40px">
        <span>Thank You</span>
        </Heading>
          <h6>
            You have successfully registered a Guest User account on Procuracon.
            You can now sign in as a Guest User. An email has
            also been sent to your registered email address.
          </h6>
        <Button type="button" className="text-center" onClick={handleOnClick}>
          Click to Sign In
        </Button>
      </LoginSection>
    </LoginWrapper>
  );
};

