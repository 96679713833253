import React, { useState } from "react";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import { Button } from "../Button";
import { DocumentGroupStyled, DocumentContainer } from "./styled";
import { DocumentControls } from "../DocumentControls";
import { ButtonToggle } from "../ButtonToggle";
import { DocumentItem } from "../DocumentItem";
import { RestrictedDocumentModal } from "../GuestModal/RestrictedDocumentModal";
import { Modal } from "../Modal";
import { EditDocument } from "../DocumentForm/EditDocument";
import { deleteDocument } from "../../actions/companyActions";
import { ConfirmDelete } from "../ConfirmDelete";
import { RequestAccessModal } from "../../components/DocumentForm/RequestAccessModal";

const DocumentGroupComponent = ({
  deleteDocument,
  requested_ids,
  current_company,
  documentDeleting,
  guestUser,
  openDocumentModal,
  hideExpireDate,
  ...props
}) => {
  const [editIsOpen, setEditIsOpen] = useState(false);
  const [editDoc, setEditDoc] = useState({});
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
  const [isUpgradeAccountModalOpen, setIsUpgradeAccountModalOpen] =
    useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [documentId, setDocumentId] = useState("");

  const requested_doc_ids = requested_ids
    .filter(
      (doc) =>
        !doc.is_viewed &&
        doc.client_company.id === current_company.id &&
        doc.document
    )
    .map((doc) => doc.document.id);
  const handleDisplayClick = async (documentId, display) => {
    await props.editDocument(documentId, { display: display });
    toast.success("Document status updated.");
  };
  const openRequestDocumentModal = (document) => {
    setDocumentId(document.id);
    setModalIsOpen(true);
  };

  const closeRequestDocumentModal = () => {
    setModalIsOpen(false);
  };

  const openEditModal = (doc) => {
    setEditDoc(doc);
    setEditIsOpen(true);
  };

  const closeEditModal = () => {
    setEditIsOpen(false);
  };

  const openDeleteModal = (doc) => {
    setEditDoc(doc);
    setDeleteModalIsOpen(true);
  };

  const closeDeleteModal = () => {
    setEditDoc(false);
    setDeleteModalIsOpen(false);
  };

  const handleDeleteConfirm = async () => {
    await deleteDocument(editDoc.id);
    setDeleteModalIsOpen(false);
  };

  return (
    <>
      {props.title !== "Fee/Hourly Rates" && (
        <DocumentGroupStyled className={`${props.className} document`}>
          <p
            className={`document-group__title ${props?.title?.replace(
              / /g,
              ""
            )}`}
          >
            {props.title}
          </p>

          {props.documents.length ? (
            <>
              {props.documents.map((document, index) => (
                <DocumentContainer key={index}>
                  <DocumentItem
                    document={document}
                    editable={props.editable}
                    title={`${document.name} ${
                      document.display === "private" ? "(Private)" : ""
                    }`}
                    private={document.display === "private" && !props.editable}
                    parentTitle={props.title}
                    openDocumentModal={openDocumentModal}
                    hideExpireDate={hideExpireDate}
                  >
                    {props.editable && (
                      <DocumentControls
                        document={document}
                        onEdit={openEditModal}
                        onDelete={openDeleteModal}
                        item={document}
                      />
                    )}
                  </DocumentItem>

                  {!props.editable &&
                  requested_doc_ids.includes(document.id) &&
                  document.display === "private" ? (
                    <Button
                      btntype={"secondary-danger"}
                      size={"sm"}
                      width={"164px"}
                      padding={"13px 10px !important"}
                      textfont={"14px"}
                      boxshadow={false}
                      disabledStyle
                    >
                      <span>REQUESTED</span>
                    </Button>
                  ) : !props.editable &&
                    !document.approved_companies.includes(current_company.id) &&
                    document.display === "private" ? (
                    <Button
                      btntype={"secondary-danger"}
                      size={"sm"}
                      width={"164px"}
                      padding={"13px 10px !important"}
                      textfont={"14px"}
                      boxshadow={false}
                      onClick={() => {
                        guestUser.login
                          ? setIsUpgradeAccountModalOpen(true)
                          : openRequestDocumentModal(document);
                      }}
                    >
                      <span>REQUEST ACCESS</span>
                    </Button>
                  ) : (
                    !props.editable && (
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={document.storage_path}
                      >
                        <Button
                          btntype={"secondary"}
                          size={"sm"}
                          width={"164px"}
                          padding={"13px 10px !important"}
                          textfont={"14px"}
                          boxshadow={false}
                        >
                          <span>View Document</span>
                        </Button>
                      </a>
                    )
                  )}

                  {/* admin */}
                  {props.editable && (
                    <ButtonToggle
                      public={document.display === "public"}
                      handleClick={handleDisplayClick}
                      documentId={document.id}
                    />
                  )}

                  {/* Requested below to be hidden - https://juiceboxcreative.atlassian.net/browse/PRO-528 */}
                  {/* {!props.editable && document.display !== 'public' && <AccessRequest documentId={1} />} */}
                </DocumentContainer>
              ))}
            </>
          ) : (
            <h4
              style={{
                color: "#cb7373",
                marginBottom: 0,
                fontWeight: "normal",
              }}
            >
              No documents uploaded to this category.
            </h4>
          )}

          {editIsOpen && (
            <Modal
              isOpen={editIsOpen}
              onRequestClose={closeEditModal}
              width="800"
            >
              <EditDocument
                initialValues={editDoc}
                onSuccessfulSubmit={closeEditModal}
              />
            </Modal>
          )}
          {modalIsOpen && (
            <Modal
              isOpen={modalIsOpen}
              onRequestClose={closeRequestDocumentModal}
              width="650"
            >
              <RequestAccessModal
                documentId={documentId}
                onSuccessfulSubmit={closeRequestDocumentModal}
              />
            </Modal>
          )}
          {deleteModalIsOpen && (
            <ConfirmDelete
              isOpen={deleteModalIsOpen}
              onRequestClose={closeDeleteModal}
              onConfirm={handleDeleteConfirm}
              onCancel={closeDeleteModal}
              itemName="document"
              isRequesting={documentDeleting}
              btnText={["Delete", "Deleting..."]}
            />
          )}
          <RestrictedDocumentModal
            guestEmail={guestUser.email}
            isOpen={isUpgradeAccountModalOpen}
            setIsOpen={setIsUpgradeAccountModalOpen}
          />
        </DocumentGroupStyled>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    documentDeleting: state.companyProfile.documentDeleting,
    requested_ids: state.companyProfile.document_access_requests,
    current_company: state.auth.current_company,
    guestUser: state.auth.guestUser,
  };
};

export const DocumentGroup = connect(mapStateToProps, {
  deleteDocument,
})(DocumentGroupComponent);
