import React, { useState } from "react";
import { Heading } from "../Heading";
import { Button } from "components/Button";
import { Spinner } from "@chakra-ui/react";

import { ConfirmCancelStyled, ButtonRow, DialogConfirm } from "./styled";
import { TextAreaStyled } from "components/FormInputs/TextArea/styled";
import { cancel_subs } from "actions/AccountManagementActions";

function CancelSubscriptionModal({ isOpen, setIsOpen, setAccountStatus }) {
  const [message, setMessage] = useState(null);
  const [sending, setSending] = useState(false);

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleConfirm = async () => {
    setSending(true);
    await cancel_subs(message)
      .then((res) => {
        console.log("testingEmail", res);
        setAccountStatus("cancelled");
        setSending(false);
        setIsOpen(false);
      })
      .catch((e) => {
        console.log(e);
        setSending(false);
        setIsOpen(false);
      });
  };

  return (
    <ConfirmCancelStyled
      isOpen={isOpen}
      onRequestClose={() => setIsOpen(false)}
      width="650"
      height="570px"
      closeIconHeight="24px"
      closeIconWidth="24px"
      closeIconTop="20px"
    >
      <Heading>Confirmation</Heading>
      <DialogConfirm>
        Are you sure you want to cancel the subscription? Please let us know
        why.
      </DialogConfirm>
      <TextAreaStyled onChange={(e) => setMessage(e.target.value)} />
      <ButtonRow>
        {!sending && (
          <Button
            size={"md"}
            danger
            className="cancel-btn"
            btntype={"secondary"}
            width={"218px"}
            height={"48px"}
            textfont={"14px"}
            boxshadow={false}
            onClick={handleClose}
            type="button"
          >
            Close
          </Button>
        )}
        <Button
          size={"md"}
          danger
          className="confirm-btn"
          btntype={"secondary"}
          width={"218px"}
          height={"48px"}
          textfont={"14px"}
          boxshadow={false}
          onClick={handleConfirm}
          type="button"
          disabled={sending}
          style={
            sending
              ? {
                  background: "#E9ECF1",
                  color: "#8492A6",
                  borderColor: "#d2d5da",
                }
              : {}
          }
        >
          {sending ? <Spinner /> : "Confirm"}
        </Button>
      </ButtonRow>
    </ConfirmCancelStyled>
  );
}

export default CancelSubscriptionModal;
