import React from "react";
import { HeaderCollumn, HeaderRow } from "components/CustomTable";

export const AccountHeaderRow = () => {
  return (
    <HeaderRow className={"section-header"}>
      <HeaderCollumn className={"md-3 first-item"}>
        <span>Name</span>
      </HeaderCollumn>
      <HeaderCollumn className={"md-2 first-item"}>
        <span>User Type</span>
      </HeaderCollumn>
      <HeaderCollumn className={"md-2 first-item"}>
        <span>Phone</span>
      </HeaderCollumn>
      <HeaderCollumn className={"md-3 first-item"}>
        <span>Email</span>
      </HeaderCollumn>
    </HeaderRow>
  );
};

export default AccountHeaderRow;
