import React from "react";
import { connect } from "react-redux";
import { CompanyCard } from "../CompanyCard";
import { CompaniesListingStyled, ListStyled } from "./styled";
import { TabHeader } from "components/TabHeader";
import { TabContent } from "components/Tabs/styled";

export const CompaniesListingComponent = ({ company, ...props }) => {
  return (
    <CompaniesListingStyled>
      <TabHeader heading="Companies Listing" />
      <TabContent>
        {props.current_user.shared_profiles.length ? (
          <ListStyled className="row">
            {[
              props.current_user.shared_profiles.map(({ company }) => (
                <CompanyCard
                  key={company.id}
                  id={company.id}
                  name={company.name}
                  size={company.size}
                  logo={company.logo_path}
                  banner={company.banner_path}
                  className={`col md-6 lg-4`}
                  admin={props.admin}
                  location={company.location}
                  profileType={company.profile_type}
                  linkNewTab={true}
                />
              )),
            ]}
          </ListStyled>
        ) : (
          <div className="message">No Shared Profiles</div>
        )}
      </TabContent>
    </CompaniesListingStyled>
  );
};

const mapStateToProps = (state) => {
  const current_user = state.auth.user;

  return {
    current_user: current_user,
  };
};

export const CompaniesListing = connect(
  mapStateToProps,
  {}
)(CompaniesListingComponent);
