import React from "react";
import { Button } from "../../Button";
import { Heading } from "../../Heading";
import { Modal } from "../../Modal";
import { navigate } from "@reach/router";
import ShortlistImage from "./shortlist-upgrade.png";
import Shortlist from "./Shortlist.png";
import { ReactComponent as CheckIcon } from "../../../icons/check-circle.svg";
import { ShortlistUpgradeWrapper } from "./styled";

export const ShortlistUpgradeModal = ({ isOpen, setIsOpen, guestEmail }) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => setIsOpen(false)}
      width="926"
      closeIconTop="24px"
      blurBackground
      backgroundImage={Shortlist}
    >
      <ShortlistUpgradeWrapper>
        <img src={ShortlistImage} alt="header-img" />
        <div className="side-content">
          <Heading fontSize="48px" marginBottom="24px">
            manage your risk profile & shortlist SUITABLE suppliers that have:
          </Heading>
          <span>
            <CheckIcon /> Demonstrated Projects & Experience
          </span>
          <span>
            <CheckIcon /> Key Personnel & Expertise
          </span>
          <span>
            <CheckIcon />
            Insurance, OHS, Environmental Sustainability and Governance
          </span>
          <p>Stop Copying into spreadsheets!</p>
          <p>Save time and money with our automated shortlist reports.</p>
          <Button
            size="sm"
            type="button"
            width="204px"
            height="56px"
            textfont="14px"
            boxshadow={false}
            onClick={() =>
              navigate("/signup", {
                state: {
                  email: guestEmail,
                },
              })
            }
          >
            UPGRADE ACCOUNT
          </Button>
          <p>Discover the power of Procuracon.</p>
        </div>
      </ShortlistUpgradeWrapper>
    </Modal>
  );
};
