import React, { useState } from 'react';
import { connect } from 'react-redux';
import Skeleton from 'react-loading-skeleton';

import { Heading } from '../Heading';
import { Button } from '../Button';
import { CompanySidebarStyled, CompanyImage, CompanySummary } from './styled';
import { ContactDetails } from '../ContactDetails';
import { Modal } from '../Modal';
import { CompanyAboutCard } from '../CompanyAboutCard';
import { addToList } from '../../actions/searchActions';
import { TabContent } from '../Tabs/styled';
import { betterClearHTMLTags } from 'components/FormInputs/FormatedInput';

export const CompanySidebarComponent = ({ company, dispatch, addToList, groupId, ...props }) => {
    const [modalIsOpen, setModalIsOpen] = useState(false);

    const openInfoModal = () => {
        setModalIsOpen(true);
    };

    const closeInfoModal = () => {
        setModalIsOpen(false);
    };
    const textDescription = betterClearHTMLTags(company?.description) 

    // const handleAddToList = () => {
    //     addToList(company, 'compare', groupId);
    // };

    // const handleAddToShortlist = () => {};

    const globalPresence = company.global_presence ? company.global_presence.map((g) => g.name) : [];

    return (
        <TabContent padding="0px 0px 0px 20px">
        <CompanySidebarStyled>
            {!props.isLoading ? (
                <>
                    <CompanyImage>
                        <img src={company.logo_path} alt='' />
                    </CompanyImage>
                    <Heading>{company.name}</Heading>
                    <CompanySummary>
                        {textDescription?.substring(0, 120)}
                        {textDescription?.length > 119 && '...'}
                    </CompanySummary>

                    <Button size='sm' onClick={() => openInfoModal()} style={{ alignSelf: 'flex-start' }}>
                        Read More
                    </Button>

                    <div className='section--contact'>
                        {
                            company.years_trading || globalPresence.length > 0 ?
                            <h5>Company Information</h5>
                            :
                            <></>
                        }
                        {
                            company.years_trading ?
                            <h5 style={{marginBottom: 0}}>Years Of Trading - <span style={{fontWeight: 'normal'}}>{company.years_trading}</span></h5>
                            :
                            <></>
                        }
                        {
                            globalPresence.length > 0 ?
                            <h5 style={{lineHeight: '19px', marginTop: '5px'}}>Global Presence - <span style={{fontWeight: 'normal'}}>{globalPresence.join(', ')}</span></h5>
                            : 
                            <></>
                        }
                    </div>

                    <div className='section--contact'>
                        <h5>Contact Information</h5>
                        <ContactDetails
                            phone={company.phone}
                            email={company.email}
                            address={company.locations[0]?.address}
                            website={company.website}
                            />
                    </div>

                    {/* JM - commenting out until project module is commenced - 16/9/21 */}

                    {/* <div className='footer'>
                        <Button onClick={handleAddToList}>Add to compare</Button>
                        <Button btntype='secondary' onClick={handleAddToShortlist}>
                            Add to shortlist
                        </Button>
                    </div> */}

                    <Modal isOpen={modalIsOpen} onRequestClose={closeInfoModal} width='800'>
                        <CompanyAboutCard
                            content={company.description}
                            logo={company.logo_path}
                            bg={company.banner_path}
                        />
                    </Modal>
                </>
            ) : (
                <Skeleton count={5} width={window.width < 1600 ? 350 : 250} duration={1} />
            )}
        </CompanySidebarStyled>
        </TabContent>
    );
};

const mapStateToProps = (state) => {
    return {
        groupId: state.search.groupId,
        company: state.search.activeCompany,
        isLoading: state.search.isLoading
    };
};

export const CompanySidebar = connect(mapStateToProps, { addToList })(CompanySidebarComponent);
