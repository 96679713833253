import styled from 'styled-components';


export const EditPlanStyled = styled.div`

    h3{
        font-family: Roboto;
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        color: #253858;
    }

    .btn-row{
        margin-bottom:40px;
        margin-top: 24px;
        display: flex;
        justify-content: space-between;
        .delete-btn{
            background: #FE0202;
            border: 1px solid #FE0202;
        }
    }

`

export const ChangePlanInfo = styled.div`
  margin-bottom: 18px;
`
