import React, { useEffect } from 'react';
import { Router, navigate } from '@reach/router';

import { PageWrap, ContentContainer } from '../components/Layout';

//Routes
import { Shortlist } from './search/Shortlist';
import { NotFound } from '../routes/NotFound';
import { useSelector } from 'react-redux';

export const CompanyListing = (props) => {
    let { allow_companies_listing_access } = useSelector((state) => state?.auth?.current_company)
    allow_companies_listing_access = parseInt(allow_companies_listing_access)
    useEffect(() => {
        if (localStorage.getItem('is_admin') !== 'true') {
            if (allow_companies_listing_access !== 1) {
                navigate('/404');
            }
        }
        //eslint-disable-next-line
    }, []);

    return (
        <PageWrap>
            <ContentContainer>
                <Router primary={false}>
                    <Shortlist path='/' admin={props.admin} linkNewTab={false} />
                    <NotFound default />
                </Router>
            </ContentContainer>
        </PageWrap>
    );
};
