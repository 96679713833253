import api from "api";
import * as constants from "./actionTypes";

function getApiWithToken() {
  const token = localStorage.getItem("access_token");
  return api.extend({
    hooks: {
      beforeRequest: [
        (request) => {
          request.headers.set("Authorization", `Bearer ${token}`);
        },
      ],
    },
  });
}

export const getSectors = (company_id = null) => {
  let guest_email = JSON.parse(localStorage.getItem("guest_user")) || null;
  return async (dispatch) => {
    (async () => {
      try {
        const project_count = company_id ? `?company_id=${company_id}&` : "?";
        const sectors = await getApiWithToken()
          .get(`sectors${project_count}email=${guest_email?.email}`)
          .json();
        await dispatch({ type: constants.GET_SECTORS, payload: sectors.data });
      } catch (e) {
        console.log(e);
      }
    })();
  };
};
