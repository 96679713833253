import React, {useState, useEffect} from 'react';
import { connect } from 'react-redux';
import Skeleton from 'react-loading-skeleton';
import { getApiWithToken} from '../../utils/helpers';
import { LogsStyled, TableSection } from './styled';
import Pagination from 'react-js-pagination';
import { LogTable } from './styled';
import { Paginator } from './styled';
import { TabHeader } from '../TabHeader';
import { TabContent } from '../Tabs/styled';



export const LogsComponent = ({company, ...props }) => {
    

    const [logs, setLogs] = useState({});

    const fetchLogs = async (pageNumber = 1) => {
        const result = await getApiWithToken().get(`logs?company_id=${company.id}&page=${pageNumber}`).json();
        setLogs(result);
    };

    useEffect(() => {
        if(company)
            fetchLogs();
    }, [company]) // eslint-disable-line

    return (
        <LogsStyled>
            <TabHeader heading="Company Logs" />
            <TabContent>
            <TableSection>
                {logs?.data ?
                <LogTable>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Date &amp; Time</th>
                            <th>Type</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                    {   
                        logs?.data?.map((log) => (
                            <tr key={log.id}>
                                <td>{log.id}</td>
                                <td>{new Date(log.created_at).toDateString()} {new Date(log.created_at).toLocaleTimeString()}</td>
                                <td className='log-type'>{log.type}</td>
                                <td>{log.action}</td>
                            </tr>
                        ))
                    }
                    </tbody>
                </LogTable>:
                <Skeleton count={10} duration={0.5} />}
            </TableSection>
            {logs.last_page > 1 ? 
            <Paginator>
                <Pagination
                    activePage={logs?.current_page ? logs?.current_page : 0}
                    itemsCountPerPage={logs?.per_page ? logs?.per_page : 0 }
                    totalItemsCount={logs?.total ? logs?.total : 0}
                    onChange={(pageNumber) => {
                        fetchLogs(pageNumber)
                    }}
                    pageRangeDisplayed={8}
                    itemClass="page-item"
                    linkClass="page-link"
                    firstPageText="First"
                    lastPageText="Last"
                />
            </Paginator>: null}
            </TabContent>
        </LogsStyled>
    );
};

const mapStateToProps = (state) => {
    const company = state.search.activeCompany;
    const current_user = state.auth.user;
    
    return {
        isRequesting: state.companyProfile.isRequesting,
        company: company,
        current_user:current_user,
    };
};

export const Logs = connect(mapStateToProps, {})(
    LogsComponent
);
