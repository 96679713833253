import * as constants from '../actions/actionTypes';

const INITIAL_ACCOUNT_TYPE_STATE = {
    accountTypeData: []

};

export const accountTypeReducer = (state = INITIAL_ACCOUNT_TYPE_STATE, action) => {
    switch (action.type) {
        case constants.GET_ACCOUNT_TYPES:
            return {
                ...state,
                accountTypeData: action.payload
            };
        
        default:
            return state;
    }
};
