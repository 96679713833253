import React from "react";
import { ContentRow, ContentCollumn } from "components/CustomTable";
import { Button } from "components/Button";
import { navigate } from "@reach/router";

const InvoiceContentRow = ({ item, i, prevItem, ...props }) => {
  const getPlanLabel = (planname) => {
    const index = props.accountTypes?.findIndex(
      (type) => type.package_name === planname
    );
    return props.accountTypes[index]?.label;
  };
  const options = {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  };
  const formatSeperator = (number) => {
    return Number(number).toLocaleString("en", options);
  };

  return (
    <ContentRow key={i}>
      <ContentCollumn className="md-1 data-cell">
        {"000" + item.id}
      </ContentCollumn>
      <ContentCollumn className="md-2 data-cell">
        {item.transaction_date}
      </ContentCollumn>
      <ContentCollumn className="md-3 data-cell">
        {getPlanLabel(item?.plan_name)}
      </ContentCollumn>
      <ContentCollumn className="md-3 data-cell">{`$${formatSeperator(
        item.charged_amount / 100 - item.gst
      )} (Excl. GST)`}</ContentCollumn>
      <ContentCollumn className="md-3 data-cell no-border action-cell">
        {item.charged_amount ? "Paid" : "Unpaid"}
        <Button
          size={"xs"}
          btntype={"secondary"}
          width={"103px"}
          height={"35px"}
          textfont={"14px"}
          boxshadow={false}
          radius={"20px"}
          onClick={() => {
            navigate("account-management/invoice-view", {
              state: {
                invoice: item,
                prevItem: prevItem,
                id: i,
                invoiceLabel: getPlanLabel(item?.plan_name),
                prevLabel: prevItem ? getPlanLabel(prevItem.plan_name) : null,
              },
            });
          }}
        >
          View
        </Button>
      </ContentCollumn>
    </ContentRow>
  );
};

export default InvoiceContentRow;
