import React from "react";
import { connect } from "react-redux";
import { Heading } from "../Heading";
import { Button } from "components/Button";
import { getCompany, updateSubscription } from "actions/companyActions";
import { getAccountTypes } from "actions/accountTypeActions";
import { ConfirmCancelStyled, ButtonRow, DialogConfirm } from "./styled";
import { useEffect } from "react";
import { Submit } from "components/FormInputs/Submit";
import { useFormikContext } from "formik";
import { profileTypeOptions } from "utils/helpers";


function ConfirmationModalComponent({ 
  isOpen, 
  setIsOpen, 
  prevId, 
  idClicked, 
  isSubmitting, 
  setSubmitting,
  profileType, 
  ...props }) {
  const prevPlan = props.accountTypes?props.accountTypes[prevId - 1 ].label: "-";
  const confirmPlan = props.accountTypes?props.accountTypes[idClicked - 1].label: "-";
  const { values, submitForm } = useFormikContext();
  

  useEffect(()=>{
    getCompany();
    getAccountTypes();
  },[])

  const handleClose = () => {
    setIsOpen(false);
  };

  const curentProfileType = profileTypeOptions.filter(
    (item) => item.value === profileType.id
    
  );

  const updatedProfileType = profileTypeOptions.filter(
    (item) => item.value === values.profile_type_id
    
  );

  console.log("profile type current", curentProfileType, "profile type selected", updatedProfileType)
  return (
    <ConfirmCancelStyled
      isOpen={isOpen}
      onRequestClose={() => setIsOpen(false)}
      width="650"
      height="570px"
      closeIconHeight="24px"
      closeIconWidth="24px"
      closeIconTop="20px"
    >
      <Heading>Confirmation</Heading>
      <DialogConfirm>
        Are you sure you want to update the subscription from  <b>
         &nbsp;{prevPlan}
          {curentProfileType[0].label !== "Client" && 
            <span>{"( "}{curentProfileType[0].label}{" )"}&nbsp;</span> 
          }
          </b>&nbsp;
          to 
          <b> &nbsp;
            {confirmPlan}
            {idClicked !== 1 && 
            <span>{"( "}{updatedProfileType[0].label}{" )"}</span>
          }
            </b>?
      </DialogConfirm>
      <ButtonRow>
          <Button
            size={"md"}
            danger
            className="cancel-btn"
            btntype={"secondary"}
            width={"218px"}
            textfont={"14px"}
            boxshadow={false}
            onClick={handleClose}
            type="button"
          >
            Cancel
          </Button>
          <Submit
            type="submit"
            size={"md"}
            dangers
            className="confirm-btn"
            boxshadow={false}
            onClick={submitForm}
            text="Update Subscription"
            submittingText="Sending..."
            style={
              isSubmitting
                ? {
                    background: "#E9ECF1",
                    color: "#8492A6",
                    borderColor: "#d2d5da",
                  }
                : {}
            }
        >
        </Submit>
      </ButtonRow>
    </ConfirmCancelStyled>
  );
}

const mapStateToProps = (state) => {
    const company = state.search.activeCompany;
    const account_types = state.accountTypes.accountTypeData;
    return {
      company: company,
      accountTypes:account_types
    };
  };
  
  const ConfirmationModal = connect(mapStateToProps, {
    getCompany,
    updateSubscription,
    getAccountTypes
  })(ConfirmationModalComponent);

  export default ConfirmationModal;
