import React from "react";
import { Button } from "../../Button";
import { Heading } from "../../Heading";
import { Modal } from "../../Modal";
import { navigate } from "@reach/router";
import SearchImage from "./search-upgrade.png";
import SearchCompare from "./Search&Compare.png";
import { SearchUpgradeWrapper } from "./styled";

export const SearchUpgradeModal = ({ isOpen, setIsOpen, guestEmail }) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => setIsOpen(false)}
      width="926"
      closeIconTop="24px"
      blurBackground
      backgroundImage={SearchCompare}
    >
      <SearchUpgradeWrapper>
        <img src={SearchImage} alt="header-img" />
        <div className="side-content">
          <Heading fontSize="48px">
            Become a member to build your project team
          </Heading>
          <p>
            <span>Search, Compare & Shortlist</span> suppliers instantly.{" "}
          </p>
          <p>Save time and money with our automated shortlist reports.</p>
          <Button
            size="sm"
            type="button"
            width="204px"
            height="56px"
            textfont="14px"
            boxshadow={false}
            onClick={() =>
              navigate("/signup", {
                state: {
                  email: guestEmail,
                },
              })
            }
          >
            UPGRADE ACCOUNT
          </Button>
        </div>
      </SearchUpgradeWrapper>
    </Modal>
  );
};
