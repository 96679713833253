
import { toast } from "react-toastify";
import React, { useState } from "react";
import { connect } from "react-redux";
import { ModalBodyStyled, ModalHeader } from "./styled";
import { Button } from "../Button";
import { ReactComponent as CopyIcon } from "../../icons/copy.svg";
import { Heading } from "../Heading";

export const ShareProfileComponent = ({
  invite_link,
}) => {
  const [isCopyLink, setIsCopyLink] = useState(false);
  const copyLink = () => {
    navigator.clipboard.writeText(invite_link);
    setIsCopyLink(true);
    toast.success("Link Copied Successfully");
  };

  return (
    <>
      <ModalHeader>
        <Heading fontSize="32px">SHARE PROFILE</Heading>
      </ModalHeader>
      <ModalBodyStyled>

          <div className="row">
            <div className="col xl-12">
              <label>
                Share a link to your Company Profile and show your clients all the great things your business can do.
              </label>
              <br />
              <br />
              <label>
                <b>Note:</b>
              </label>
              <ul>
                <li>
                  {" "}
                  Anyone with this link can view your Company Profile (Guest User sign-up required).
                </li>
                <li>
                  {" "}
                  Guest Users are not permitted to request access to your private documents.
                </li>
              </ul>
            </div>
            <div className="col xl-12 buttons">
              <Button
                padding="0px !important"
                type="submit"
                btntype="secondary"
                width="140px"
                height="48px"
                boxshadow={false}
                onClick={copyLink}
              >
                <CopyIcon />
                <span>{isCopyLink ? "Link Copied" : "Copy Link"}</span>
              </Button>
            </div>
          </div>
      </ModalBodyStyled>

    </>
  );
};

const mapStateToProps = (state) => {
  return {
    invite_link: state.auth.current_company.invite_link,
  };
};

export const ShareProfile = connect(mapStateToProps)(ShareProfileComponent);
