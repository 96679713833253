import React from 'react';
import styled from 'styled-components';
// import ScrollBooster from 'scrollbooster';

import { Viewport, LockedItems } from './styled';
import { CompanyResultCard } from '../../components/CompanyResultCard';

export const ResultsListStyled = styled.div`
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
    height: 100%;
    overflow-x: scroll;
`;

export const ResultsList = React.forwardRef(({ children, lockedSearchItems, isCompare, cardType, compareSlideoutClick, ...props }, ref) => {
    return (
        <Viewport className='viewport' ref={ref}>
            {lockedSearchItems.length > 0 && (
                <LockedItems>
                    {lockedSearchItems.map((company) => {
                        if (company !== undefined) {
                            return (
                                <CompanyResultCard
                                    key={`search_locked_${company.id}`}
                                    company={company}
                                    compareCard={isCompare}
                                    locked
                                    cardType={cardType}
                                    compareSlideoutClick={compareSlideoutClick}
                                />
                            );
                        } else {
                            return '';
                        }
                    })}
                </LockedItems>
            )}

            {children}
        </Viewport>
    );
});

ResultsList.defaultProps = {
    lockedSearchItems: []
};
