import styled from 'styled-components';

export const DataTableHeader = styled.div`
    display: table-cell;
    border-collapse: separate;
    padding-left: 1.25rem;
    font-weight: 500;
    color: ${(props) => props.theme.colors.grayLight};
    transition: ${(props) => props.theme.transitions.default};
    font-size: ${(props) => props.theme.fontSizes.xs};
    white-space: nowrap;
    cursor: ${(props) => (props.isSortable ? 'pointer' : 'default')};

    &:nth-of-type(2) {
        padding-left: ${(props) => props.theme.space.sm};
    }

    &::hover {
        color: ${(props) => (props.isSortable ? props.theme.colors.royalBlue : props.theme.colors.grayLight)};
        .tooltip-container{
                opacity: 1;
            }
    }
    .tooltip-container{
        position: absolute;
            opacity: 0;
            left: 50%;
            transform: translateX(-50%);
            padding: 5px 9px;
            background: white;
            outline: solid 2px;
            z-index: 99;
            border-radius: 2px;
            top: 24px;
            transition: ease-in-out 0.5s;
    }
`;

export const DataTableStyled = styled.div`
    display: table;
    width: 100%;
    border-spacing: 0 16px;
    border-collapse: separate;
    ${'' /* max-width: 1500px; */}

    .table-header {
        display: table-row;
        border-collapse: separate;
    }

    .table-body {
        display: table-row-group;
        border-collapse: separate;
    }

    .cell-inner {
        padding: 10px 0;
    }
`;
