import React from "react";
import { Button } from "../../Button";
import { Heading } from "../../Heading";
import { Modal } from "../../Modal";
import { navigate } from "@reach/router";
import SearchImage from "./search-upgrade.png";
import { AdditionalProfilesWrapper } from "./styled";

export const AdditionalProfilesModal = ({
  isOpen,
  setIsOpen,
  redirectUrl,
  guestEmail,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => navigate(redirectUrl)}
      width="926"
      closeIconTop="24px"
      blurBackground
    >
      <AdditionalProfilesWrapper>
        <img src={SearchImage} alt="header-img" />
        <div className="side-content">
          <Heading fontSize="48px">
            Upgrade your account to view additional profiles
          </Heading>
          <Button
            size="sm"
            type="button"
            width="204px"
            height="56px"
            textfont="14px"
            boxshadow={false}
            onClick={() =>
              navigate("/signup", {
                state: {
                  email: guestEmail,
                },
              })
            }
          >
            UPGRADE ACCOUNT
          </Button>
        </div>
      </AdditionalProfilesWrapper>
    </Modal>
  );
};
