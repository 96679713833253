import api from "api";
import * as constants from "./actionTypes";
import { processFormData } from "../utils/helpers";
import { toast } from "react-toastify";
import { navigate } from "@reach/router";

export const getGuestUser = (id) => {
  return async (dispatch) => {
    (async () => {
      try {
        const res = await api.get(`guest/user/${id}`).json();
        localStorage.setItem("guest_user", JSON.stringify(res.user));
        await dispatch({
          type: constants.AUTHENTICATE_GUEST_USER,
          payload: res.user,
        });
      } catch (e) {
        const error = await e.response.json();
        toast.error(error.message);
      }
    })();
  };
};


export const guestForgotPassword = (values, setSubmitting) => {
  return async () => {
    const formData = processFormData(values);

    try {
      const status = await api
        .post("guest/forget-password", { body: formData, timeout: false })
        .json();

      if (status.status) {
        setSubmitting(false);
        return status;
      } else {
        setSubmitting(false);
        return status;
      }
    } catch (e) {
      const error = await e.response.json();
      toast.error(error.message, {
        autoClose: 7000,
      });
    }
    setSubmitting(false);
  };
};

export const guestResetPassword = (values, setSubmitting) => {
  return async () => {
    const formData = processFormData(values);

    try {
      const status = await api
        .post("guest/reset-password", { body: formData, timeout: false })
        .json();
      if (status.success) {
        toast.success("Reset Password successfully. Redirecting now.", {
          autoClose: 7000,
        });
        navigate("/guest/login");
      } else {
        toast.error(status.message, {
          autoClose: 7000,
        });
      }
    } catch (e) {
      const error = await e.response.json();
      toast.error(error.message, {
        autoClose: 7000,
      });
    }

    setSubmitting(false);
  };
};
