import React, { useEffect } from "react";
import { Router } from "@reach/router";
import { connect } from "react-redux";
import { SearchForm } from "./SearchForm";
import { SearchContainer } from "./SearchContainer";
import { searchCompanies } from "../../actions/searchActions";
import { getSectors } from "../../actions/sectorActions";
import { getAllDisciplines } from "../../actions/searchActions";
import { getAllTypes } from "../../actions/searchActions";

const NotFound = () => {
  return <h1>ERROR: PATH NOT FOUND</h1>;
};

const SearchConnected = ({
  searchCompanies,
  compareList,
  shortlist,
  projectId,
  getSectors,
  searchCriteria,
  getAllDisciplines,
  getAllTypes,
  lockedCompareItems,
  lockedSearchItems,
  ...props
}) => {
  useEffect(() => {
    getSectors();
    getAllDisciplines();
    getAllTypes();
  }, [getSectors, getAllDisciplines, getAllTypes]);

  return (
    <Router primary={false} style={{ width: "100%" }}>
      <SearchForm
        path="/"
        projectId={projectId}
        searchCompanies={searchCompanies}
      />
      <SearchContainer
        path="/:groupId/*"
        compareList={compareList}
        shortlist={shortlist}
        lockedCompareItems={lockedCompareItems}
        lockedSearchItems={lockedSearchItems}
      />
      <NotFound default />
    </Router>
  );
};

const mapStateToProps = (state) => {
  return {
    locations: [],
    projectId: state.search.projectId,
    compareList: state.search.compareList,
    shortlist: state.search.shortlist.map((shortlistItem) => {
      let companyInCompare = state.search.compareList.find(
        (compareItem) => compareItem.id === shortlistItem.id
      );
      return companyInCompare ? companyInCompare : shortlistItem;
    }),
    lockedSearchItems:
      state.search.searchResult &&
      state.search.lockedSearchItems.map((lockedCompanyId) =>
        state.search.searchResult.find(
          (company) => company.id === lockedCompanyId
        )
      ),
    lockedCompareItems:
      state.search.searchResult &&
      state.search.lockedCompareItems.map((lockedCompanyId) =>
        state.search.compareList.find(
          (company) => company.id === lockedCompanyId
        )
      ),
  };
};

export const Search = connect(mapStateToProps, {
  searchCompanies,
  getSectors,
  getAllDisciplines,
  getAllTypes,
})(SearchConnected);
