import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import Skeleton from 'react-loading-skeleton';
import { TextInput } from '../FormInputs/TextInput';
import { Submit } from '../FormInputs/Submit';
import { UserFormStyled} from './styled';
import { Select } from '../FormInputs/Select';
import { findUserRole, getPasswordStrength, userRoles } from '../../utils/helpers';
import { createCompanyUser } from '../../actions/authActions';
import { navigate } from '@reach/router';
import { TabHeader } from '../TabHeader';
import { TabContent } from '../Tabs/styled';
import { PasswordValidator } from 'components/FormInputs/PasswordValidator';


export const CreateUserFormComponent = ({ company,...props }) => {
    const [passwordRules, setPasswordRules] = useState({
        letter: false,
        capital: false,
        number: false,
        count: false,
      });
    const initialValues = {
        first_name:"",
        last_name:"",
        phone:"",
        email:"",    
        position:"",
        company_id:company?.id
    }
    return company ? (
        <UserFormStyled>
            <Formik
                enableReinitialize
                initialValues={initialValues}
                validationSchema={Yup.object({
                    first_name:Yup.string().required('* This Field is required'),
                    last_name:Yup.string().required('* This Field is required'), 
                    email: Yup.string().email().required('* This Field is required'),
                    password: Yup.string()
                        .required("* This Field is required")
                        .test(
                          "strong password",
                          "*The supplied password does not meet the requirements",
                          (value) => {
                            let validRules = passwordRules;
                            if (value) {
                              validRules = getPasswordStrength(value, passwordRules);
                              setPasswordRules(validRules);
                              return Object.values(validRules).every((val) => val === true);
                            } else {
                              validRules.letter = false;
                              validRules.number = false;
                              validRules.capital = false;
                              validRules.count = false;
                              setPasswordRules(validRules);
                              return false;
                            }
                          }
                        ),
                    position: Yup.string().required('* This Field is required'),
                    phone: Yup.string().required('* THIS FIELD IS REQUIRED').matches(/^(?=.*)((?:\+?61) ?(?:\((?=.*\)))?([2-47-8])\)?|(?:\((?=.*\)))?([0-1][2-47-8])\)?) ?-?(?=.*)((\d{1} ?-?\d{3}$)|(00 ?-?\d{4} ?-?\d{4}$)|( ?-?\d{4} ?-?\d{4}$)|(\d{2} ?-?\d{3} ?-?\d{3}$))/,'Invalid Phone No.'),
                })}
                onSubmit={async (values, { setErrors, setSubmitting }) => {
                    try {
                        const result = await props.createCompanyUser(values,setSubmitting);
                        if(result.success){
                            navigate(-1);
                        }
                    } catch (e) {
                        const error = await e;
                        error.errors !== undefined && setErrors(error.errors);
                    }
                }}
            >
                {(props) => {
                    const {isSubmitting, values} = props;
                    return ( <>
                        <TabHeader heading="Create User" />
                        <TabContent>
                        <Form>
                            <div className='row'>
                                <TextInput 
                                    label='First Name'
                                    required
                                    name='first_name'
                                    className='col xl-6'
                                    placeholder={"First Name"}
                                />
                                <TextInput 
                                    label='Last Name'
                                    required
                                    name='last_name'
                                    className='col xl-6'
                                    placeholder={"Last Name"}
                                />
                                <TextInput 
                                    label='Phone Number'
                                    required
                                    name='phone'
                                    className='col xl-6'
                                    placeholder={"Phone No"}
                                />
                                <TextInput 
                                    label='Email Address'
                                    required
                                    name='email'
                                    className='col xl-6'
                                    placeholder={"Email"}
                                />
                                <Select
                                    required="true"
                                    options={userRoles}
                                    name={"position"}
                                    label={"User Type"}
                                    className={"col xl-6"}
                                    value={findUserRole(values.position)}
                                />
                                <TextInput 
                                data-tip
                                    data-for="pass_validation"
                                    label='Password'
                                    required
                                    name='password'
                                    className='col xl-6'
                                    icon
                                    type="password"
                                />
                                <PasswordValidator
                                    dataId={"pass_validation"}
                                    passwordRules={passwordRules}
                                />
                                <div className='col xl-6'>
                                    <Submit
                                        type='submit'
                                        isSubmitting={isSubmitting}
                                        text='Save'
                                        submittingText='Saving...'
                                    />
                                </div>
                            </div>
                        </Form>
                        </TabContent>
                        </>
                    );
                }}
            </Formik>
        </UserFormStyled>
    ) : (
        <Skeleton count={5} duration={0.5} />
    );
};

const mapStateToProps = (state) => {
    const company = state.search.activeCompany;
    const current_user = state.auth.user;
    
    return {
        company: company,
        current_user:current_user,
    };
};

export const CreateUserForm = connect(mapStateToProps, {createCompanyUser})(
    CreateUserFormComponent
);
