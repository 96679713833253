import React from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";

import { DocumentFormStyled } from "./styled";
import { FormHeading } from "../FormHeading";
import { TextInput } from "../FormInputs/TextInput";
import { FileUpload } from "../FormInputs/FileUpload";
import { DocumentCategory } from "../FormInputs/DocumentCategory";
import { DocumentSubCategory } from "../FormInputs/DocumentSubCategory";
import { Checkbox } from "../FormInputs/Checkbox";
import { RelevantYear } from "../FormInputs/RelevantYear";
import { DateInput } from "../FormInputs/DateInput";
import { Currency } from "../FormInputs/Currency";
import { OnSubmitValidationError } from "../../utils/OnSubmitValidationError";
import { Submit } from "../FormInputs/Submit";

export const DocumentForm = ({
  document,
  handleSubmit,
  initialValues,
  profileTypeId,
  title,
  ...props
}) => {
  return (
    <DocumentFormStyled>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={Yup.object().shape(
          {
            category_id: Yup.string().required(),
            file: Yup.mixed().required(),
            name: Yup.string().when("sub_category_id", {
              is: (sub_category_id) =>
                !sub_category_id || sub_category_id.length === 0,
              then: Yup.string().required(),
              otherwise: Yup.string(),
            }),
            sub_category_id: Yup.object().when("name", {
              is: (name) => !name || name.length === 0,
              then: Yup.object().required(),
              otherwise: Yup.object().nullable(),
            }),
          },
          ["sub_category_id", "name"]
        )}
        onSubmit={handleSubmit}
      >
        {(props) => {
          const { setFieldValue, isSubmitting, values } = props;

          const onSubmitValidationError = () => {
            toast.error("Please review field errors in form.");
          };

          let subCategoryName = "Sub Category";

          switch (values.category_id) {
            case 5:
              subCategoryName =
                "OUR OCCUPATIONAL HEALTH AND SAFETY MANAGEMENT SYSTEM CONFORMS TO THE REQUIREMENTS OF";
              break;
            case 6:
              subCategoryName = "OUR COMPANY POLICIES AND STATEMENTS INCLUDE";
              break;
            case 7:
              subCategoryName =
                "OUR QUALITY MANAGEMENTSYSTEM CONFORMS TO THE REQUIREMENTS OF";
              break;
            default:
              subCategoryName = "Sub Category";
          }

          return (
            <Form>
              <div className="container">
                <FormHeading text={title || "Document Detail"} />
                <div className="row">
                  <DocumentCategory
                    label="Category"
                    required="true"
                    name="category_id"
                    setFieldValue={setFieldValue}
                    defaultValue={initialValues.category_id}
                    values={values}
                    className="col xl-12"
                  />

                  {values.category_id &&
                    ![4, 5, 6, 7].includes(values.category_id) && (
                      <>
                        <TextInput
                          label={
                            values.category_id === 2
                              ? "Name of Body / Agency registered with"
                              : "Document Name"
                          }
                          required="true"
                          name="name"
                          type="text"
                          className="col xl-12"
                        />
                      </>
                    )}

                  {[4, 5, 6, 7].includes(values.category_id) && (
                    <>
                      <DocumentSubCategory
                        label={subCategoryName}
                        required="true"
                        name="sub_category_id"
                        setFieldValue={setFieldValue}
                        defaultValue={initialValues.sub_category_id}
                        values={values}
                        selectedCategory={values.category_id}
                        className="col xl-12"
                      />
                      {/* 21 => Other Occupational Health and Safety Standard or Scheme */}
                      {values?.sub_category_id?.id === 21 && (
                        <>
                          <>
                            <TextInput
                              label="Document Name"
                              required="true"
                              name="name"
                              type="text"
                              className="col xl-12"
                            />
                          </>
                        </>
                      )}
                    </>
                  )}
                  {/* Insurance Certificates */}
                  {values.category_id === 4 && (
                    <>
                      <DateInput
                        name="extra_info[expiry_date]"
                        label="Expiry Date"
                        setFieldValue={setFieldValue}
                        values={values}
                        className="col xl-12"
                      />

                      <Currency
                        label="Value"
                        name="extra_info[value]"
                        type="number"
                        lang="en_EN"
                        setFieldValue={setFieldValue}
                        values={values}
                        className="col xl-12"
                      />
                    </>
                  )}

                  {/* Financial Report */}
                  {values.category_id === 3 && (
                    <>
                      <RelevantYear
                        name="extra_info[relevant_year]"
                        setFieldValue={setFieldValue}
                        defaultValue={initialValues.extra_info.relevant_year}
                        className="col xl-12"
                      />
                      {profileTypeId !== 3 && (
                        <Currency
                          label="Annual Turnover"
                          name="extra_info[annual_turnover]"
                          type="number"
                          lang="en_EN"
                          setFieldValue={setFieldValue}
                          values={values}
                          className="col xl-12"
                        />
                      )}
                      <Checkbox
                        label="Audited"
                        name="extra_info[audited]"
                        className="col xl-12"
                      />
                    </>
                  )}

                  {values.category_id && (
                    <>
                      <FileUpload
                        label={
                          values.category_id === 2
                            ? "Evidence of Registration / Certification"
                            : "File"
                        }
                        required="true"
                        values={values}
                        name="file"
                        setFieldValue={setFieldValue}
                        className="col xl-12"
                      />
                    </>
                  )}
                </div>

                {values.category_id && (
                  <Submit
                    type="submit"
                    isSubmitting={isSubmitting}
                    text="Save to Profile"
                    submittingText="Saving..."
                  />
                )}
              </div>

              <OnSubmitValidationError callback={onSubmitValidationError} />
            </Form>
          );
        }}
      </Formik>
    </DocumentFormStyled>
  );
};
