import React from 'react';
import styled from 'styled-components';

export const KeywordStyled = styled.div`
    background-color: rgba(15, 227, 199, 0.1);
    border: 2px solid rgba(15, 227, 199, 0.1);
    color: ${(props) => props.theme.colors.mintDark};
    font-size: 0.75rem;
    font-weight: 500;
    display: inline-block;
    padding: 4px 31px;
    border-radius: ${(props) => props.theme.radii.default};
`;

export const Keyword = (props) => {
    return <KeywordStyled>{props.children}</KeywordStyled>;
};
