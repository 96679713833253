import { combineReducers } from "redux";
import { searchReducer } from "./searchReducer";
import { companyProfileReducer } from "./companyProfileReducer";
import { sectorsReducer } from "./sectorsReducer";
import { globalPresenceReducer } from "./globalPresenceReducer";
import { authReducer } from "./authReducer";
import { accountTypeReducer } from "./accountTypeReducer";
import { accountManagementReducer } from "./accountManagementReducer";

const appReducer = combineReducers({
  accountTypes: accountTypeReducer,
  search: searchReducer,
  companyProfile: companyProfileReducer,
  sectors: sectorsReducer,
  globalPresence: globalPresenceReducer,
  auth: authReducer,
  accountManagement: accountManagementReducer,
});

const rootReducer = (state, action) => {
  if (action.type === "LOGOUT_USER") {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

export default rootReducer;
