import api from 'api';
import * as constants from './actionTypes';

function getApiWithToken() {
    const token = localStorage.getItem("access_token");
    return api.extend({
      hooks: {
        beforeRequest: [
          (request) => {
            request.headers.set("Authorization", `Bearer ${token}`);
          },
        ],
      },
    });
  }

export const getAccountTypes = () => {

    return async (dispatch) => {

        (async () => {
            const accountTypes = await getApiWithToken().get('account-types').json();
            await dispatch({ type: constants.GET_ACCOUNT_TYPES, payload: accountTypes.data });
            
        })();
    };
};

