import React from 'react';
import { connect } from 'react-redux';
import _get from 'lodash.get';
import { useFormikContext } from 'formik';

import { SectorsMulti } from './SectorsMulti';
import { Select } from '../Select';

export const SectorsComponent = ({
    options,
    name,
    singleValue,
    values,
    value,
    includeAllOption,
    onChange,
    showPorojectsCount,
    ...props
}) => {
    const { setFieldValue } = useFormikContext();

    const SelectComponent = singleValue ? Select : SectorsMulti;

    let optionsArray = [];
    const getLabel = (opt)=>{
        return (showPorojectsCount && opt.projects_count !== null) ? `${opt.name} (${opt.projects_count})`: opt.name;
    }
    if (options.length) {
        optionsArray = options.map((option) => ({ value: option.id, label: getLabel(option) }));
    }

    //use this var to create proper label/value obj
    let valueObj = [];

    //pick out the initial values from the options array based on sector id's from form
    if (!singleValue) {
        if (Array.isArray(values[name])) {
            valueObj = values[name].map((id) => {
                return optionsArray.find((option) => {
                    return option.value === id;
                });
            });
        }
    } else {
        //match up the value from the form to the ID's we retrieve from the API
        //need lodash _get to help when retrieving formik field array nested values.
        valueObj = optionsArray.find((option) => option.value === _get(values, name, {}));
    }

    return (
        <SelectComponent
            options={optionsArray}
            name={name}
            setFieldValue={setFieldValue}
            label={props.label || 'Sectors'}
            required={props.required}
            value={valueObj}
            noLabel={props.noLabel}
            className={props.className}
            isClearable={true}
            customOnChange={onChange}
            {...props}
        />
    );
};

const mapStateToProps = (state) => {
    const sectorData = state.sectors.sectorData;

    return {
        options: sectorData
    };
};

export const Sectors = connect(mapStateToProps, {})(SectorsComponent);
