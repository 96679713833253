import React from "react";
import { Field } from "formik";
import isUrl from "is-url";

import { RepeaterButton } from "../RepeaterButton";
import {
  ResourceProjectsStyled,
  ProjectItemsStyled,
  ProjectItemStyled,
} from "./../FormInputs/ResourceProjects/styled";
import { DocumentItem } from "../DocumentItem";
import { DocumentControls } from "../DocumentControls";

export const Resume = ({
  resume,
  setFieldValue,
  className,
  handleAddClick,
  handleEditClick,
  handleDeleteClick,
  ...props
}) => {
  // use storage_path for path key as this is what DocumentItem expects
  // only add it if the file has been uploaded to s3 and has a valid URL
  if (resume && isUrl(resume.resume_file?.path)) {
    resume.resume_file["storage_path"] = resume?.resume_file?.path;
  }

  return (
    <ResourceProjectsStyled className={className}>
      <Field name="resume">
        {({ field, form, meta }) => (
          <div>
            <RepeaterButton
              text="Resume"
              as={resume && resume.resume_file ? "p" : "button"}
              handleAddClick={handleAddClick}
              showIcon={resume && resume.resume_file ? false : true}
              disabled={resume && resume.resume_file ? true : false}
            />

            <ProjectItemsStyled>
              {resume && resume.resume_file && (
                <ProjectItemStyled>
                  <DocumentItem document={resume.resume_file} editable>
                    <DocumentControls
                      onEdit={handleEditClick}
                      onDelete={() => handleDeleteClick()}
                    />
                  </DocumentItem>
                </ProjectItemStyled>
              )}
            </ProjectItemsStyled>
          </div>
        )}
      </Field>
    </ResourceProjectsStyled>
  );
};
