import React, { useState } from "react";
import AnimateHeight from "react-animate-height";
import styled from "styled-components";

import { ProjectCardStyled, ExpandableContentStyled } from "./styled";
import { Button } from "../Button";
import { ImageSlider } from "../ImageSlider";
import { formatNumber } from "utils/helpers";
import ReactQuill from 'react-quill';

export const Image = styled.img`
  object-fit: ${(props) => (props.isPlaceholder ? "contain" : "cover")};
  background-color: ${(props) =>
    props.isPlaceholder ? "#e9ecf1" : "transparent"};
  width: 100%;
  height: 300px;
`;

export const ProjectCard = (props) => {
  const [height, setHeight] = useState(0);

  const { data } = props;
  const profileTypeId = data.company.profile_type.id;

  const clickExpand = () => {
    setHeight(height === 0 ? "auto" : 0);
  };

  const procurementMethods =
    Array.isArray(data.procurement_methods) &&
    data.procurement_methods.length > 0
      ? data.procurement_methods.map((method) => method.name)
      : null;

  return (
    <ProjectCardStyled open={height !== 0}>
      <div className="inner">
        <ImageSlider
          images={data.images}
          heading={data.name}
          subheading={data.location.value}
        />
        <div className="content">
          <ReactQuill readOnly value={data.summary} theme="bubble" className="section summary" style={{marginBottom: "-40px",paddingBottom:0}} />
          <ul className="section figures">
            {profileTypeId === 3 ? (
              <>
                <li>
                  Trade Packages Value (AUD):{" "}
                  <strong>
                    {data.trade_value === null
                      ? "Confidential/NA"
                      : `$${data.trade_value}`}
                  </strong>
                </li>
              </>
            ) : (
              <>
                <li>
                  Project Construction Value (AUD):{" "}
                  <strong>
                    {data.value === 0
                      ? "Confidential/NA"
                      : `$${data.value_humanized}`}
                  </strong>
                </li>
              </>
            )}

            {data.year_completed && (
              <li>
                Year Complete: <strong>{data.year_completed}</strong>
              </li>
            )}
            

            <li>
              {profileTypeId === 3 ? "Client Name" : "Principal/Owner of Project"}:{" "}
              <strong>{data.client_name}</strong>
            </li>

            {profileTypeId === 3 && (
              <>
                <li>
                  Trade Package/s:{" "}
                  <strong>
                    {data.trades?.length > 0 ? (
                      <>
                        {data.trades?.map((trade, index) => {
                          return (
                            <>
                              <span>
                                {" "}
                                {trade.name}
                                {index === data.trades?.length - 1
                                  ? ""
                                  : ","}{" "}
                              </span>
                            </>
                          );
                        })}
                      </>
                    ) : (
                      "Confidential/NA"
                    )}
                  </strong>
                </li>
              </>
            )}
            {procurementMethods && profileTypeId !== 3 && (
              <li>
                Procurement Model:{" "}
                <strong>{procurementMethods.join(", ")}</strong>
              </li>
            )}

            {data.funding_type !== "" && profileTypeId !== 3 ? (
              <li>
                Type of Funding: <strong>{data.funding_type}</strong>
              </li>
            ) : (
              <></>
            )}

            {data.site_area !== null &&
            data.site_area !== "" &&
            profileTypeId !== 3 ? (
              <li>
                Site Area (Ha): <strong>{data.site_area}</strong>
              </li>
            ) : (
              <></>
            )}

            {data.gross_floor_area !== null &&
            data.gross_floor_area !== "" &&
            profileTypeId !== 3 ? (
              <li>
                Gross Floor Area (m2):{" "}
                <strong>{formatNumber(data.gross_floor_area)}</strong>
              </li>
            ) : (
              <></>
            )}
          </ul>
          {profileTypeId !== 3 && (
            <ExpandableContentStyled
              as={AnimateHeight}
              duration={500}
              height={height}
            >
              <div>
                {data.innovation_value_summary && profileTypeId !== 3 && (
                  <div className="section section--inverse description" style={{marginBottom: "-40px"}}>
                    <h5>Innovation / Value Summary:</h5>
                    <ReactQuill readOnly value={data.innovation_value_summary} theme="bubble" />
                  </div>
                )}

                {data.awards && (
                  <div className="section section--inverse awards">
                    <h5>Awards:</h5>
                    {data.awards}
                  </div>
                )}

                {data.testimonials && (
                  <Button
                    as="a"
                    href={data.testimonials}
                    target="_blank"
                    rel="noopener noreferrer"
                    btntype="secondary"
                    size="sm"
                  >
                    View Client Testimonial
                  </Button>
                )}
              </div>
            </ExpandableContentStyled>
          )}
        </div>
        {profileTypeId !== 3 && (
          <div className="expand">
            <button onClick={() => clickExpand()}>
              {height === 0 ? "Expand for More" : "Hide"}
            </button>
          </div>
        )}
      </div>
    </ProjectCardStyled>
  );
};
