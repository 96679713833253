import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import { toast } from "react-toastify";
import {
  LeftSection,
  RightSection,
  LoginSection,
  Logo,
  Tagline,
  LoginWrapper,
} from "../Login/styled";
import { ResetPasswordWrapper } from "./styled";
import { Heading } from "../Heading";
import { TextInput } from "../FormInputs/TextInput";
import { Submit } from "../FormInputs/Submit";
import { resetPassword } from "../../actions/authActions";
import { guestResetPassword } from "../../actions/guestActions";
import { OnSubmitValidationError } from "../../utils/OnSubmitValidationError";
import { connect } from "react-redux";
import { getPasswordStrength, isPAPLUsers} from "../../utils/helpers";
import logo from "./../../icons/logo-text.svg";
import tagline from "./../../icons/tagline.svg";
import rightImg from "./../../icons/login-right-img.svg";
import { Link, navigate } from "@reach/router";
import { PasswordValidator } from "../FormInputs/PasswordValidator";

export const ResetPasswordFormComponent = ({ ...props }) => {
  const { search } = window.location;
  const params = new URLSearchParams(search);
  const email = params.get("email").replace(" ", "+");
  const token = params.get("token");

  let guest = false;
  if (window.location.href.includes("guest")) {
    guest = true;
  }
  const isPAPLUser = isPAPLUsers({email: email});
  const [passwordRules, setPasswordRules] = useState({
    letter: false,
    capital: false,
    number: false,
    count: false,
  });

  useEffect(() => {
    if (token == null || email == null) navigate("/login", { replace: true });
  }, []); // eslint-disable-line

  const handleSubmit = async (values, setErrors, setSubmitting) => {
    setSubmitting(true);

    try {
      if (guest) {
        await props.guestResetPassword(values, setSubmitting);
      } else {
        await props.resetPassword(values, setSubmitting);
      }
    } catch (e) {
      const error = await e.response.json();
      toast.error(error.message);
      error.errors !== undefined && setErrors(error.errors);
    }
  };

  return (
    <LoginWrapper>
      <ResetPasswordWrapper>
        <LeftSection>
          <a href="/">
            <Logo src={logo} alt="Procuracon Logo" />
          </a>
          <Tagline src={tagline} alt="Build Possible" />

          <LoginSection>
            <Heading marginBottom="50px">
              <span className="welcome">RESET MY PASSWORD</span>
            </Heading>
            <p className="instructions">
              Enter your new password below and submit. You will then be
              redirected to the login page
            </p>

            <Formik
              enableReinitialize
              initialValues={{
                token: token || "",
                email: email || "",
                password: "",
                password_confirmation: "",
              }}
              validationSchema={Yup.object({
                email: Yup.string()
                  .email()
                  .required("* This Field is required"),
                password: Yup.string()
                  .required("* This Field is required")
                  .test(
                    "strong password",
                    "*The supplied password does not meet the requirements",
                    (value) => {
                      let validRules = passwordRules;
                      if (value) {
                        validRules = getPasswordStrength(value, passwordRules, isPAPLUser);
                        setPasswordRules(validRules);
                        return Object.values(validRules).every(
                          (val) => val === true
                        );
                      } else {
                        validRules.letter = false;
                        validRules.number = false;
                        validRules.capital = false;
                        validRules.count = false;
                        setPasswordRules(validRules);
                        return false;
                      }
                    }
                  ),
                password_confirmation: Yup.string()
                  .oneOf([Yup.ref("password"), null], "Passwords must match")
                  .required(),
              })}
              onSubmit={(values, { setErrors, setSubmitting }) => {
                handleSubmit(values, setErrors, setSubmitting);
              }}
            >
              {(props) => {
                const { setFieldValue, isSubmitting } = props;

                const onSubmitValidationError = () => {
                  toast.error("Please review field errors in form.");
                };

                return (
                  <Form>
                    <div className="row">
                      <TextInput
                        label="enter your Email"
                        placeholder="Email"
                        name="email"
                        type="email"
                        className="col xl-12 text-mr"
                        setFieldValue={setFieldValue}
                        disabled
                      />
                      <TextInput
                        data-tip
                        data-for="pass_validation"
                        label="New Password"
                        placeholder="New Password"
                        name="password"
                        type="password"
                        className="col xl-12 text-mr"
                        setFieldValue={setFieldValue}
                        icon
                      />
                      <PasswordValidator
                        dataId={"pass_validation"}
                        passwordRules={passwordRules}
                        isPAPLUser={isPAPLUser}
                      />
                      <TextInput
                        label="Confirm password"
                        placeholder="Confirm Password"
                        name="password_confirmation"
                        type="password"
                        className="col xl-12 text-mr"
                        setFieldValue={setFieldValue}
                      />

                      <div className="col xl-12">
                        <Submit
                          type="submit"
                          widthExpand
                          isSubmitting={isSubmitting}
                          text="Submit"
                          submittingText="Submitting in..."
                        />
                      </div>
                    </div>

                    <OnSubmitValidationError
                      callback={onSubmitValidationError}
                    />
                  </Form>
                );
              }}
            </Formik>
          </LoginSection>
          <div className="float-bottom">
            <p>
              <Link to="/terms-and-conditions">Terms & Conditions</Link>
            </p>
            <p>
              <Link to="/privacy-policy">Privacy Policy</Link>
            </p>
            <p>
              <Link to="#">
                © {new Date().getFullYear()} Procuracon. All right reserved.
              </Link>
            </p>
          </div>
        </LeftSection>
        <RightSection
          style={{ backgroundImage: `url(${rightImg})` }}
        ></RightSection>
      </ResetPasswordWrapper>
    </LoginWrapper>
  );
};

export const PasswordResetForm = connect(null, {
  resetPassword,
  guestResetPassword,
})(ResetPasswordFormComponent);
