import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Select } from '../Select';
import { getRegionOptions } from '../../../actions/searchActions';

const RegionConnected = ({ name, label, regions, className, value, getRegionOptions, ...props }) => {
    useEffect(() => {
        getRegionOptions();
    }, [getRegionOptions]);

    let optionsArray = [];

    if (regions !== undefined) {
        const states = regions;

        optionsArray = states.map((item) => ({ value: item, label: item }));
    }

    // Data from API comes in as a single number, not the {id, label} format <Select expects
    const selectValue = typeof value === 'string' ? {value: value,label:value}:value;
    return (
        <Select
            required='true'
            options={optionsArray}
            name={name}
            label={label || 'Region'}
            value={selectValue}
            className={className}
            autoComplete='none'
            autoFill='off'
        />
    );
};

const mapStateToProps = (state) => {
    return {
        regions: state.search.regionOptions
    };
};

RegionConnected.defaultProps = {
    className: 'col xl-12'
};

export const Region = connect(mapStateToProps, { getRegionOptions })(RegionConnected);
