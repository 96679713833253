import styled from "styled-components";

export const StyledHoverLabel = styled.div`
  position: absolute;
  background: ${(props) => (props.bg ? props.bg : "#9b9b9b")};
  bottom: ${(props) => (props.gap ? `-${props.gap}px` : "-40px")};
  padding: 2px 12px;
  color: white;
  border-radius: 4px;
  z-index: 999;
  opacity: ${(props) => (props.hovered ? "1" : "0")};
  transition: all 0.5s ease-in-out;
`;
