import React from "react";

import { SidebarStyled } from "./styled";
import { CompanySidebar } from "../CompanySidebar";
import { GuestSidebar } from "../GuestSidebar";

export const Sidebar = ({ guestUser }) => {
  return (
    <SidebarStyled>
      {guestUser.login ? (
        <GuestSidebar guestUser={guestUser} />
      ) : (
        <CompanySidebar />
      )}
    </SidebarStyled>
  );
};
