import styled from 'styled-components';


export const LogsStyled = styled.div`

`

export const TableSection = styled.section`
    min-height:1041px;
    .log-type{
        text-transform: capitalize;
    }
`
export const LogTable = styled.table`
    width:100%;
    tr{
        text-align:left;
    }
`

export const Paginator = styled.div`
    margin-top:60px;
    .pagination{
        display:flex;
        list-style:none;
        justify-content:space-between;
        max-width:fit-content;
        margin:auto;
        .page-item{
            padding:10px;
            .page-link{
                color:grey;
                font-size:large;
                font-weight:bold;
                &:hover{
                    color:blue;
                }
            }
        }
        .active{
            .page-link{
                color:blue;
            }
        }
        .disabled{
            .page-link{
                color:darkgrey;
                cursor:not-allowed;
                &:hover{
                    color:darkgrey;
                }
            }
        }
    }
`