import React from 'react';
import { CompanyLocationsWrap } from "./styled";
import { AddressCard } from '../AddressCard';



export const CompanyLocations = ({locations})=>{

    const renderSecondaryAddresses = ()=>{
        return locations.slice(1).map(location=><AddressCard location={location} />)
    }
    return (
        <CompanyLocationsWrap>
            <h3>Main Office</h3>
            <AddressCard primary location={locations[0]}/>

            {locations.length > 1 && 
            
                <div className="row secondary-addresses">
                    <div className="col md-12">
                        <h3>Secondary Offices</h3>
                        {renderSecondaryAddresses()}
                    </div>
                </div>
            }
        </CompanyLocationsWrap>
    )
}