import {
  LeftSection,
  LoginSection,
  LoginWrapper,
  Logo,
  RightSection,
  Tagline,
} from "components/Login/styled";
import React, { useEffect } from "react";
import logo from "./../../icons/logo-text.svg";
import tagline from "./../../icons/tagline.svg";
import rightImg from "./../../icons/login-right-img.svg";

import { authTwoFactorLogin, continueTwoFactorSetup, enableTwoFactor, enablingTwoFactor, submitTwoFactorCode } from "actions/authActions";
import { connect } from "react-redux";
import TwoFactorStart from "./TwoFactorStart";
import TwoFactorCode from "./TwoFactorCode";
import TwoFactorFinish from "./TwoFactorFinish";

const TwoFactorSetup = ({
  access_token,
  twoFactorEnabled,
  enableTwoFactor,
  continueTwoFactorSetup,
}) => {
  const [code, setCode] = React.useState("");
  const [data2fa, setData2fa] = React.useState(null);
  const [codeScreen, setCodeScreen] = React.useState(false);
  const [enabled2FA, setEnabled2FA] = React.useState(false);
  const [user2FA, setUser2FA] = React.useState(null);

  const handle2FA = () => {
    enablingTwoFactor().then((data) => {
      setData2fa(data);
    });
  };

  const verifyHandler = () => {
    submitTwoFactorCode(code).then((data) => {
      if(data) {
        setUser2FA(data);
        setEnabled2FA(true);
      }
    });
  };

  const continueHandler = async () => {
    if(enabled2FA){
      await continueTwoFactorSetup(user2FA);
      enableTwoFactor()
      
    }
  }

  useEffect(() => {
    if (access_token && twoFactorEnabled !== 'true') {
      handle2FA();
    }
    //eslint-disable-next-line
  }, []);

  return (
    <LoginWrapper>
      <LeftSection>
        <a href="/">
          <Logo src={logo} alt="Procuracon Logo" />
        </a>
        <Tagline src={tagline} alt="Build Possible" />

        <LoginSection>
          {enabled2FA ? (<TwoFactorFinish continueHandler={continueHandler} />) : 
          !codeScreen ? (
            <TwoFactorStart data2fa={data2fa} setCodeScreen={setCodeScreen} />
          ) : (
            <TwoFactorCode
              verifyHandler={verifyHandler}
              code={code}
              setCode={setCode}
              setCodeScreen={setCodeScreen}
            />
          )}
          
        </LoginSection>
      </LeftSection>
      <RightSection style={{ backgroundImage: `url(${rightImg})` }} />
    </LoginWrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    access_token: state.auth.access_token || localStorage.getItem('access_token'),
    user: state.auth.user,
    guestUser: state.auth.guestUser,
    company: state.auth.current_company,
    twoFactorEnabled: state.auth.two_factor_enabled || localStorage.getItem('two_factor_enabled'),
    twoFactorVerified: state.auth.two_factor_verified || localStorage.getItem('two_factor_verified'),
  };
};

export default connect(mapStateToProps, { authTwoFactorLogin, enableTwoFactor, continueTwoFactorSetup })(TwoFactorSetup);
