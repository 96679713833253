import styled from 'styled-components';

export const ProjectCardStyled = styled.div`
    .inner {
        border-radius: ${(props) => props.theme.radii.default};
        background-color: white;
        padding: 0 0 2.5rem;
    }

    .content {
        padding: 30px;
        font-size: 0.875rem;
    }

    .section {
        margin-bottom: 1.75rem;
        white-space: pre-wrap;
        text-align: justify;

        p {
            margin-top: 0;
        }

        &--inverse {
            color: ${(props) => props.theme.colors.navy};
            font-weight: 500;

            h5 {
                color: ${(props) => props.theme.colors.gray};
                font-weight: 400;
                margin: 0 0 0.275rem;
                font-size: 0.875rem;
            }
        }
    }

    .figures {
        padding-left: 0;
        list-style: none;

        strong {
            color: ${(props) => props.theme.colors.navy};
            text-transform: capitalize;
        }

        li {
            margin-bottom: 0.625rem;
        }
    }

    .expand {
        position: relative;

        &::before {
            content: '';
            position: absolute;
            top: 50%;
            left: 0;
            width: 100%;
            height: 1px;
            transform: translateY(-50%);
            background-color: ${(props) => props.theme.colors.grayBorder};
            opacity: 0.15;
        }

        button {
            background: ${(props) => (props.open ? props.theme.colors.royalBlue : props.theme.colors.snowDark)};
            color: ${(props) => (props.open ? 'white' : props.theme.colors.gray)};
            border: none;
            font-size: 0.6875rem;
            font-weight: 700;
            text-transform: uppercase;
            border-radius: ${(props) => props.theme.radii.xl};
            padding: 0.65rem 1rem;
            border: 1px solid rgba(99, 139, 200, 0.15);
            position: relative;
            z-index: 2;
            left: 50%;
            transform: translateX(-50%);
            cursor: pointer;
            transition: ${(props) => props.theme.transitions.default};

            &::hover {
                background: ${(props) => props.theme.colors.royalBlue};
                color: white;
            }

            &:focus {
                outline: none;
            }
        }
    }
`;

export const ExpandableContentStyled = styled.div`
    height: auto;
    transition: ${(props) => props.theme.transitions.default};
`;
