import styled from 'styled-components';

import { Modal } from '../Modal';

export const ConfirmDeleteStyled = styled(Modal)`
    padding:3.37rem;
    background-color:white;
    min-width:570px;
    .delete-btn{
        float:right;
        padding:8px 12px;
        color:white;
        background: #FE0202;
        border: 1px solid #FE0202;
        cursor:pointer;
        display: flex;
        padding: 8px 35px;
        align-items: center;
        justify-content: center;
       
    }
    .cancel-btn{
        float:right;
        padding:8px 12px;
        color:#263859;
        background: transparent;
        border: 1px solid #263859;
        cursor:pointer;
        display: flex;
        padding: 8px 35px;
        justify-content: center;
        align-items:center;
       
    }
`;

export const ConfirmHeading = styled.h3`
    
    margin: 0px 0px 32px 0px;
    color: ${(props) => props.color};
    font-weight: 500;
`;

export const ButtonRow = styled.div`
    border-top: 1px solid ${(props) => props.noborder ? 'none' : props.theme.colors.grayBorder};

    > div {
        
        justify-content: space-between;
        display: flex;
    }
`;
