import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';

import { ImageUploadStyled,ImagePreview, DeleteStyled } from './styled';
import { FormLabel } from '../TextInput';
import {Button} from '../../Button';
import { toast } from 'react-toastify';
import { ReactComponent as InfoIcon } from "./../../../icons/info.svg";
import ReactTooltip from "react-tooltip";



export const ImageUpload = ({ setFieldValue, name, image, guideLine, ...props }) => {
    const [files, setFiles] = useState([{ preview: image }]);
    const maxFileSize = 10485760
    const { getRootProps, getInputProps } = useDropzone({
        multiple: false,
        accept: 'image/*',
        onDrop: (acceptedFiles) => {
            if(acceptedFiles[0].size <= maxFileSize){
                setFieldValue(name, acceptedFiles[0]);

                //base64 method
                // const reader = new FileReader();
                // reader.onload = () => {
                //     var base64Image = reader.result;
                //     console.log(base64Image);
                //     setFieldValue(name, base64Image);
                // };
    
                setFiles(
                    acceptedFiles.map((file) =>
                        Object.assign(file, {
                            preview: URL.createObjectURL(file)
                        })
                    )
                );
            }else{
                toast.error(`Maximum file size is ${10}MB.`)
            }
            
        }
    });
    const deleteHandler = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setFieldValue(name, "");
        setFiles({preview:""});
    }

    return (
        <div className={props.className}>
            <FormLabel>
                {props.label} &nbsp;
                {guideLine && (
                <div className="guideline">
                <InfoIcon data-tip data-for={props.name} />
                <ReactTooltip
                    className="guide"
                    id={props.name}
                    place="right"
                    arrowColor="transparent"
                >
                    {guideLine}
                </ReactTooltip>
                </div>
            )} 
            </FormLabel>
            <ImageUploadStyled {...getRootProps({ className: 'dropzone' })} {...props}>
                <input {...getInputProps()} />
                {files[0]?.preview ? (
                <ImagePreview >
                    <img src={files[0].preview} alt={files[0].name} />
                    {props?.module ==="key-personel" && <DeleteStyled onClick={deleteHandler} />}
                </ImagePreview>
                ) : (<>
                    <p style={{ padding: '10px',margin:0, fontSize:'14px' }}>Drop file here to upload...</p>
                    <Button
                        btntype={'secondary'}
                        size={'xs'}
                        width={'140px'}
                        textfont={'14px'}
                        boxshadow={false}
                        type="button"
                        >
                        <span>File Upload</span>
                    </Button>
                    </>
                )}
            </ImageUploadStyled>
        </div>
    );
};
