import { createGlobalStyle } from 'styled-components';
import { theme } from '../theme';

export default createGlobalStyle`
    :root {
        font-family: ${theme.fonts.sans_serif};
        line-height: ${theme.lineHeights.base};
        font-weight: 400;
        font-size: 0.938rem;

        ${theme.mediaQueries.xxl} {
            font-size: 0.938rem;
        }
    }

    * {
        &,
        &:before,
        &:after {
            box-sizing: border-box;
        }
    }

    html {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-rendering: optimizeLegibility;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    }

    body {
        color: ${theme.colors.gray};
        background-color: ${theme.colors.snow};

        &.ReactModal__Body--open {
            overflow: hidden;
            position: fixed;
            width: 100%;
            height: 100%;
        }
    }

    ::selection {
        background: ${theme.colors.royalBlueLight};
        color: white;
    }

    ::-moz-selection {
        background: ${theme.colors.royalBlueLight};
        color: white;
    }

    .page-wrap {
        width: 100%;
        background-color: ${theme.colors.snow};
    }

    img {
        vertical-align: middle;
        max-width: 100%;
    }

    a {
        color: ${theme.colors.royalBlue};
        text-decoration: none;
        transition: ${theme.transitions.default};

        @include on-event() {
            color: ${theme.colors.royalBlueLight};
            text-decoration: none;
        }
    }

    strong {
        color: ${theme.colors.navy};
    }
`;
