import React, { useEffect } from "react";
import { connect } from "react-redux";
import { navigate } from "@reach/router";
import { AccountUsersStyled, UsersSection } from "./styled";
import { Button } from "components/Button";
import { getCompanyUsers } from "actions/AccountManagementActions";
import { TabHeader } from "components/TabHeader";
import { TabContent } from "components/Tabs/styled";
import { AccountUsersTable } from "./AccountUsersTable";

export const AccountUsersComponent = ({
  company_users,
  company,
  getCompanyUsers,
}) => {
  useEffect(() => {
    getCompanyUsers(company.id);
  }, [getCompanyUsers, company]);

  return (
    <AccountUsersStyled>
      <TabHeader heading="Account Users" />
      <TabContent>
        <UsersSection>
          <div className="users-note">
            <Button
              className={"create-btn"}
              onClick={() => navigate("account-users/create-user")}
            >
              Create User
            </Button>
            <p>
              *Important Note: There must always be at least one 'Admin User'
            </p>
          </div>
          <AccountUsersTable datas={company_users} />
        </UsersSection>
      </TabContent>
    </AccountUsersStyled>
  );
};

const mapStateToProps = (state) => {
  const company = state.search.activeCompany;
  const company_users = state.accountManagement.companyUsers;
  return {
    company: company,
    company_users: company_users,
  };
};

export const AccountUsers = connect(mapStateToProps, { getCompanyUsers })(
  AccountUsersComponent
);
