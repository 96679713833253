import React, { useEffect } from "react";
import { connect } from "react-redux";
import Skeleton from "react-loading-skeleton";
import { EditPlanStyled } from "./styled";
import { UpdatePlanDetail } from "components/UpdatePlanDetail/UpdatePlanDetail";
import { getAccountTypes } from "actions/accountTypeActions";
import { getCompany } from "actions/companyActions";
import { TabHeader } from "../TabHeader";
import { getCalculation } from "actions/searchActions";
import { updateSubscription } from "actions/companyActions";


export const EditPlanComponent = ({
  accountType,
  getAccountTypes,
  profileType,
  nextBillingDate,
  getCalculation,
  calculation,
  updateSubscription,
  company
}) => {

  useEffect(()=>{
    getCalculation()
  },[getCalculation])

  return accountType ? (
    <EditPlanStyled>
      <TabHeader heading="Edit Subscription" />
        <UpdatePlanDetail
            accountTypes={accountType}
            getAccountTypes={getAccountTypes}
            profileType={profileType}
            nextBillingDate={nextBillingDate}
            calculation={calculation}
            getCalculation={getCalculation}
            updateSubscription={updateSubscription}
            company= {company}
        />
    </EditPlanStyled>
  ) : (
    <Skeleton count={5} duration={0.5} />
  );
};

const mapStateToProps = (state) => {
    const account_type = state.accountTypes.accountTypeData;
    const profile_type = state.search.activeCompany.profile_type;
    const next_billing_date = state.search.activeCompany.next_billing_date;
    const calculation = state.search.calculation;
    const company = state.search.activeCompany;
    return {
        accountType: account_type,
        profileType: profile_type,
        nextBillingDate: next_billing_date,
        calculation: calculation,
        company: company,
    };
};

export const EditPlan = connect(mapStateToProps, {getAccountTypes, getCompany, getCalculation, updateSubscription})(
    EditPlanComponent
);
