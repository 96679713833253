import React from 'react';
import { FormPageStyled } from './styled';
import { Heading } from '../Heading';

export const FormPage = ({ heading, marginBottom, ...props }) => {
    return (
        <FormPageStyled>
            {heading && (
                <Heading mb='md' fontSize='3.4rem' color='white' marginBottom={marginBottom}>
                    {heading}
                </Heading>
            )}
            {props.children}
        </FormPageStyled>
    );
};
