import styled, { css } from "styled-components";
import { Modal } from "components/Modal";
import { theme } from "theme";

export const CancelLabel = styled.h4`
  display: block;
  color: ${theme.colors.red};
  margin-top: 0.25rem;
  font-weight: 400;
  cursor: pointer;
  font-size: 12px;
`;

export const RestartLabel = styled.h4`
  display: block;
  color: ${theme.colors.royalBlueLight};
  margin-top: 0.25rem;
  font-weight: 400;
  cursor: pointer;
  font-size: 12px;
`;

export const AccountManagementStyled = styled.div`
  h1 {
    font-size: 2.9rem;
    color: rgb(0, 69, 245);
  }
  h2 {
    font-size: 2.3rem;
    font-family: Abolition-Regular;
  }
  h3 {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    color: #253858;
  }
  .transaction-head {
    margin-top: 3rem;
  }
`;

export const PlanSection = styled.div`
  display: flex;
  justify-content: space-between;
  .plan-link {
    display: block;
    font-size: 12px;
  }
  .plan-container {
    width: 100%;
    .plan-row {
      display: flex;
      flex-direction: row;
      h6 {
        min-width: 30%;
        margin-top: 0px;
        color: #263859;
        font-size: 15px;
        font-weight: 500;
      }
      p {
        color: #8492a6;
        margin-top: 0px;
        font-size: 15px;
        font-weight: 400;
        margin-bottom: 24px;
        span {
          font-weight: 500;
        }
      }
    }
  }
`;

const sectionListingStyles = css`
  h2 {
    color: ${(props) => props.theme.colors.black};
    font-weight: 500;
  }
  .section-header {
    padding: 1.25rem;
    border-bottom: solid #dfdfdf 1px;
    .first-item {
      padding-left: 1rem;
    }
    span {
      font-size: ${(props) => props.theme.fontSizes.sm};
      color: ${(props) => props.theme.colors.black};
      font-weight: 500;
      text-transform: uppercase;
      letter-spacing: 1.125px;
    }
  }
  .create-btn {
    margin-bottom: 14px;
  }
  .action-cell {
    display: flex;
    justify-content: space-between;
  }
`;

export const UsersSection = styled.div`
  ${sectionListingStyles};
  .users-note {
    display: flex;
    column-gap: 15%;
    p {
      color: red;
      font-weight: bold;
    }
  }
`;

export const InvoicesSection = styled.div`
  ${sectionListingStyles}
`;

export const InfoRow = styled.div`
  margin-top: 8px;
  margin-bottom: 24px;
  background-color: ${(props) => props.theme.colors.white};
  box-shadow: 0px 4px 16px rgba(208, 210, 212, 0.4);
  border-radius: 5px;
  padding-top: 16px;
  padding-bottom: 16px;
  margin-left: 0px;
  .data-cell {
    border-right: 1px solid rgba(132, 146, 166, 0.4);
    padding: 6px 4px 6px 24px;
    display: flex;
    color: ${(props) => props.theme.colors.navy};
    align-items: center;
    font-size: 15px;
    overflow-wrap: anywhere;
    &.first {
      font-size: 18px;
      font-weight: 500;
    }
    &.no-border {
      border: none;
    }
    &.last {
      border: none;
      padding-right: 24px;
    }
    &.action-cell {
      display: flex;
      justify-content: space-around;
    }
    button {
      span {
        display: none;
        margin-top: 2px;
        letter-spacing: 1.13px;
        ${(props) => props.theme.mediaQueries.xl} {
          display: inherit;
        }
      }
    }
  }
`;
export const ConfirmCancelStyled = styled(Modal)`
  padding: 1.5rem 2rem;
  background-color: white;
  min-width: 570px;
  .confirm-btn {
    float: right;
    padding: 8px 12px;
    color: white;
    background: #fe0202;
    border: 1px solid #fe0202;
    cursor: pointer;
    display: flex;
    padding: 8px 35px;
    align-items: center;
    justify-content: center;
  }
  .cancel-btn {
    float: right;
    padding: 8px 12px;
    color: #0045f5;
    background: transparent;
    border: 1px solid #263859;
    cursor: pointer;
    display: flex;
    padding: 8px 35px;
    justify-content: center;
    align-items: center;
  }
  .confirm-btn-changesubs{
    float: right;
    padding: 8px 12px;
    color: white;
    background: #0045F5;
    border: 1px solid #0045F5;
    cursor: pointer;
    display: flex;
    padding: 8px 35px;
    align-items: center;
    justify-content: center;
    // width: 218px;
    // height: 48px;
    font-size: 14px;
  }
`;

export const ButtonRow = styled.div`
  display: flex;
  justify-content: end;
  gap: 1.5rem;
  margin: 1.5rem rem 0.5rem 0.5rem 0.5rem;
`;

export const DialogConfirm = styled.h2`
  font-size: 1.15rem;
  width: 95%;
  margin: 1.5rem 0;
  font-weight: 500;
`;
