import styled from 'styled-components';

export const CompanyHeaderMediaStyled = styled.picture`
    display: flex;
    position: relative;
`;

export const CompanyBannerImage = styled.div`
    width: 100%;

    img {
        width: 100%;
        height: 200px;
        object-fit: cover;
    }
`;

export const CompanyHeaderLogo = styled.img`
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateY(50%) translateX(-50%);
    box-shadow: 0px 4px 44px #edeef0;
    width: 140px;
    height: 140px;
    border-radius: 50%;
    object-fit: contain;
    background-color: white;
    padding: 10px;
    box-shadow: 3px 3px 13px 1px #e3e2e2;
`;
