import React, { useState } from "react";
import styled from "styled-components";

import { ProjectLinkStyled } from "./styled";
import { Button } from "../Button";
import { ProjectCard } from "../ProjectCard";
import { Modal } from "../Modal";
import { ButtonLink } from "../ButtonLink";

export const Image = styled.img`
  align-self: center;
  width: 55px;
  height: 55px;
  border-radius: ${(props) => props.theme.radii.md};
  object-fit: ${(props) => (props.isPlaceholder ? "contain" : "cover")};
  background-color: ${(props) =>
    props.isPlaceholder ? "#e9ecf1" : "transparent"};
`;

export const ResourceLink = (props) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  let { data, isObj } = props;

  const closeModal = () => {
    setModalIsOpen(false);
  };

  data = isObj ? data[1] : data;
  return (
    <ProjectLinkStyled className={`project-link ${props.className}`}>
      <div className="project-link__cell project-link__cell--img">
        <Image
          src={
            data?.avatar_location ||
            process.env.PUBLIC_URL + "/img/placeholder-sm.jpg"
          }
          className="project-link__img"
          isPlaceholder={!data?.avatar_location}
          alt=""
        />
      </div>

      <div className="project-link__cell project-link__cell--title">
        <div className="project-link__cell-inner">
          <h4 className="project-link__title">{`${data?.first_name || "-"} ${
            data?.last_name || "-"
          }`}</h4>
        </div>
      </div>

      <div className="project-link__cell project-link__cell--spec">
        <div className="project-link__cell-inner">
          <p>{data.position}</p>
        </div>
      </div>

      <div className="project-link__cell project-link__cell--spec">
        <div className="project-link__cell-inner">
          <p>{data.experience} years</p>
        </div>
      </div>

      <div className="project-link__cell project-link__cell--spec">
        <div className="project-link__cell-inner">
          {data.sectors && data.sectors.length ? (
            <>
              {data.sectors.map((sector) => (
                <p key={sector.id}>{sector.name}</p>
              ))}
            </>
          ) : (
            ""
          )}
        </div>
      </div>

      <div className="project-link__cell project-link__btn-cell">
        {props.edit ? (
          <ButtonLink
            id={data?.first_name}
            to={`edit/${data.id}`}
            btnType="secondary"
            size="sm"
            className="project-link__btn"
          >
            Edit
          </ButtonLink>
        ) : (
          data.resume &&
          data.resume.length > 0 && (
            <Button
              as="a"
              href={data.resume[0]}
              target="_blank"
              btntype="secondary"
              size="sm"
              className="project-link__btn"
            >
              Resume
            </Button>
          )
        )}
      </div>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        shouldCloseOnOverlayClick
      >
        <ProjectCard />
      </Modal>
    </ProjectLinkStyled>
  );
};
