import React, { useEffect, useState } from "react";
import { Router } from "@reach/router";
import Skeleton from "react-loading-skeleton";
import { useSelector, useDispatch } from "react-redux";
import _isEmpty from "lodash.isempty";

import { SearchTemplate } from "../../layout/SearchTemplate";
import { SearchResults } from "./SearchResults";
import { FullDraw } from "../../components/FullDraw";
// import { SearchCompare } from './SearchCompare';
import { SearchShortlist } from "./SearchShortlist";
import { connect } from "react-redux";
import { useLocation } from "@reach/router";
import {
  getGroup,
  searchCompanies,
  removeFromList,
  addProjectToCompareCard,
  removeProjectFromCompareCard,
  getCompareList,
  getShortlist,
  setAddProjectIndex,
} from "../../actions/searchActions";
import { getPastProjects } from "../../actions/companyActions";
import { toast } from "react-toastify";

const SearchContainerConnected = ({
  results,
  compareList,
  compareListCompanies,
  shortlist,
  groupId,
  getGroup,
  searchCriteria,
  searchCompanies,
  isSearching,
  disciplines,
  group,
  sectors,
  types,
  hasSearched,
  lockedSearchItems,
  lockedCompareItems,
  removeFromList,
  getPastProjects,
  pastProjects,
  searchMeta,
  isLoadingNext,
  getCompareList,
  getShortlist,
  trades,
  specialities,
  ...props
}) => {
  const location = useLocation();
  let pagesSplit = location.pathname.split("/");
  const lastPage = pagesSplit[pagesSplit.length - 1];

  const [isOpen, setIsOpen] = useState(false);
  const [companyProjectData, setCompanyProjectData] = useState({});
  const [showCompanyProjects, setCompanyProjectsView] = useState(false);

  const [companyPersonnel, setCompanyPersonnel] = useState({});
  const [showCompanyPersonnel, setCompanyPersonnelView] = useState(false);

  const addProjectIndexes = useSelector(
    (state) => state.search.addProjectIndex
  );
  const dispatch = useDispatch();

  const handleAddProjectClick = async (project, index) => {
    if (addProjectIndexes.length === 0) {
      toast.error("No more room for projects");
      return false;
    }
    const res = await dispatch(
      addProjectToCompareCard(
        groupId,
        project.company_id
          ? project.company_id
          : project.company && project.company.id,
        project,
        addProjectIndexes[0]
      )
    );

    if (res) {
      addProjectIndexes.shift();
      dispatch(setAddProjectIndex(addProjectIndexes));
      toast.success("Project successfully added to compare view");
    } else toast.error("Unable to add project");
  };

  // TODO: Not being used at the moment.
  const handleRemoveProjectClick = async (project, index) => {
    const res = await dispatch(
      removeProjectFromCompareCard(
        groupId,
        project.company_id
          ? project.company_id
          : project.company && project.company.id,
        project,
        index
      )
    );

    if (res) {
      toast.success("Project successfully removed from compare view");
    } else toast.error("Unable to remove project");
  };

  useEffect(() => {
    if (groupId && !group.search_criteria) {
      getGroup(groupId);
      getCompareList(parseInt(groupId));
      getShortlist(parseInt(groupId));
    }

    if (!_isEmpty(searchCriteria) && !hasSearched) {
      let search = searchCriteria;
      let newSearchCriteria = { ...search, group_id: parseInt(groupId) };

      (async () => {
        await searchCompanies(newSearchCriteria, true);
      })();
    }

    // eslint-disable-next-line
  }, [getGroup, group, groupId]);

  const compareSlideoutClick = (items, type, companyId) => {
    if (type === "projects") {
      //fetch company projects
      getPastProjects(companyId);

      //grab all the project id's we've got in our compare card for the company, so we can test if the add button should be disabled
      const compareProjects =
        compareList && compareList.length
          ? compareList.flatMap((company) =>
              companyId === company.id ? company.compare_projects : []
            )
          : [];

      setCompanyProjectData({ companyId, compareProjects });
      setCompanyProjectsView(true);
      setCompanyPersonnelView(false);
      setIsOpen(true);
    } else if (type === "personnel") {
      setCompanyPersonnel({ companyId, data: items });
      setCompanyProjectsView(false);
      setCompanyPersonnelView(true);
      setIsOpen(true);
    }
  };

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  const hasReachedHorizontalEnd = (element) => {
    let scrollLeft = element.scrollLeft;
    let scrollWidth = element.scrollWidth;

    //trigger 150px before reaching end of scroll div
    if (
      Math.ceil(scrollWidth - scrollLeft - 150) <=
      Math.ceil(element.getBoundingClientRect().width)
    ) {
      return true;
    }

    return false;
  };

  const handleResultsScroll = (element) => {
    if (hasReachedHorizontalEnd(element)) {
      if (searchMeta.current_page < searchMeta.last_page && !isLoadingNext) {
        searchCompanies(searchCriteria, true, searchMeta.current_page + 1);
      }
    }
  };

  return (
    <>
      {!isSearching ? (
        <SearchTemplate
          resultsCount={searchMeta.total}
          compareCount={compareList ? compareList.length : 0}
          shortlistCount={shortlist ? shortlist.length : 0}
          shortlist={shortlist}
          noDataTable={["shortlist"].includes(lastPage)}
          searchCriteria={searchCriteria}
          disciplines={disciplines}
          sectors={sectors}
          types={types}
          groupId={groupId}
          trades={trades}
          specialities={specialities}
        >
          <Router
            primary={false}
            style={{
              width: "100%",
              backgroundColor: "#F4F5F7",
              flexGrow: 1,
              paddingTop: "50px",
            }}
          >
            <SearchResults
              path="results"
              results={results}
              lockedItems={lockedSearchItems}
              compareSlideoutClick={compareSlideoutClick}
              isResults
              cardType="search"
              handleScrollEnd={handleResultsScroll}
              isLoadingNext={isLoadingNext}
            />

            <SearchResults
              path="compare"
              results={compareList}
              compareList={compareList}
              compareSlideoutClick={compareSlideoutClick}
              lockedItems={lockedCompareItems}
              isCompare
              cardType="compare"
              isFullDrawOpen={isOpen}
            />

            <SearchShortlist
              path="shortlist"
              items={shortlist}
              removeFromList={removeFromList}
              groupId={groupId}
            />
          </Router>

          <FullDraw
            companyProjectData={companyProjectData}
            showCompanyProjects={showCompanyProjects}
            companyPersonnel={companyPersonnel}
            showCompanyPersonnel={showCompanyPersonnel}
            handleAddProjectClick={handleAddProjectClick}
            handleRemoveProjectClick={handleRemoveProjectClick}
            isOpen={isOpen}
            groupId={groupId}
            toggleOpen={toggleOpen}
            sectors={sectors}
            pastProjects={pastProjects}
          />
        </SearchTemplate>
      ) : (
        <div style={{ padding: "60px" }}>
          <Skeleton count={35} duration={1} />
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    isSearching: state.search.isSearching,
    results: state.search.searchResult,
    searchMeta: state.search.searchMeta,
    isLoadingNext: state.search.isLoadingNext,
    group: state.search.group,
    searchCriteria: state.search.searchCriteria,
    hasSearched: state.search.hasSearched,
    disciplines: state.search.disciplines,
    types: state.search.types,
    sectors: state.sectors,
    pastProjects: state.companyProfile.pastProjects,
    compareList: state.search.compareList,
    trades: state.companyProfile.trades,
    specialities: state.companyProfile.specialities,
  };
};

export const SearchContainer = connect(mapStateToProps, {
  getGroup,
  searchCompanies,
  removeFromList,
  getPastProjects,
  getCompareList,
  getShortlist,
})(SearchContainerConnected);
