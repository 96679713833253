import React from 'react';
import { CompanyAboutCardStyled } from './styled';
import { CompanyHeaderMedia } from '../CompanyHeaderMedia';
import ReactQuill from 'react-quill';
export const CompanyAboutCard = (props) => {
    return (
        <CompanyAboutCardStyled>
            <CompanyHeaderMedia bg={props.bg} logo={props.logo} />
            <ReactQuill readOnly theme='bubble' value={props.content} className='company-about__inner' />
        </CompanyAboutCardStyled>
    );
};
