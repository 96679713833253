import styled from 'styled-components';
import React from 'react';
import { ReactComponent as ArrowIcon } from './../../icons/arrow-open.svg';


const Button = styled.button`
        font-size: 15px;
        cursor:pointer;
        border: none;
        background-color: transparent;
        display: contents;
        svg {
            width:8px;
        }
        span {
            margin-left:10px;
            font-size :${(props)=>props.fontSize};
            font-weight : ${(props)=>props.fontWeight};
            margin-bottom: ${(props)=>props.marginBottom};
            color: #8492A6;
        }
`

export const BackButton = ({label,fontSize,fontWeight,marginBottom,handleOnClick})=>{

    const handleclick = () =>{
        if (handleOnClick){
           handleOnClick();

        }
      }
    return (
        <Button
            onClick={handleclick}
            fontSize={fontSize}
            fontWeight={fontWeight}
            marginBottom ={marginBottom}
        >
            <ArrowIcon/>
            <span>
                {label} 
            </span>
        
        </Button>
        
    )
}