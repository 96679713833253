import React, { useState, useEffect } from "react";
import { Formik, Form, useFormikContext } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import Skeleton from "react-loading-skeleton";
import { toast } from "react-toastify";
import debounce from "lodash.debounce";
import { TextInput } from "../FormInputs/TextInput";
import { Submit } from "../FormInputs/Submit";
import { LocalGovtAssociation } from "../FormInputs/LocalGovtAssociation";
import { LocationFormStyled } from "./styled";
import { Location } from "../FormInputs/Location";
import {
  submitCompanyLocationForm,
  deleteCompanyAddress,
} from "actions/companyActions";
import { OnSubmitValidationError } from "../../utils/OnSubmitValidationError";
import { ReactComponent as Plus } from "../../icons/plus-solid.svg";
import { FlashError } from "../FormInputs/FlashError";
import { TabHeader } from "../TabHeader";
import { TabContent } from "../Tabs/styled";

const AutoSave = ({ debounceMs, setShowToast }) => {
  const formik = useFormikContext();
  setShowToast(false);
  // eslint-disable-next-line
  const debouncedSubmit = React.useCallback(
    debounce(() => formik.submitForm(), debounceMs),
    [formik.submitForm]
  );

  useEffect(() => {
    debouncedSubmit();
  }, [debouncedSubmit, formik.values]);

  return true;
};
export const CompanyLocationFormComponent = ({
  company,
  viewOnly,
  ...props
}) => {
  const initialValues = props.initialValues;
  const [showToast, setShowToast] = useState(false);

  const noOfAddresses = company.locations.length;
  const [forms, setForms] = useState(noOfAddresses > 0 ? noOfAddresses - 1 : 0);
  const [sameState, setSameState] = useState(true);
  const [enableSubmit, setEnableSubmit] = useState(false);

  // Fill rest of the arrays with empty data. Temporary fix for following known issue with the library.
  // Ref: https://github.com/jquense/yup/issues/676
  const autoFillInitialValues = (initialValues) => {
    for (let i = 0; i < forms; i++) {
      for (let key in initialValues) {
        for (let j = initialValues[key].length + 1; j <= forms; j++) {
          initialValues[key].push(undefined);
        }
      }
    }

    return initialValues;
  };

  const handleAddressDelete = async (companyId, addressId) => {
    if (!addressId) {
      setSameState(true);
      setForms(forms - 1);
    } else {
      try {
        const res = await props.deleteCompanyAddress(companyId, addressId);
        if (res) {
          setForms(forms - 1);
          toast.success("Address has been deleted");
        } else toast.error("Unable to delete Address");
      } catch (error) {
        toast.error("Unable to delete Address: " + error);
      }
    }
  };
  const getErrors = (n, msg) => {
    const errObj = { 0: msg };
    for (let i = 1; i < n; i++) {
      errObj[i] = msg;
    }
    return errObj;
  };
  return company !== undefined ? (
    <LocationFormStyled>
      <Formik
        enableReinitialize
        initialValues={autoFillInitialValues(initialValues)}
        validationSchema={Yup.object({
          state: Yup.array().test(
            "same state",
            getErrors(forms + 1, "Address must be from same state"),
            (value) => {
              return (
                value.length === forms + 1 &&
                value.every((v) => v === value[0] && v != null && v !== "")
              );
            }
          ),
          address: Yup.array().of(
            Yup.object().shape({
              value: Yup.string().required(),
            })
          ),
          city: Yup.array().of(Yup.string().required()),
          postcode: Yup.array()
          .of(Yup.number()
              .typeError("Postcode must be a number") // Custom error for non-numeric input
              .required("Postcode is required")
          ),
          local_govt_association_id: Yup.array().of(Yup.number().required()),
        })}
        onSubmit={async (values, { setErrors }) => {
          if (
            values["address"].length === forms + 1 &&
            values["postcode"].length === forms + 1 &&
            values["city"].length === forms + 1 &&
            values["state"].length === forms + 1 &&
            values["local_govt_association_id"].length === forms + 1
          ) {
            try {
              if (!values.local_govt_association_id)
                delete values.local_govt_association_id;
              await props.submitCompanyLocationForm(values.company_id, values);
              showToast && toast.success("Data Updated Successfully");
              setSameState(true);
            } catch (e) {
              const error = await e.response.json();
              toast.error(error.message);
              error.errors !== undefined && setErrors(error.errors);
            }
          } else {
            setSameState(true);
          }
        }}
      >
        {(props) => {
          const { setFieldValue, isSubmitting, values, setErrors } = props;
          const onSubmitValidationError = (e) => {
            if ("state" in e.errors) {
              setSameState(false);
            } else {
              setSameState(true);
            }
          };
          return (
            <Form onChange={() => setEnableSubmit(true)}>
              {forms + 1 === company.locations.length &&
                !isSubmitting &&
                JSON.stringify(initialValues) !== JSON.stringify(values) && (
                  <AutoSave setShowToast={setShowToast} debounceMs={850} />
                )}
              <TabHeader heading="Office Locations" />
              <TabContent>
                <div className="row">
                  <div className="col xl-12">
                    <h3>{!viewOnly && `Your`} Primary Address</h3>
                  </div>
                  <div className="col xl-12">
                    <div className="row">
                      <Location
                        label="Address"
                        required={!viewOnly}
                        name="address[0]"
                        setFieldValue={setFieldValue}
                        initialValue={initialValues.address[0]}
                        className="col xl-12"
                        disabled={viewOnly}
                        errorBottom={"-3px"}
                      />
                      <div className="col xl-12">
                        <div className="row">
                          <TextInput
                            label="Post Code"
                            required={!viewOnly}
                            name="postcode[0]"
                            maxlength="4"
                            className="col xl-6"
                            disabled={viewOnly}
                          />
                          <TextInput
                            label="City / Suburb"
                            required={!viewOnly}
                            name="city[0]"
                            className="col xl-6"
                            disabled={viewOnly}
                          />
                          <TextInput
                            label="State"
                            required={!viewOnly}
                            name="state[0]"
                            className="col xl-6"
                            disabled={viewOnly}
                          />
                          <LocalGovtAssociation
                            name="local_govt_association_id[0]"
                            profileType={values.profile_type}
                            value={values.local_govt_association_id[0]}
                            className="col xl-6"
                            required={!viewOnly}
                            disabled={viewOnly}
                            state={values.state[0]}
                            errorBottom={"-3px"}
                            noOptionsMessage="Please select State first"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {[...Array(forms)].map((formNo, index) => {
                  return (
                    <div key={index} className="row">
                      <div className="col xl-12">
                      </div>
                      {index === 0 && (
                        <div className="col xl-12">
                          <h3>{!viewOnly && `Your`} Secondary Addresses</h3>
                        </div>
                      )}
                      <div className="col xl-12">
                        <div className="row">
                          <Location
                            label="Address"
                            required={!viewOnly}
                            name={`address[${index + 1}]`}
                            setFieldValue={setFieldValue}
                            initialValue={initialValues.address[index + 1]}
                            className="col xl-12"
                            disabled={viewOnly}
                          />
                          <div className="col xl-12">
                            <div className="row">
                              <TextInput
                                label="Post Code"
                                required={!viewOnly}
                                name={`postcode[${index + 1}]`}
                                className="col xl-6"
                                disabled={viewOnly}
                              />
                              <TextInput
                                label="City / Suburb"
                                required={!viewOnly}
                                name={`city[${index + 1}]`}
                                className="col xl-6"
                                disabled={viewOnly}
                              />
                              <TextInput
                                label="State"
                                required={!viewOnly}
                                name={`state[${index + 1}]`}
                                className="col xl-6"
                                disabled={viewOnly}
                              />
                              <LocalGovtAssociation
                                name={`local_govt_association_id[${index + 1}]`}
                                profileType={values.profile_type}
                                value={
                                  values.local_govt_association_id[index + 1]
                                }
                                className="col xl-6"
                                required={!viewOnly}
                                disabled={viewOnly}
                                state={values.state[index + 1]}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      {!viewOnly && (
                        <div className="col xl-12 delete-address">
                          <button
                            title="Delete Address"
                            className="delete-btn"
                            type="button"
                            disabled={props.isRequesting}
                            onClick={() => {
                              // remove values as well
                              values.address.splice(index + 1, 1);
                              values.postcode.splice(index + 1, 1);
                              values.city.splice(index + 1, 1);
                              values.state.splice(index + 1, 1);
                              values.local_govt_association_id.splice(
                                index + 1,
                                1
                              );
                              setErrors({});
                              handleAddressDelete(
                                initialValues.company_id,
                                initialValues.id[index + 1]
                              );
                            }}
                          >
                            Delete
                          </button>
                        </div>
                      )}
                    </div>
                  );
                })}
                <TextInput
                  name="company_id"
                  value={initialValues.company_id}
                  type="hidden"
                />
                {!viewOnly && (
                  <div className="add-more-container">
                    {!sameState && (
                      <FlashError
                        heading={"Location Error"}
                        text={
                          "You are trying to add a address from a different state. Each address must be from same state."
                        }
                      />
                    )}
                    <button
                      onClick={() => setForms(forms + 1)}
                      type="button"
                      className="add-more-btn"
                    >
                      Add Address
                      <span>
                        <Plus height={30} width={30} color="#0FE3C7" />
                      </span>
                    </button>
                  </div>
                )}
                {!viewOnly && (
                  <Submit
                    type="submit"
                    isSubmitting={isSubmitting}
                    onClick={() => setShowToast(true)}
                    spinner
                    text="Save Address"
                    submittingText="Saving..."
                    disabled={!enableSubmit}
                  />
                )}

                <OnSubmitValidationError callback={onSubmitValidationError} />
              </TabContent>
            </Form>
          );
        }}
      </Formik>
    </LocationFormStyled>
  ) : (
    <Skeleton count={5} duration={0.5} />
  );
};

const mapStateToProps = (state) => {
  const company = state.search.activeCompany;
  const locations = state.search.activeCompany.locations;
  return {
    isRequesting: state.companyProfile.isRequesting,
    company: company,
    initialValues: {
      address:
        locations.map((loc) => {
          return {
            value: loc.address,
            longitude: loc.longitude,
            latitude: loc.latitude,
          };
        }) || [],
      local_govt_association_id:
        locations.map((loc) => loc.local_govt_association_id) || [],
      company_id: company.id || "",
      postcode: locations.map((loc) => loc.postcode) || [],
      city: locations.map((loc) => loc.city) || [],
      state: locations.map((loc) => loc.state) || [],
      id: locations.map((loc) => loc.id) || [],
      profile_type: company.profile_type.id,
    },
  };
};

export const CompanyLocationForm = connect(mapStateToProps, {
  submitCompanyLocationForm,
  deleteCompanyAddress,
})(CompanyLocationFormComponent);
