import { Button } from "components/Button";
import { Heading } from "components/Heading";
import { OTPInput } from "input-otp";
import React from "react";
import styled from "styled-components";
import {ReactComponent as BackIcon} from "../../icons/backicon.svg"

const TwoFactorContainer = styled.div`
  position: relative;
  padding-bottom: 24px;
  margin-bottom: 24px;
  .slot-container {
    display: flex;
    gap: 12px;
  }
  .slot-code {
    transition: all 0.1s;
    width: 76px;
    height: 110px;
    border: 1px solid #d0d5dd;
    box-shadow: 0px 1px 2px 0px #1018280d;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .code-character {
    font-size: 36px;
    font-weight: 500;
    color: #344054;
  }
  .slot-active {
    border: 2px solid #0045f5;
  }
  .back-button{
    display: flex;
    position: absolute;
    align-items: center;
    gap: 12px;
    top: -56px;
    left: -84px;
    cursor: pointer;
    svg{
        width: 48px;
        height: 48px;
    }
  }
`;

const Slot = (props) => {
    const haveChar = props.char !== null;
    return (
      <div className={`slot-code ${props?.isActive && "slot-active"}`}>
        <p style={{ opacity: haveChar ? 1 : 0.3 }} className="code-character">
          {haveChar ? props.char : 0}
        </p>
      </div>
    );
  };

const TwoFactorCode = ({setCodeScreen, setCode, verifyHandler,code}) => {

  
  
  return (
    <div>
      <TwoFactorContainer>
        <div className="back-button" onClick={()=> setCodeScreen(false)}> 
            <BackIcon/>
            <p>Back</p>
        </div>
        
        <Heading marginBottom="40px">
          <span>Enter your 6-digital</span>
          <span>authentication code</span>
        </Heading>

        <h3 style={{ fontWeight: 400, margin: "93px 0 26px 0" }}>
          Enter the 6-digit code generated by your authenticator app.
        </h3>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            verifyHandler();
          }}
        >
          <OTPInput
            textAlign="center"
            maxLength={6}
            onComplete={(code) => setCode(code)}
            onChange={(code) => setCode(code)}
            render={({ slots }) => (
              <div className="slot-container">
                {slots.map((slot, index) => (
                  <Slot key={index} {...slot} />
                ))}
              </div>
            )}
          />
        </form>
      </TwoFactorContainer>

      <Button
        type="submit"
        text="Verify"
        onClick={verifyHandler}
        style={{ width: "100%" }}
        disabled={code.length !== 6}
      >
        Verify and enable 2FA
      </Button>
    </div>
  );
};

export default TwoFactorCode;
