import React from 'react';
import styled from 'styled-components';

const NotFoundStyled = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
`;

export const NotFound = () => {
    return (
        <NotFoundStyled>
            <h1>Error: Path not found</h1>
        </NotFoundStyled>
    );
};
