import styled from "styled-components";

export const ShortlistUpgradeWrapper = styled.div`
  display: flex;
  .side-content {
    padding: 60px 40px;
    font-family: Abolition;
    span {
      display: flex;
      flex-direction: row;
      align-items: start;
      font-family: Arial;
      font-size: 15px;
      font-weight: 400;
      color: #565656;
      padding: 12px 0px;
      column-gap: 16px;
      svg {
        min-width: 24px;
        width: 24px;
        height: 24px;
      }
    }
    p {
      font-family: Arial;
      font-size: 15px;
      font-weight: 400;
      color: #565656;
    }
  }
`;
