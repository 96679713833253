import styled from 'styled-components';

import { theme } from '../../theme';

export const ProjectLinkStyled = styled.div`
    display: table-row;
    background: white;
    box-shadow: 0px 4px 16px rgba(208, 210, 212, 0.1), 0px 4px 44px #edeef0;
    border-collapse: separate;
    border-radius: 15px;
    position: sticky !important;
    .animate-pop{
        fill:rgb(15, 227, 199);
        path{
            stroke:rgb(15, 227, 199);
        }
    }
    .star-icon{
        &:hover{
            path{
                stroke:rgb(15, 227, 199);
            }
        }
        path{
            stroke: gray;
        }
    }

    .project-link {
        &__inner {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            align-items: center;
            padding: 8px;
        }

        &__title {
            margin: 0 0 0 1.75rem;
            color: ${(props) => props.theme.colors.grayDark};
            font-size: ${(props) => props.theme.fontSizes.md};
            display: table-cell;
        }

        &__specs {
            display: flex;
            align-items: center;
            margin-left: auto;
            padding-right: 1rem;
        }

        &__btn-cell {
            text-align: right;
        }

        &__cell {
            display: table-cell;
            padding: 8px 0;
            vertical-align: middle;

            &:first-of-type {
                border-top-left-radius: ${(props) => props.theme.radii.default};
                border-bottom-left-radius: ${(props) => props.theme.radii.default};
                padding-left: 16px;
            }

            &:last-of-type {
                border-top-right-radius: ${(props) => props.theme.radii.default};
                border-bottom-right-radius: ${(props) => props.theme.radii.default};
                padding-right: 16px;
                width: ${(props) => (props.extraButton ? 'auto' : '150px')};
                align-items: center;
                justify-content:flex-end;
                a{
                    text-align: center;
                }
                > button + button {
                    margin-left: 15px;
                }
                .added-button{
                    display:flex;
                    align-items:center;
                    .check{
                        background-color: ${(props) => props.theme.colors.mint};
                        height:15px;
                        width:15px;
                        display:flex;
                        border-radius:50%;
                        justify-content:center;
                        align-items:center;
                        margin-bottom:2px;
                    }
                    .text{
                        margin-left:5px;
                    }
                }
            }

            &--img {
                width: 90px;
                min-width: 90px;
            }

            &--title {
                width: 20%;
                padding: 0 ${(props) => props.theme.space.sm};
                font-weight: 600;
                text-transform: capitalize;

                ${theme.mediaQueries.xxl} {
                    width: 27%;
                }
            }

            &--spec {
                padding: 8px 1.25rem 8px 0;

                .project-link__cell-inner {
                    border-left: 1px solid ${(props) => props.theme.colors.grayBorder};
                    padding: 0 0 0 1.25rem;
                    text-transform: capitalize;          
                }
                
                & + .project-link__cell--spec {
                    margin-left: 1.25rem;
                }

                h5,
                p {
                    margin: 0;
                    font-size: ${(props) => props.theme.fontSizes.xs};
                }

                h5 {
                    color: ${(props) => props.theme.colors.gray};
                    font-weight: 400;
                }

                p {
                    color: ${(props) => props.theme.colors.grayDark};
                    font-weight: 500;
                }
            }
        }
    }
`;

export const VisibilityButton = styled.button`
    border: none;
    background: transparent;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;

    svg {
        width: 23px;
        height: 23px;
        opacity: 0.5;
        transition: ${(props) => props.theme.transitions.default};

        path {
            transition: ${(props) => props.theme.transitions.default};
        }
    }

    &::hover {
        svg {
            opacity: 1;

            path {
                fill: ${(props) => props.theme.colors.royalBlue};
            }
        }
    }
`;
