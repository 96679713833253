import React from 'react';

import { Select } from '../Select';

export const RelevantYear = ({ required, name, setFieldValue, defaultValue, ...props }) => {
    let currentYear = new Date().getFullYear(),
        optionsArray = [];
    let startYear = currentYear - 5;
    while (startYear <= currentYear) {
        let optionYear = currentYear--;
        optionsArray.push({
            value: optionYear,
            label: optionYear
        });
    }

    let value = optionsArray.filter((item) => {
        return item.value === defaultValue;
    });

    return (
        <Select
            label='Relevant Year'
            options={optionsArray}
            name={name}
            setFieldValue={setFieldValue}
            defaultValue={value}
            className={props.className}
        />
    );
};
