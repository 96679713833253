import React from 'react';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { useField, useFormikContext } from 'formik';

import { FormLabel } from '../FormInputs/TextInput';
import { Error } from '../FormInputs/Error';

const animatedComponents = makeAnimated();


export const GlobalPresentMulti = ({ options, name, setFieldValue, values, noLabel, maxOptions, ...props }) => {
    //eslint-disable-next-line
    const [field,meta] = useField(name);
    const { setFieldTouched } = useFormikContext();
    const isError = meta.touched && meta.error;

    const customStyles = {
        option: (provided, state) => ({
            ...provided,
            cursor: 'pointer'
        }),

        dropdownIndicator: base => ({
            ...base,
            color: '#336AF7'
        }),
        
        control: (provided) => ({
            ...provided,
            padding: '0.438rem',
            minHeight: '60px',
            borderColor: isError ? '#E11900' : '#e7eaee',
            marginBottom: isError ? '2.25rem' : '1.75rem'
        })
    };

    const handleOnChange = (options) => {
        const sortArray = options.sort((a,b)=>a.value-b.value)
        setFieldValue(name, props.sortingValue ? sortArray :options);
    };


    return (
        <div className={props.className} style={{ position: 'relative' }}>
            {props.label && !noLabel && (
                <FormLabel>
                    {props.label}
                    {props.required && <span>*</span>}
                </FormLabel>
            )}

            <Select
                isMulti
                styles={customStyles}
                closeMenuOnSelect={false}
                components={{ animatedComponents }}
                options={options}
                onChange={handleOnChange}
                onBlur={() => setFieldTouched(name, true)}
                value={values}
                placeholder='Select..'
            />

            <Error meta={meta} />
        </div>
    );
};
