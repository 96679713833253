import styled from "styled-components";

export const DocumentRequestStyled = styled.div`
  h3 {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    color: #253858;
  }
  .reset-arrow {
    display: flex;
    align-items: center;
    margin-bottom: 59px;
  }
`;

export const RequestSection = styled.div`
  h2 {
    font-size: 18px;
    color: ${(props) => props.theme.colors.black};
    font-weight: 500;
  }
  .section-header {
    margin-top: ${(props) => props.theme.space.sm};
    .first-item {
      padding-left: 0px;
    }
    span {
      font-size: ${(props) => props.theme.fontSizes.sm};
      padding-left: 15px;
      color: ${(props) => props.theme.colors.black};
      font-weight: 500;
      text-transform: uppercase;
      letter-spacing: 1.125px;
    }
  }
  .create-btn {
    margin-bottom: 14px;
  }
`;

export const PlanSection = styled.div`
  justify-content: space-between;
  display: flex;
  .label {
    color: #263859;
    font-size: 18px;
    font-weight: 500;
    p {
      line-height: 22px;
    }
  }
  .details {
    color: #8492a6;
    font-size: 15px;
    font-weight: 400;
    p {
      line-height: 24px;
      font-family: ${(props) => props.theme.fonts.open_sauce_two};
      font-weight: 400;
    }
  }
`;

export const OpportunitySection = styled.div`
  .flex-box {
    display: flex;
    justify-content: space-between;
    button {
      margin-left: auto;
    }
  }
  h4 {
    color: #263859;
    font-size: 18px;
    font-weight: 500;
    margin-top: 14px;
  }
  p {
    color: #8492a6;
    font-size: 15px;
    font-family: ${(props) => props.theme.fonts.open_sauce_two};
    font-weight: 400;
  }
`;
export const InfoRow = styled.div`
  margin-top: 8px;
  margin-bottom: 24px;
  background-color: ${(props) => props.theme.colors.white};
  box-shadow: 0px 4px 16px rgba(208, 210, 212, 0.4);
  border-radius: 5px;
  padding-top: 16px;
  padding-bottom: 16px;
  margin-left: 0px;
  .data-cell {
    svg {
      margin-right: 20px;
    }
    border-right: 1px solid rgba(132, 146, 166, 0.4);
    padding: 6px 4px 6px 16px;
    display: flex;
    color: ${(props) => props.theme.colors.navy};
    align-items: center;
    font-size: 15px;
    overflow-wrap: anywhere;
    &.first {
      font-size: 15px;
      font-weight: 400;
    }
    &.no-border {
      border: none;
    }
    &.last {
      border: none;
      padding-right: 24px;
    }
    &.action-cell {
      display: flex;
      justify-content: space-around;
    }
    button {
      padding: 2px;
      span {
        display: inline-block;
        margin-top: 2px;
        letter-spacing: 1.13px;
        
      }
    }
  }
`;

export const ViewRequestFormStyled = styled.div`
  background-color: white;
  padding: ${(props) => props.theme.space.sm_2};
  h3 {
    color: #263859;
    font-size: 22px;
    font-weight: 600;
  }
  .modal-buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: 8px;
    button {
      margin-right: 0px;
    }
  }
  .modal-footer {
    font-size: 15px;
    font-weight: 500;
    span {
      color: ${(props) => props.theme.colors.royalBlue};
    }
  }
  .doc-reason {
    div {
      padding: 0;
    }
    label {
      font-size: 20px;
      color: black;
    }
  }
`;
