import React, { useReducer, useEffect, useState } from "react";
import _orderBy from "lodash.orderby";

import { DataTableStyled, DataTableHeader } from "./styled";
import { SortIndicator } from "./SortIndicator";
import styled from "styled-components";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { reorderList } from "utils/reorderList";
import { updateProjectOrderings } from "actions/companyActions";

export const DataTable = ({ data, ...props }) => {
  const initialState = { isAsc: true, items: data };
  const [isOrdering,setIsOrdering] = useState(false)

  const sortReducer = (state, action) => {
    switch (action.type) {
      case "SORT":
        return {
          sortKey: action.key,
          sortOrder: action.isAsc ? "asc" : "desc",
          isAsc: action.isAsc,
          items: _orderBy(
            [...data],
            [action.key],
            [action.isAsc ? "asc" : "desc"]
          ),
        };
      case "ORDER":
        return {
          ...state,
          items: action.value
        }
      default:
        return {
          ...state,
          items: _orderBy(
            [...data],
            [state.sortKey],
            [state.isAsc ? "asc" : "desc"]
          ),
        };
    }
  };

  const setItems = (data) => {
    dispatch({
      type: "ORDER",
      value: data,
    })
  }

    //a quick fix here to refresh the sorted data after the visibility of a past-project is toggled.
    //redux reducer is run after toggle click, so we just want to refresh the table data but keep the current sorting values
    //REFRESH_DATA action type will hit the default fallback in this component reducer, and assumes current state but with new data from redux
    useEffect(() => {
        dispatch({ type: 'REFRESH_DATA' });
    }, [data]);

    const [itemData, dispatch] = useReducer(sortReducer, initialState);

  const handleClick = (key) => {
    dispatch({
      key: key,
      isAsc: itemData.sortKey === key ? !itemData.isAsc : true,
      type: "SORT",
    });
  };

  const orderReq = () => {
    const reducerLength = Object.keys(itemData).length
    if(props.hasSearched || reducerLength > 2 || isOrdering) return false
    return true
  }

  const TableWrap = styled.div`
   scroll-behavior: smooth;
  `;

  const sendOrder = async (reOrder,type)  => {
    setIsOrdering(true)
    const reOrderId = reOrder.map((project,i) => {return {id:project.id,order: i+1}})
    const bodyReq = {
      company_id : props.companyId,
      ordering : type === "one" ? JSON.stringify([reOrderId[0]]) : JSON.stringify(reOrderId),
      type: type
    }
    updateProjectOrderings(bodyReq).then((res)=>{
      setIsOrdering(false)
    })
  }
  
  const handlePinProject = (id) => {
    if(orderReq()){
      const result = {
        source:{
          index: id
        },
        destination:{
          index: 0
        }
      }
      const reOrder = reorderList(itemData.items,result,setItems)
      sendOrder(reOrder,"one")
    }else{
      let changedOrder = itemData.items.map((item,i)=>{
        let itemDupe = item
        if(id === i) {
          itemDupe.order = 1
          return itemDupe
        }else if(item.order === null){
          return itemDupe
        }else{
          itemDupe.order++
          return itemDupe
        }
      })
      setItems(changedOrder)
      sendOrder([changedOrder[id]],"one")
    }
  }

  return (
    <DataTableStyled headers={props.headers}>
      {props.headers && (
        <div className="table-header">
          
                {props.headers.map((header, i) => {
                  return (
                    <DataTableHeader
                      key={i}
                      isSortable={header.key || header.hover}
                      onClick={() => handleClick(header.key)}
                      style={{position:"relative"}}
                    >
                      {/* <DataTableHeader key={i} isSortable={header.key} onClick={() => handleClick(header.key)}> */}
                      {header.label}
                      {header.hover && 
                          <div className="tooltip-container">{header.hover}</div>
                      }
                      {header.key && (
                        <SortIndicator
                          sortKey={header.key}
                          currentSortKey={itemData.sortKey}
                          currentSortOrder={itemData.sortOrder}
                  />
                )}
              </DataTableHeader>
            );
          })}
              
          
          
        </div>
      )}

        <DragDropContext 
          
          onDragEnd={(result)=>{
            const reOrder = reorderList(itemData.items,result,setItems);
            sendOrder(reOrder,"all")
          }}>
          <Droppable droppableId="droppable-project" direction="vertical">
            {(provided) => (
              <TableWrap
              ref={provided.innerRef}
              {...provided.droppableProps}
              className="table-body"
            >
              {props.component &&
                itemData.items.map((item, i) => {
                  return (
                    <Draggable
                       key={item.id}
                       draggableId={`${item.id}`}
                      index={i}
                      isDragDisabled={!orderReq() || !props.isEdit }
                     >
                      {(provided, snapshot) => (
                        
                        <props.component key={i} data={item} {...props.componentProps} reff={provided.innerRef}
                                  dragProps={{...provided.draggableProps, ...provided.dragHandleProps}} index={i}
                                  handlePinProject={handlePinProject} orderReq={orderReq}
                                  />
                      
                      )}
                     </Draggable>
                  );
                })}
              </TableWrap>
              )}
          
            </Droppable>
          </DragDropContext>
    </DataTableStyled>
  );
};
