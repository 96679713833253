import React from "react";
import { SearchTag } from "./SearchTag";
import { SearchTagRowStyled } from "./styled";
import { RefineButton } from "../RefineResults/styled";
import { ControlButton } from "../CompanyResultCard/ControlButton";

const capitalize = (s) => {
  if (typeof s !== "string") return "";
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const SearchTagRow = ({
  searchCriteria,
  disciplines,
  sectors,
  types,
  trades,
  specialities,
  open,
  handleRefineButtonClick,
}) => {
  console.log("testSpec", specialities);
  let filteredDisciplines = [];
  let filteredSectLabel;
  let filteredTypeLabel;
  let filteredTrades;
  let filteredSpecialities;

  const URL = window.location.pathname;
  const hideRefineBtn = URL.includes("compare") || URL.includes("shortlist");

  if (
    searchCriteria.discipline !== undefined &&
    Array.isArray(searchCriteria.discipline)
  ) {
    filteredDisciplines = searchCriteria.discipline.map((criteriaDiscipline) =>
      disciplines.find((discipline) => discipline.id === criteriaDiscipline)
    );
  }

  if (
    searchCriteria.sector_id !== null &&
    searchCriteria.sector_id !== undefined
  ) {
    let sectorArr = sectors.sectorData;
    let searchedSector = searchCriteria.sector_id;
    let filteredSectorObj = sectorArr.filter(
      (sect) => sect.id === searchedSector
    );
    filteredSectLabel = filteredSectorObj[0] ? filteredSectorObj[0].name : null;
  }

  if (
    searchCriteria.profile_type !== null &&
    searchCriteria.profile_type !== undefined
  ) {
    let searchTypeId = searchCriteria.profile_type;
    let filteredTypeObj = types.filter((type) => type.id === searchTypeId);
    filteredTypeLabel = filteredTypeObj[0]
      ? capitalize(filteredTypeObj[0].name)
      : null;
    if (filteredTypeLabel === "Contractor")
      filteredTypeLabel = "Head Contractor";
  }

  if (
    searchCriteria.trades !== null &&
    searchCriteria.trades !== undefined &&
    searchCriteria.trades?.length !== 0
  ) {
    let searchTradeIds = searchCriteria.trades;
    filteredTrades = searchTradeIds.map((id) =>
      trades.find((trade) => trade.value === id)
    );
  }

  if (
    searchCriteria.specialities !== null &&
    searchCriteria.specialities !== undefined &&
    searchCriteria.specialities?.length !== 0
  ) {
    let searchSpecialitiesIds = searchCriteria.specialities;
    filteredSpecialities = searchSpecialitiesIds.map((id) =>
      specialities?.find((speciality) => speciality.value === id)
    );
  }

  return (
    <SearchTagRowStyled>
      {searchCriteria.sector_id !== null &&
        searchCriteria.sector_id !== undefined && (
          <SearchTag title="Sector" value={filteredSectLabel} />
        )}

      {searchCriteria.profile_type !== null &&
        searchCriteria.profile_type !== undefined && (
          <SearchTag title="Type" value={filteredTypeLabel} />
        )}

      {searchCriteria.expertise !== null &&
        searchCriteria.expertise !== undefined && (
          <SearchTag
            title={`${
              searchCriteria.profile_type === 3 ? "Service" : "Expertise"
            }`}
            value={searchCriteria.expertise}
          />
        )}

      {searchCriteria.discipline !== null &&
        searchCriteria.discipline !== undefined && (
          <SearchTag
            title={`${
              searchCriteria.profile_type === 3 ? "Category" : "Discipline"
            }`}
            value={filteredDisciplines.map((item) => ({ label: item.value }))}
          />
        )}

      {searchCriteria.trades !== null && filteredTrades !== undefined && (
        <SearchTag title="Trades" value={filteredTrades} />
      )}

      {searchCriteria.specialities !== null &&
        filteredSpecialities !== undefined && (
          <SearchTag title="Specialities" value={filteredSpecialities} />
        )}

      {!hideRefineBtn && (
        <RefineButton opened={open}>
          {open ? (
            <ControlButton
              radius="5px"
              type="inverseRemove"
              square
              onClick={handleRefineButtonClick}
            />
          ) : (
            <ControlButton
              radius="5px"
              type="refine"
              square
              onClick={handleRefineButtonClick}
            />
          )}
        </RefineButton>
      )}
    </SearchTagRowStyled>
  );
};

SearchTagRow.defaultProps = {
  trades: [],
  open: false,
};
