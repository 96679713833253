import React from 'react';

import { Select } from '../Select';

export const YearCompleted = ({ name, setFieldValue, values, defaultValue, ...props }) => {
    // Show 5 years to the future, and 10 years in the past
    let currentYear = new Date().getFullYear() + 5,
        optionsArray = [];
    let startYear = currentYear - 15;

    while (startYear <= currentYear) {
        let optionYear = currentYear--;
        optionsArray.push({
            value: optionYear.toString(),
            label: optionYear.toString()
        });
    }

    let value = optionsArray.filter((item) => {
        return item.value === defaultValue.toString();
    });

    return (
        <Select
            label='Year Complete'
            required='true'
            options={optionsArray}
            name={name}
            setFieldValue={setFieldValue}
            defaultValue={value}
        />
    );
};
