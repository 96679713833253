import React, { useEffect } from "react";
import { connect } from "react-redux";
import _get from "lodash.get";
import { useFormikContext } from "formik";
import { getSpecialities } from "actions/companyActions";
import { SpecialityMulti } from "./SpecialityMulti";
import { Select } from "../Select";

export const SpecialityComponent = ({
  options,
  name,
  singleValue,
  values,
  value,
  includeAllOption,
  onChange,
  showPorojectsCount,
  getSpecialities,
  ...props
}) => {
  useEffect(() => {
    if (options.length === 0) {
      getSpecialities();
    }
  }, [getSpecialities, options]);
  const { setFieldValue } = useFormikContext();
  const SelectComponent = singleValue ? Select : SpecialityMulti;

  //use this var to create proper label/value obj
  let valueObj = [];

  //pick out the initial values from the options array based on sector id's from form
  if (!singleValue) {
    if (Array.isArray(values[name])) {
      valueObj = values[name].map((id) => {
        return options.find((option) => {
          return option.value === id;
        });
      });
    }
  } else {
    //match up the value from the form to the ID's we retrieve from the API
    //need lodash _get to help when retrieving formik field array nested values.
    valueObj = options.find(
      (option) => option.value === _get(values, name, {})
    );
  }

  return (
    <SelectComponent
      options={options}
      name={name}
      setFieldValue={setFieldValue}
      label={props.label || "Specialty"}
      required={props.required}
      value={valueObj}
      noLabel={props.noLabel}
      className={props.className}
      isClearable={true}
      customOnChange={onChange}
      {...props}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    options: state.companyProfile.specialities,
  };
};

export const Speciality = connect(mapStateToProps, { getSpecialities })(
  SpecialityComponent
);
