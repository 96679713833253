import React, { useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import Skeleton from "react-loading-skeleton";
import { toast } from "react-toastify";
import { TextInput } from "../FormInputs/TextInput";
import { Submit } from "../FormInputs/Submit";
import { EditUserAccountStyled } from "./styled";
import { update_user } from "../../actions/AccountManagementActions";
import { OnSubmitValidationError } from "../../utils/OnSubmitValidationError";
import { Heading } from "../Heading";
import { Select } from "../FormInputs/Select";
import { Button } from "../Button";
import { ConfirmDelete } from "../ConfirmDelete";
import { delete_user } from "../../actions/AccountManagementActions";
import { ReactComponent as Trash } from "./../../icons/trash-2.svg";
import { userRoles, findUserRole } from "../../utils/helpers";
import { TabHeader } from "../TabHeader";
import { TabContent } from "../Tabs/styled";
import { Divider } from "../Divider";
import { FlashError } from "../FormInputs/FlashError";
import { ADMINISTRATOR_TEXT } from "../../utils/constants";

export const EditUserAccountComponent = ({
  company,
  viewOnly,
  current_user,
  delete_user,
  ...props
}) => {
  const target_user = props.location.state.user.user_draft
    ? props.location.state.user.user_draft
    : props.location.state.user;

  const companyUsers = props.location.state?.company_users || [];

  const initialValues = {
    id: props.location.state.user.id,
    first_name: target_user.first_name || "",
    last_name: target_user.last_name || "",
    phone: target_user.phone || "",
    email: target_user.email || "",
    position: target_user.position || "",
  };

  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
  const isOnlyAdmin =
    companyUsers.filter((user) => user.position === ADMINISTRATOR_TEXT)
      .length === 1;

  const closeDeleteModal = () => {
    setDeleteModalIsOpen(false);
  };

  const handleDeleteConfirm = async () => {
    await delete_user(props.location.state.user.id);
    setDeleteModalIsOpen(false);
  };
  const openDeleteModal = () => {
    setDeleteModalIsOpen(true);
  };

  return company ? (
    <EditUserAccountStyled>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={Yup.object({
          first_name: Yup.string().min(2, "* THIS FIELD MUST CONTAIN AT LEAST Two CHARACTER").required("* THIS FIELD REQUIRED"),
          last_name: Yup.string().min(2, "* THIS FIELD MUST CONTAIN AT LEAST TWO CHARACTER").required("* THIS FIELD IS REQUIRED"),
          email: Yup.string().required("* THIS FIELD IS REQUIRED"),
          phone: Yup.string()
            .required("* THIS FIELD IS REQUIRED")
            .matches(
              /^(?=.*)((?:\+?61) ?(?:\((?=.*\)))?([2-47-8])\)?|(?:\((?=.*\)))?([0-1][2-47-8])\)?) ?-?(?=.*)((\d{1} ?-?\d{3}$)|(00 ?-?\d{4} ?-?\d{4}$)|( ?-?\d{4} ?-?\d{4}$)|(\d{2} ?-?\d{3} ?-?\d{3}$))/,
              "Invalid Phone No."
            ),
          position: Yup.string().required("* THIS FIELD IS REQUIRED"),
        })}
        onSubmit={async (values, { setErrors, setStatus }) => {
          setStatus(false);
          try {
            await props.update_user(values);
          } catch (e) {
            const error = await e;
            toast.error(error.message);
            error.errors !== undefined && setErrors(error.errors);
          }
        }}
      >
        {(props) => {
          const { isSubmitting, values, status, setStatus } = props;
          const onSubmitValidationError = () => {
            setStatus(true);
          };
          return (
            <>
              <TabHeader heading="Edit User" />
              <TabContent>
                <Heading
                  fontFamily={"Roboto"}
                  marginBottom={"32px"}
                  fontSize="1.125rem"
                >
                  {"Edit Details"}
                </Heading>
                <Form>
                  <div className="row">
                    <TextInput
                      label="First Name"
                      required={!viewOnly}
                      name="first_name"
                      className="col xl-6"
                      disabled={viewOnly}
                    />
                    <TextInput
                      label="Last Name"
                      required={!viewOnly}
                      name="last_name"
                      className="col xl-6"
                      disabled={viewOnly}
                    />
                    <TextInput
                      label="Phone Number"
                      required={!viewOnly}
                      name="phone"
                      className="col xl-6"
                      disabled={viewOnly}
                    />
                    <TextInput
                      label="Email Address"
                      required={!viewOnly}
                      name="email"
                      className="col xl-6"
                      disabled
                    />
                    <Select
                      required="true"
                      options={userRoles}
                      name={"position"}
                      label={"User Type"}
                      disabled={
                        isOnlyAdmin &&
                        target_user.position === ADMINISTRATOR_TEXT
                      }
                      className={"col xl-6"}
                      value={findUserRole(values.position)}
                    />
                    <div className="col xl-7">
                      {status && (
                        <FlashError
                          heading="Required Field"
                          text="There are incomplete required fields, Please complete them."
                          margin="0px 0px 20px 0px"
                        />
                      )}
                    </div>
                    {deleteModalIsOpen && (
                      <ConfirmDelete
                        isOpen={true}
                        heading={"Delete User"}
                        onRequestClose={closeDeleteModal}
                        onConfirm={handleDeleteConfirm}
                        onCancel={closeDeleteModal}
                        itemName={`${target_user?.first_name} ${target_user?.last_name} from your account `}
                        color={"#7E7E7E"}
                        noborder
                        btnText={["Delete", "Deleting..."]}
                      />
                    )}
                    {!viewOnly && (
                      <div className="col xl-12 btn-row">
                        <div className="save-btns">
                          <Submit
                            type="submit"
                            isSubmitting={isSubmitting}
                            className="save-btn"
                            text="Save Details"
                            submittingText="Saving..."
                          />
                        </div>
                        {
                            current_user?.position === ADMINISTRATOR_TEXT && target_user.position !== ADMINISTRATOR_TEXT 
                          &&(
                            <Button
                              size={"sm"}
                              danger
                              className="delete-btn"
                              type="button"
                              btntype={"secondary"}
                              width={"200px"}
                              height={"60px"}
                              boxshadow={false}
                              onClick={openDeleteModal}
                            >
                              <Trash />
                              <span>Delete User</span>
                            </Button>
                          )}
                      </div>
                    )}
                  </div>
                  <OnSubmitValidationError callback={onSubmitValidationError} />
                </Form>

                <Divider />
              </TabContent>
            </>
          );
        }}
      </Formik>
    </EditUserAccountStyled>
  ) : (
    <Skeleton count={5} duration={0.5} />
  );
};

const mapStateToProps = (state) => {
  const company = state.search.activeCompany;
  const current_user = state.auth.user;
  return {
    isRequesting: state.companyProfile.isRequesting,
    company: company,
    current_user: current_user,
  };
};

export const EditUserAccount = connect(mapStateToProps, {
  update_user,
  delete_user,
})(EditUserAccountComponent);
