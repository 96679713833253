import React, { useEffect } from "react";
import { connect } from "react-redux";

import { getSpecialities } from "../../../actions/companyActions";
import { MultiSelect } from "../MultiSelect";

const SpecialitiesConnected = ({
  className,
  name,
  values,
  options,
  profileType,
  required,
  getSpecialities,
  ...props
}) => {
  useEffect(() => {
    if (options.length === 1) {
      getSpecialities();
    }
  }, [options, getSpecialities]);

  return (
    <MultiSelect
      label={"Specialities"}
      name="specialities"
      className={className}
      options={options}
      required={required}
    />
  );
};

SpecialitiesConnected.defaultProps = {
  label: "Specialty",
  name: "specialities",
  required: true,
  className: "col sm-12",
};

const mapStateToProps = (state) => {
  return {
    options: [
      ...state.companyProfile.specialities,
      { label: "Show All", value: 6 },
    ],
  };
};

export const Specialities = connect(mapStateToProps, {
  getSpecialities,
})(SpecialitiesConnected);
