import React, { useState } from "react";
import { connect } from "react-redux";
import { navigate } from "@reach/router";
import { toast } from "react-toastify";

import { ResourceForm } from "../ResourceForm";
import { editResource, deleteResource } from "../../actions/companyActions";
import { ConfirmDelete } from "../ConfirmDelete";
import Skeleton from "react-loading-skeleton";

export const EditResourceComponent = ({
  resources,
  resourceId,
  editResource,
  deleteResource,
  isRequesting,
  ...props
}) => {
  const initialValues = props.initialValues;
  const [autoUpdateForm, setAutoUpdateForm] = useState(false);
  const [confirmModalIsOpen, setConfirmModalIsOpen] = useState(false);

  const handleSubmit = async (values, { setErrors, setStatus }) => {
    try{
      await editResource(resourceId, values, setErrors, setStatus);
      if (autoUpdateForm) {
        await navigate("../resources");
        toast.success("Resource updated successfully.");
      }
    }catch(e){
      console.log('errortype', e)
      if(!(e instanceof Error)){
        const emailError = e
        setStatus(emailError.message);
        setErrors(emailError ? {email:emailError.error} : e.message);
        toast.error(e.message);
      }else{
        setStatus("Request Timed out");
        setErrors("Request Timed out");
        toast.error("Request Timed out");
      }
      
    }
      
  };

  const handleDelete = async () => {
    try {
      await deleteResource(resourceId);
      navigate("../resources");
      toast.success("Resource deleted successfully.");
    } catch (e) {
      const error = await e.response.json();
      console.log(error);
      toast.error(error.message);
    }
  };

  return (
    <>
      {!isRequesting ? (
        <>
          <ResourceForm
            initialValues={initialValues}
            handleSubmit={handleSubmit}
            handleDelete={() => setConfirmModalIsOpen(true)}
            isRequesting={props.isRequesting}
            setAutoUpdateForm={setAutoUpdateForm}
            autoUpdateForm={autoUpdateForm}
            editForm
          />

          <ConfirmDelete
            isOpen={confirmModalIsOpen}
            onRequestClose={() => setConfirmModalIsOpen(false)}
            onConfirm={() => handleDelete()}
            onCancel={() => setConfirmModalIsOpen(false)}
            itemName="resource"
            btnText={["Delete", "Deleting..."]}
          />
        </>
      ) : (
        <Skeleton count={5} duration={0.5} />
      )}
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  //pick out the particular resource from the store
  const resource = ownProps.resources.filter((resource) => {
    return resource.id === parseInt(ownProps.resourceId);
  });

  const values = resource[0] || {};

  return {
    isRequesting: state.companyProfile.requestingResources,
    initialValues: {
      company_id: state.search.activeCompany.id || "",
      // active: values.active,
      avatar_image: values.avatar_location || "",
      first_name: values.first_name || "",
      last_name: values.last_name || "",
      email: values.email || "",
      position: values.position || "",
      experience: values.experience || "",
      sector_ids: Array.isArray(values.sectors)
        ? values.sectors.map((sector) => sector.id)
        : [],
      roles: [1],
      projects_completed: values.projects_completed,
      resume:
        values.resume && values.resume.length > 0
          ? {
              resume_file: {
                path: values.resume ? values.resume[0] : "",
                name: values.resume ? values.resume[1] : "",
              },
            }
          : "",
    },
  };
};

export const EditResource = connect(mapStateToProps, {
  editResource,
  deleteResource,
})(EditResourceComponent);
