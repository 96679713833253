import React from 'react';

import { Shortlist } from './Shortlist';

export const SearchShortlist = ({ items, removeFromList, groupId, ...props }) => {
    if (!items.length) {
        return <h1 style={{ textAlign: 'center', marginTop: '4rem' }}>Shortlist is empty.</h1>;
    }

    const handleRemoveButtonClick = (event, companyId) => {
        removeFromList(companyId, 'shortlist', groupId);
    };

    return (
        <>
            <Shortlist
                path='/'
                noHeading
                items={items}
                padded
                searchShortlist
                handleRemoveButtonClick={handleRemoveButtonClick}
                linkNewTab
            />
        </>
    );
};
