import React from "react";

import { PageWrap, ContentContainer } from "../components/Layout";
import { Heading } from "../components/Heading";
import { TabContent } from "../components/Tabs/styled";

export const PrivacyPolicy = (props) => {
  return (
    <PageWrap>
      <TabContent>
        <ContentContainer bgColor="rgba(0,0,0,0)">
          <Heading size="xl">Privacy Policy</Heading>
          <div>
            <ol>
              <li>
                <h2>INTRODUCTION</h2>
                <p>
                  This document sets out the privacy policy of PROCURACON PTY
                  LTD ABN 29 629 025 225 operating under the registered business
                  name Procuracon (referred to in this privacy policy as ‘we’,
                  ‘us’, or ‘our’).
                </p>
                <p>
                  We take our privacy obligations seriously and we’ve created
                  this privacy policy to explain how we store, maintain, use and
                  disclose personal information.
                </p>
                <p>
                  By providing personal information to us, you consent to our
                  storage, maintenance, use and disclosing of personal
                  information in accordance with this privacy policy.
                </p>
                <p>
                  We may change this privacy policy from time to time by posting
                  an updated copy on our website and we encourage you to check
                  our website regularly to ensure that you are aware of our most
                  current privacy policy.
                </p>
              </li>
              <li>
                <h2>TYPES OF PERSONAL INFORMATION WE COLLECT</h2>
                <p>
                  The personal information we collect may include the following:
                </p>
                <ol type="a">
                  <li>name;</li>
                  <li>mailing or street address;</li>
                  <li>email address;</li>
                  <li>social media information;</li>
                  <li>telephone number and other contact details;</li>
                  <li>age;</li>
                  <li>date of birth;</li>
                  <li>photographs of you;</li>
                  <li>credit card or other payment information;</li>
                  <li>
                    information about your business or personal circumstances
                    including financial information, projects, key personnel,
                    and other business information you choose to provide us
                    with;{" "}
                  </li>
                  <li>
                    information in connection with client surveys,
                    questionnaires and promotions;
                  </li>
                  <li>
                    your device identity and type, I.P. address, geo-location
                    information, page view statistics, advertising data and
                    standard web log information;{" "}
                  </li>
                  <li>
                    your user activity when you use our marketplace or website,
                    including profiles you have viewed, searched, compared and
                    shortlisted;
                  </li>
                  <li>information about third parties; and</li>
                  <li>
                    any other information provided by you to us via our website,
                    our marketplace or our online presence, or otherwise
                    required by us or provided by you.
                  </li>
                  <p>
                    <b>(Providing personal information for others)</b> If you
                    provide any information to us which includes personal
                    information of another person, you must first obtain consent
                    from that person before giving us their personal
                    information. By providing that information to us, you
                    represent and warrant that you have obtained such consent. 
                  </p>
                </ol>
              </li>
              <li>
                <h2>HOW WE COLLECT PERSONAL INFORMATION</h2>
                <p>
                  We may collect personal information either directly from you,
                  or from third parties, including where you:
                </p>
                <ol type="a">
                  <li>contact us through our website;</li>
                  <li>receive goods or services from us;</li>
                  <li>submit any of our online sign up forms;</li>
                  <li>
                    communicate with us via email, telephone, SMS, social
                    applications (such as LinkedIn, Facebook or Twitter) or
                    otherwise;
                  </li>
                  <li>
                    interact with our website, social applications, services,
                    content and advertising; and
                  </li>
                  <li>
                    invest in our business or enquire as to a potential purchase
                    in our business.
                  </li>
                </ol>
                <p>
                  We may also collect personal information from you when you use
                  or access our website or our social media pages. This may be
                  done through use of web analytics tools, ‘cookies’ or other
                  similar tracking technologies that allow us to track and
                  analyse your website usage. Cookies are small files that store
                  information on your computer, mobile phone or other device and
                  enable and allow the creator of the cookie to identify when
                  you visit different websites. If you do not wish information
                  to be stored as a cookie, you can disable cookies in your web
                  browser. We may use Google Analytics to collect and process
                  data, including when you use third party websites or apps. To
                  find out more see{" "}
                  <a href="https://policies.google.com/technologies/partner-sites">
                    How Google uses data when you use our partners’ sites or
                    apps.
                  </a>
                </p>
              </li>
              <li>
                <h2>USE OF YOUR PERSONAL INFORMATION</h2>
                <p>
                  We collect and use personal information for the following
                  purposes:
                </p>
                <ol type="a">
                  <li>
                    to provide goods, services or information to you, including
                    on our marketplace;
                  </li>
                  <li>for record keeping and administrative purposes;</li>
                  <li>
                    to provide information about you to our contractors,
                    employees, consultants, agents or other third parties for
                    the purpose of providing goods or services to you;
                  </li>
                  <li>
                    to improve and optimise our service offering and customer
                    experience;
                  </li>
                  <li>
                    to comply with our legal obligations, resolve disputes or
                    enforce our agreements with third parties;
                  </li>
                  <li>
                    to send you marketing and promotional messages and other
                    information that may be of interest to you and for the
                    purpose of direct marketing (in accordance with the Spam
                    Act). In this regard, we may use email, SMS, social media or
                    mail to send you direct marketing communications. You can
                    opt out of receiving marketing materials from us by using
                    the opt-out facility provided (e.g. an unsubscribe link);
                  </li>
                  <li>
                    to send you administrative messages, reminders, notices,
                    updates, security alerts, and other information requested by
                    you;{" "}
                  </li>
                  <li>for statistics and market research; and</li>
                  <li>to consider an application of employment from you.</li>
                </ol>
                <p>
                  We may disclose your personal information to cloud-providers,
                  contractors and other third parties located inside or outside
                  of Australia. If we do so, we will take reasonable steps to
                  ensure that any overseas recipient deals with such personal
                  information in a manner consistent with how we deal with it.
                  Be aware that when engaging with us on our marketplace that
                  other users may be overseas and before you upload or supply
                  any personal information it is your responsibility to check
                  where any other user is located and how this concerns your
                  personal information. It is your choice if you engage with
                  overseas users.
                </p>
              </li>
              <li>
                <h2>SECURITY</h2>
                <p>
                  We take reasonable steps to ensure your personal information
                  is secure and protected from misuse or unauthorised access.
                  Our information technology systems are password protected, and
                  we use a range of administrative and technical measures to
                  protect these systems. However, we cannot guarantee the
                  security of your personal information. We recommend that you
                  take all necessary precautions to protect your personal
                  information while you are online, including installing
                  appropriate operating system updates, browser updates, virus
                  protection software and firewall software. 
                </p>
              </li>
              <li>
                <h2>LINKS</h2>
                <p>
                  Our website may contain links to other websites. Those links
                  are provided for convenience and may not remain current or be
                  maintained. We are not responsible for the privacy practices
                  of those linked websites and we suggest you review the privacy
                  policies of those websites before using them.
                </p>
              </li>
              <li>
                <h2>
                  REQUESTING ACCESS OR CORRECTING YOUR PERSONAL INFORMATION
                </h2>
                <p>
                  If you wish to request access to the personal information we
                  hold about you, please contact us using the contact details
                  set out below including your name and contact details. We may
                  need to verify your identity before providing you with your
                  personal information. In some cases, we may be unable to
                  provide you with access to all your personal information and
                  where this occurs, we will explain why. We will deal with all
                  requests for access to personal information within a
                  reasonable timeframe. If you think that any personal
                  information we hold about you is inaccurate, please contact us
                  using the contact details set out below and we will take
                  reasonable steps to ensure that it is corrected.
                </p>
              </li>
              <li>
                <h2>COMPLAINTS</h2>
                <p>
                  If you wish to complain about how we handle your personal
                  information held by us, please contact us using the details
                  set out below including your name and contact details. We will
                  investigate your complaint promptly and respond to you within
                  a reasonable timeframe.
                </p>
              </li>
              <li>
                <h2>CONTACT US</h2>
                <p>
                  For further information about our privacy policy or practices,
                  or to access or correct your personal information, or make a
                  complaint, please contact us using the details set out below:
                </p>
                <p>
                  <b>Email: </b>{" "}
                  <a href="mailto:admin@procuracon.com.au">
                    admin@procuracon.com.au
                  </a>
                </p>
                <p>Our privacy policy was last updated on 14 March 2022. </p>
              </li>
            </ol>
          </div>
        </ContentContainer>
      </TabContent>
    </PageWrap>
  );
};
