import React from 'react';

import { ButtonPillStyled } from './styled';

export const ButtonPill = ({ children, color, className, ...rest }) => {
    return (
        <ButtonPillStyled color={color} className={className} {...rest}>
            {children}
        </ButtonPillStyled>
    );
};

ButtonPill.defaultProps = {
    color: 'mint'
};
