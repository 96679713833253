import { Button } from 'components/Button';
import { ButtonRow, ConfirmHeading } from 'components/ConfirmDelete/styled';
import { Heading } from 'components/Heading'
import { Modal } from 'components/Modal'
import React from 'react'

import styled from 'styled-components';

export const ConfirmDeactivateModal = styled(Modal)`
    padding:3.37rem;
    background-color:white;
    min-width:570px;
    .delete-btn{
        float:right;
        padding:8px 12px;
        color:white;
        background: #FE0202;
        border: 1px solid #FE0202;
        cursor:pointer;
        display: flex;
        padding: 8px 35px;
        align-items: center;
        justify-content: center;
       
    }
    .cancel-btn{
        float:right;
        padding:8px 12px;
        color:#263859;
        background: transparent;
        border: 1px solid #263859;
        cursor:pointer;
        display: flex;
        padding: 8px 35px;
        justify-content: center;
        align-items:center;
       
    }
`;

const ModalDeactivate2FA = ({isOpen,onCLose,onConfirm}) => {
  return (
    <ConfirmDeactivateModal
      isOpen={isOpen}
      onRequestClose={onCLose}
      width="600"
    >
      <Heading marginBottom={"16px"} fontSize="2rem">
        Deactivate Two-Factor Authentication (2FA)
      </Heading>
      <ConfirmHeading >
      Are you sure you want to deactivate 2FA? Disabling this feature will remove the additional layer of security from your account. If you proceed, you’ll only need your password to log in.
      </ConfirmHeading>
      <ButtonRow style={{border: 'none'}}>
        <div>
          <Button
            size={"lg"}
            danger
            className="cancel-btn"
            btntype={"secondary"}
            width={"218px"}
            height={"60px"}
            textfont={"14px"}
            boxshadow={false}
            onClick={onCLose}
            type="button"
          >
            Cancel
          </Button>
          <Button
            size={"lg"}
            danger
            className="delete-btn"
            btntype={"secondary"}
            width={"218px"}
            height={"60px"}
            textfont={"14px"}
            boxshadow={false}
            onClick={onConfirm}
            type="button"
          >
            <span>Deactive 2FA</span>
          </Button>
        </div>
      </ButtonRow>
    </ConfirmDeactivateModal>
  )
}

export default ModalDeactivate2FA
