import React, { useEffect } from "react";
import { PackageCard } from "../PackageCard/PackageCard";
import * as Yup from "yup";
import { Formik, Form } from "formik";

import { Heading } from "../Heading";
import { Submit } from "../FormInputs/Submit";
import { connect } from "react-redux";
import { Select } from "./../FormInputs/Select/Select";
import { OnSubmitValidationError } from "../../utils/OnSubmitValidationError";
import { BackButton } from "../BackButton/BackButton";
import { SignupSection } from "./styled";
import { getAccountTypes } from "../../actions/accountTypeActions";
import { FlashError } from "../FormInputs/FlashError";
import { profileTypeOptions } from "../../utils/helpers";
import Skeleton from "react-loading-skeleton";

export const SignUpAccountTypeComponent = ({
  setAccountType,
  getAccountTypes,
  accountTypes,
  ...props
}) => {
  useEffect(() => {
    getAccountTypes();
  }, [getAccountTypes]);
  const handleSubmit = async (values, setSubmitting) => {
    setAccountType(
      accountTypes.find((accType) => accType.id === values.account_type_id)
    );
    setSubmitting(true);

    props.next(values);
  };

  const renderPacakges = (setStatus) => {
    return accountTypes?.map((plan, i) => (
      <PackageCard
        key={i}
        label={plan.label}
        name="account_type_id"
        amount={plan.total_amount}
        value={plan.id}
        textInfo={plan.description}
        handleOnClick={() => setStatus(null)}
      />
    ));
  };
  const updateProfileTypeOptions = profileTypeOptions.filter(
    (item) => item.value !== 1
  );
  return (
    <SignupSection>
      <div className="reset-arrow">
        <BackButton
          label={"Back"}
          handleOnClick={(values) => props.prev(values)}
        />
      </div>
      <Heading marginBottom="16px">
        <span>choose your account type</span>
      </Heading>
      <p className="instructions">
        Let us know the account type that applies to your business.
      </p>
      <Formik
        enableReinitialize
        initialValues={props.data}
        validationSchema={Yup.object({
          account_type_id: Yup.number().required(),
        })}
        onSubmit={(values, { setStatus, setSubmitting }) => {
          if (
            (values.account_type_id === 2 || values.account_type_id === 3) &&
            !values.profile_type_id
          ) {
            setStatus("Profile");
            setSubmitting(false);
            return;
          }
          handleSubmit(values, setStatus, setSubmitting);
        }}
      >
        {(props) => {
          const { setFieldValue, isSubmitting, values, setStatus, status } =
            props;
          const onSubmitValidationError = () => {
            setStatus("Account");
          };
          return (
            <Form>
              <div className="row">
                <div className="col xl-12">
                  {accountTypes.length ? (
                    renderPacakges(setStatus)
                  ) : (
                    <Skeleton count={5} duration={0.5} />
                  )}
                </div>

                {(values.account_type_id === 2 ||
                  values.account_type_id === 3) && (
                  <Select
                    label="Account Type "
                    required="true"
                    name="profile_type_id"
                    setFieldValue={setFieldValue}
                    className="col xl-12 uptid"
                    options={updateProfileTypeOptions}
                    placeholder={"Choose your Account Type"}
                    errorText={
                      values.account_type_id === 2 && status
                        ? `Profile type is required`
                        : null
                    }
                    handleOnMenuOpen={() => setStatus(null)}
                  />
                )}
                <div className="col xl-12 p-0">
                  {!values.account_type_id && status && (
                    <FlashError
                      heading={`* ${status} Type Required`}
                      text={`Please select ${status} type.`}
                      margin="10px 0px 40px 0px"
                    />
                  )}
                </div>
                <div className="col xl-12 p-0">
                  <Submit
                    type="submit"
                    widthExpand
                    marginTop={"40px"}
                    isSubmitting={isSubmitting}
                    text="Continue"
                    submittingText="Sending..."
                  />
                </div>
                <OnSubmitValidationError callback={onSubmitValidationError} />
              </div>
            </Form>
          );
        }}
      </Formik>
    </SignupSection>
  );
};

const mapStateToProps = (state) => {
  return {
    accountTypes: state.accountTypes.accountTypeData,
  };
};

export const SignUpAccountType = connect(mapStateToProps, { getAccountTypes })(
  SignUpAccountTypeComponent
);
