import React, { useState } from "react";
import { SearchTemplateStyled, SearchHeader } from "./styled";
import { Heading } from "../../components/Heading";
import { SideDraw } from "../../components/SideDraw";
import { TabNav } from "../../components/TabNav/TabNav";
import { SearchTagRow } from "../../components/SearchTagRow";
import { RefineResults } from "../../components/RefineResults";

export const SearchTemplate = ({
  resultsCount,
  compareCount,
  shortlistCount,
  searchCriteria,
  noDataTable,
  disciplines,
  types,
  sectors,
  shortlist,
  groupId,
  trades,
  specialities,
  ...props
}) => {
  const [refineOpenState, setRefineOpenState] = useState(false);

  const handleRefineOpenClick = () => {
    setRefineOpenState(!refineOpenState);
  };

  const tabs = [
    {
      link: "results",
      title: "Search Results",
      count: resultsCount,
    },
    {
      link: "compare",
      title: "Compare",
      count: compareCount,
    },
    {
      link: "shortlist",
      title: "Shortlist",
      count: shortlistCount,
    },
  ];

  return (
    <SearchTemplateStyled>
      <SearchHeader>
        <RefineResults
          handleClick={handleRefineOpenClick}
          open={refineOpenState}
          searchCriteria={searchCriteria}
          disciplines={disciplines}
          sectors={sectors}
          types={types}
          groupId={groupId}
          trades={trades}
          specialities={specialities}
        />

        <Heading size="xl">PRE-QUALIFICATION</Heading>

        <SearchTagRow
          searchCriteria={searchCriteria}
          disciplines={disciplines}
          sectors={sectors}
          types={types}
          open={refineOpenState}
          handleRefineButtonClick={handleRefineOpenClick}
          trades={trades}
          specialities={specialities}
        />

        <TabNav tabs={tabs} />

        <SideDraw shortlist={shortlist} />
      </SearchHeader>

      {props.children}
    </SearchTemplateStyled>
  );
};
