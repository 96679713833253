import styled from 'styled-components';

export const CompanyLocationsWrap = styled.div`
    h3{
        margin-top:${props=>props.theme.space.md};
        font-size:${props=>props.theme.fontSizes.md};
        color:${props=>props.theme.colors.grayDark};
    }
    .secondary-addresses{
        margin-top:${props=>props.theme.space.sm};
        border-top: 1px solid ${props=>props.theme.colors.snowDark};
    }
`