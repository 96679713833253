import React from "react";
import { Button } from "../../Button";
import { Heading } from "../../Heading";
import { Modal } from "../../Modal";
import { navigate } from "@reach/router";
import UpgradeAccountImage from "./upgrade-account.png";
import { ReactComponent as CheckIcon } from "../../../icons/check-circle.svg";
import { UpgradeAccountModalWrapper } from "./styled";

export const UpgradeAccountModal = ({ isOpen, setIsOpen, guestEmail }) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => setIsOpen(false)}
      width="926"
      closeIconTop="24px"
      blurBackground
    >
      <UpgradeAccountModalWrapper>
        <img src={UpgradeAccountImage} alt="header-img" />
        <div className="side-content">
          <Heading fontSize="48px" marginBottom="24px">
            Become a member to build your project team
          </Heading>
          <p>
            Register for a full Procuracon account. Providing you access to a
            variety of features.
          </p>
          <span>
            <CheckIcon /> Search compare & Shortlist suppliers instantly
          </span>
          <span>
            <CheckIcon /> Save time and money with our automated shortlist
            reports
          </span>

          <Button
            size="sm"
            type="button"
            width="204px"
            height="56px"
            textfont="14px"
            boxshadow={false}
            onClick={() =>
              navigate("/signup", {
                state: {
                  email: guestEmail,
                },
              })
            }
          >
            UPGRADE ACCOUNT
          </Button>
        </div>
      </UpgradeAccountModalWrapper>
    </Modal>
  );
};
