import React from 'react';

import { ButtonToggleStyled } from './styled';
import { Button } from '../Button';

export const ButtonToggle = (props) => {
    return (
        <ButtonToggleStyled>
            <Button
                btntype='secondary'
                alt
                disablePointer={!props.public}
                disabledStyle={props.public}
                size='sm'
                onClick={() => props.handleClick(props.documentId, 'private')}
            >
                Private
            </Button>

            <Button
                btntype='secondary'
                alt
                disablePointer={props.public}
                disabledStyle={!props.public}
                size='sm'
                onClick={() => props.handleClick(props.documentId, 'public')}
            >
                Public
            </Button>
        </ButtonToggleStyled>
    );
};
