import React from 'react';
import styled from 'styled-components';
import { ToastContainer, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

const WrappedToastContainer = ({ className, ...rest }) => (
    <div className={className}>
        <ToastContainer {...rest} />
    </div>
);

export const Toast = styled(WrappedToastContainer).attrs({
    transition: Slide,
    enableMultiContainer: true,
    autoClose: 3000,
    hideProgressBar: true,
})`
    .Toastify__toast-container {
    }

    .Toastify__close-button {
        top: -5px;
    }

    .Toastify__toast {
        padding: 1rem;

        &-body {
            display: flex;
            align-items: center;
            margin: 0;
            font-family: ${(props) => props.theme.fonts.sans_serif};
            font-weight: 500;
            color: white;
        }

        &--error {
            background-color: ${(props) => props.theme.colors.red};
        }

        &--warning {
            background-color: ${(props) => props.theme.colors.red};
        }

        &--success {
            background-color: ${(props) => props.theme.colors.mintDark};
        }
    }
`;
