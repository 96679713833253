import React, { useState } from 'react';
import 'react-dates/initialize';
import { SingleDatePicker } from 'react-dates';
import moment from 'moment';
import _get from 'lodash.get';

import { DateInputStyled } from './styled';
import { FormLabel } from '../TextInput';

export const DateInput = ({ label, name, values, setFieldValue, defaultValue, className, ...props }) => {
    const [isFocused, setIsFocused] = useState(false);

    return (
        <DateInputStyled style={{ position: 'relative' }} className={className}>
            {label && <FormLabel htmlFor={name}>{label}</FormLabel>}

            <SingleDatePicker
                date={_get(values, name) ? moment(_get(values, name)) : null} // momentPropTypes.momentObj or null
                onDateChange={(date) => {
                    date !== null && setFieldValue(name, date.format());
                }}
                onFocusChange={({ focused }) => setIsFocused(focused)}
                focused={isFocused}
                id={name}
                hideKeyboardShortcutsPanel
                displayFormat='DD/MM/YYYY'
                block
                placeholder=''
            />
        </DateInputStyled>
    );
};
