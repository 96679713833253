import React from 'react';
import styled from 'styled-components';

import { ReactComponent as ViewIcon } from '../../../icons/view.svg';
import { ReactComponent as LockIcon } from '../../../icons/lock.svg';
import { ReactComponent as UnlockIcon } from '../../../icons/unlock.svg';
import { ReactComponent as CrossIcon } from '../../../icons/cross.svg';
import { ReactComponent as RefineIcon } from '../../../icons/refine.svg';

import { theme } from '../../../theme';

const types = {
    view: theme.colors.white,
    lock: theme.colors.white,
    unlock: theme.colors.mintDark,
    remove: theme.colors.red,
    inverseRemove: theme.colors.royalBlue,
    refine: theme.colors.royalBlue
};

const icon = {
    view: {
        icon: <ViewIcon />,
        iconColor: theme.colors.royalBlue
    },
    lock: {
        icon: <UnlockIcon />,
        iconColor: theme.colors.mintDark
    },
    unlock: {
        icon: <LockIcon />,
        iconColor: theme.colors.white
    },
    remove: { icon: <CrossIcon />, iconColor: theme.colors.white },
    inverseRemove: { icon: <CrossIcon />, iconColor: theme.colors.white },
    refine: { icon: <RefineIcon />, iconColor: theme.colors.white }
};

const ControlButtonStyled = styled.button`
    width: ${(props) => (props.square? '145px' : props.xl ?'60px': '30px')};
    height: ${(props) => (props.square? '37px' : props.xl ? '60px':'30px')};
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: white;
    background: ${(props) => types[props.type]};
    border: none;
    border-radius: ${props=>props.radius? props.radius: '50%'};
    cursor: pointer;
    padding: 0;
    transition: ${(props) => props.theme.transitions.default};

    &::hover {
        background: ${(props) => (props.type === 'inverseRemove' ? theme.colors.red : props.theme.colors.royalBlue)};

        svg {
            color: white;
        }
    }

    &:focus {
        outline: none;
    }

    svg {
        width: ${(props) => (props.xl ? '23px' : '16px')};
        height: ${(props) => (props.xl ? '23px' : '15px')};
        display: block;
        margin: 0 auto;
        color: ${(props) => icon[props.type].iconColor};
        transition: ${(props) => props.theme.transitions.default};
    }
    .ref-title{
        font-size:10px;
        text-transform: uppercase;
        color:${props=>props.theme.colors.snow};
        font-weight:500;
        margin-left:8px;
    }
`;

export const ControlButton = ({ type, ...props }) => {
    return (
        <ControlButtonStyled type={type} {...props}>
            <span>{icon[type].icon}</span> { type==="refine" && <span className="ref-title">Refine Results</span>}
        </ControlButtonStyled>
    );
};

ControlButton.defaultProps = {
    type: 'view'
};
