import React from 'react';
import { Divider } from '../Divider';
import { Heading } from '../Heading';
import { TabHeaderStyled } from './styled';

export const TabHeader = ({ heading }) => {

    return (
        <TabHeaderStyled>
            <Heading fontSize='2rem'>{heading}</Heading>
            <Divider top="20px" bottom="40px" />
        </TabHeaderStyled>
    );
};
