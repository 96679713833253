import axios from 'axios';
import { toast } from 'react-toastify';
axios.defaults.withCredentials = true;
axios.defaults.baseURL = process.env.REACT_APP_API_URL;
const serverUrl = process.env.REACT_APP_API_URL;
const apiV2 = axios.create({
    baseURL: serverUrl,
    withCredentials: true,
    timeout: 120000, // Milliseconds
});
apiV2.interceptors.request.use((config) => {
    const authToken = localStorage.getItem('access_token');
    if (authToken) {
        config.headers.Authorization = `Bearer ${authToken}`;
    }
    return config;
}, (error) => {
    return Promise.reject(error);
});

apiV2.interceptors.response.use(
    function (response) {
        return response;
    },
    function (error) {
        if (error?.response) {
            switch (error?.response?.status) {
                case 404:
                    toast.error(error?.message);
                    break;
                case 403:
                    let errorMessage = error?.message
                    if (errorMessage !== "This action is unauthorized.") {
                        errorMessage = "This action is unauthorized."
                    }
                    toast.error(errorMessage)
                    setTimeout(() => {
                        window.location.pathname = "/";
                    }, 1000)
                    break;
                default:
                    return Promise.reject(error);
            }
        }
        return Promise.reject(error);
    }
);

axios.interceptors.response.use(
    (response) => response,
    (error) => {
        const unauthorized = [401, 419];
        if (unauthorized.includes(error.response?.status)) {
            if (!localStorage.getItem('twoFactorRequired') || !localStorage.getItem('access_token')) {
                toast.error('Please try and sign in again.', {
                    toastId: 'session_expired',
                    heading: 'Sorry, your session has expired.',
                });

                localStorage.removeItem('access_token');

                if (window.location.pathname !== '/') {
                    if (window.location.pathname !== '/logout') {
                        localStorage.setItem('returnTo', window.location.pathname);
                    }
                    window.location.href = '/';
                }
            }
        } else if (error.response?.status === 500) {
            toast.error('Sorry, there was an error processing your request', {
                toastId: 'error',
            });
            return;
        } else {
            return Promise.reject(error);
        }
    }
);

// export const getCsrfCookie = async () => {
//     await axios.get(`${process.env.REACT_APP_API_BASE_URL}/sanctum/csrf-cookie`);
// };


export default apiV2;