import React from 'react';
import { Field } from 'formik';

import { RepeaterButton } from '../../RepeaterButton';
import { ResourceProjectsStyled, ProjectItemsStyled, ProjectItemStyled } from './styled';
import { DocumentItem } from '../../DocumentItem';
import { DocumentControls } from '../../DocumentControls';

export const ResourceProjects = ({
    projects,
    setFieldValue,
    className,
    handleAddClick,
    handleEditClick,
    handleDeleteClick,
    ...props
}) => {
    return (
        <ResourceProjectsStyled className={className}>
            <Field
                name='projects_completed'
            >
                {({field, form, meta}) => 
                    <div>
                        <RepeaterButton text='Add Project Experience' handleAddClick={handleAddClick} />

                        <ProjectItemsStyled>
                            {projects &&
                                Array.isArray(projects) &&
                                projects.map((project, index) => {
                                    return (
                                        <ProjectItemStyled key={index}>
                                            <DocumentItem
                                                document={project}
                                                editable
                                            >
                                                <DocumentControls
                                                    item={index}
                                                    onEdit={handleEditClick}
                                                    onDelete={() => handleDeleteClick(index)}
                                                />
                                            </DocumentItem>
                                        </ProjectItemStyled>
                                    );
                                })}
                        </ProjectItemsStyled>
                    </div>
                }
            </Field>
        </ResourceProjectsStyled>
    );
};
