import { checkAccessToken } from 'actions/authActions';
import ky from 'ky';
import { toast } from 'react-toastify';


export default ky.extend({
  prefixUrl: process.env.REACT_APP_API_URL,
  hooks: {
    beforeRequest: [
      request => {
        if (token) {
          request.headers.set("Authorization", `Bearer ${token}`);
        }
      }
    ],
    afterResponse: [
      async (request, options, response) => {
        if (!response.ok) {
          // Handle specific errors or status codes here
          const error = await response.json();
          switch (response?.status) {
            case 404:
              toast.error(error?.message)
              break;
            case 403:
              await checkAccessToken().then(status => {
                if(!status){
                    localStorage.clear();
                    window.location.href = '/login';
                }
              })
              break;
            default:
              return error;
          }
        }
      }
    ]
  }
});


const token = localStorage.getItem("access_token") ? localStorage.getItem("access_token") : '';
const Clientweb = ky.extend({
  hooks: {
    beforeRequest: [
      (request) => {
        request.headers.set("Authorization", `Bearer ${token}`);
      },
    ],
    afterResponse: [
      async (request, options, response) => {
        if (!response.ok) {
          // Handle specific errors or status codes here
          const error = await response.json();
          switch (error?.status) {
            case 404:
              toast.error(error?.error)
              break;
            case 403:
                await checkAccessToken().then(status => {
                    if(!status){
                        localStorage.clear();
                        window.location.href = '/login';
                    }
                  })
              break;
            default:
              return error;
          }
        }
      }
    ]
  },
  prefixUrl: process.env.REACT_APP_API_URL
})
export { Clientweb }

