import React from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";

import { FormContainer, Divider } from "../FormContainer";
import { Sectors } from "components/FormInputs/Sectors";
import { Submit } from "components/FormInputs/Submit";
import { ProfileType } from "components/FormInputs/ProfileType";
import { CompanySize } from "components/FormInputs/CompanySize";
// import { CompanySearch } from "../FormInputs/CompanySearch";
import { Region } from "../FormInputs/Region";
import { ExpertiseDisciplines } from "../FormInputs/ExpertiseDisciplines";
import { Specialities } from "components/FormInputs/Specialities";
import { Trades } from "../FormInputs/Trades";

export const SupplierSearchForm = ({
  searchCompanies,
  projectId,
  ...props
}) => {
  const initialValues = {
    location: "",
    sector_id: "",
    size: "",
    expertise: "",
    discipline: "",
    specialities: "",
    trades: "",
    profile_type: "",
    company_id: "",
    project_id: projectId,
  };

  const handleSearchSubmit = (values) => {
    if (values.trades?.length === 0) {
      delete values["trades"];
    }
    if (values.specialities?.includes(6)) {
      values.specialities = [1, 2, 3, 4, 5];
    }
    searchCompanies(values);
  };

  return (
    <FormContainer>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={(values) => handleSearchSubmit(values)}
        validationSchema={() => {
          return Yup.object({
            location: Yup.string().required(),
            sector_id: Yup.number().required(),
            profile_type: Yup.number().required(),
            size: Yup.string().required(),
            // don't enforce validation on these fields if profile_type is subcontractor (id 2)
            expertise: Yup.string().when("profile_type", {
              is: (profile_type) => profile_type === 4,
              then: Yup.string().required(),
            }),
            discipline: Yup.string().when("profile_type", {
              is: (profile_type) => profile_type === 4,
              then: Yup.string().required(),
            }),
            trades: Yup.string().when("profile_type", {
              is: (profile_type) => profile_type === 3,
              then: Yup.string().required(),
            }),
            specialities: Yup.string().when("profile_type", {
              is: (profile_type) => profile_type === 2 || profile_type === 3,
              then: Yup.string().required(),
            }),
          });
        }}
      >
        {(props) => {
          const { isSubmitting, values, setFieldValue } = props;

          const handleProfileTypeChange = (data, field) => {
            console.log("checkingFormik", values);
            setFieldValue(field.name, data.value);
            setFieldValue("expertise", "");
            setFieldValue("discipline", "");
            setFieldValue("size", "");
            if (values.hasOwnProperty("trades")) {
              setFieldValue("trades", []);
            }
            if (values.hasOwnProperty("specialities")) {
              setFieldValue("specialities", []);
            }
          };

          return (
            <Form>
              <div className="row" mb="xl">
                <Region name="location" label="Location" className="col xl-6" />

                <Sectors
                  label="Sector"
                  required="false"
                  name="sector_id"
                  defaultValue={initialValues.sector_id}
                  values={values}
                  singleValue
                  className="col xl-6"
                />

                <ProfileType
                  name="profile_type"
                  label="Type"
                  className="col xl-6"
                  hideClient
                  required
                  onChange={handleProfileTypeChange}
                />

                <CompanySize
                  name="size"
                  required="false"
                  className="col xl-6"
                  label="Size"
                  value={values.size}
                  profileType={values.profile_type}
                  filter
                  noOptionsMessage="Please select a supplier type to load options"
                />

                {(values.profile_type === 2 || values.profile_type === 3) && (
                  <Specialities profileType={values.profile_type} required />
                )}

                {/* subcontractor */}
                {values.profile_type === 3 && (
                  <Trades required profileType={values.profile_type} />
                )}

                {/* consultant */}
                {values.profile_type === 4 && (
                  <ExpertiseDisciplines
                    label="Services provided by company"
                    name="discipline"
                    values={values}
                    className="col xl-12"
                    profileType={values.profile_type}
                    required
                  />
                )}

                <Divider />

                {/* <CompanySearch
                  name="company_id"
                  label="Search by company (optional)"
                  className="col xl-12"
                /> */}
              </div>

              <Submit
                type="submit"
                width="100%"
                isSubmitting={isSubmitting}
                text="Search"
                submittingText="Searching..."
                mt="xs"
              />
            </Form>
          );
        }}
      </Formik>
    </FormContainer>
  );
};
