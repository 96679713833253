import React, { useState } from "react";
import * as Yup from "yup";
import { Formik, Form } from "formik";

import { Heading } from "../Heading";
import { TextInput } from "../FormInputs/TextInput";
import { Submit } from "../FormInputs/Submit";
import { connect } from "react-redux";

import { OnSubmitValidationError } from "../../utils/OnSubmitValidationError";
import { BackButton } from "../BackButton/BackButton";
import { ReactComponent as InfoIcon } from "../../icons/info.svg";
import cardsImage from "./maxresdefault.jpg";

import {
  BottomLinks,
  SignupSection,
  PaymentFormWrapper,
  FormLabel,
  AmountLabel,
  SubsLabel,
} from "./styled";
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";
import { FlashError } from "../FormInputs/FlashError";
import { completeRegistration, applyDiscount } from "../../actions/authActions";
import { ButtonPill } from "../ButtonPill/ButtonPill";
import ReactTooltip from "react-tooltip";

export const SignUpPaymentDetailsComponent = ({ accountType, ...props }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [discountApplied, setDiscountApplied] = useState(false);
  const [loading, setLoading] = useState(false);
  const [discount, setDiscount] = useState(0);
  const user_id = props.user.id ? props.user.id : null;

  const CardStyle = {
    base: {
      fontSize: "18px",
      color: "#6F7C8F",
      border: "1px solid rgba(132, 146, 166, 0.2)",
      "::placeholder": {
        color: "#6F7C8F",
      },
    },
    invalid: {
      color: "#6F7C8F",
    },
    complete: {
      color: "#6F7C8F",
    },
  };

  const handleSubmit = async (values, setStatus, setSubmitting) => {
    setSubmitting(true);
    setStatus(null);
    if (!stripe || !elements) {
      setSubmitting(false);
      return;
    }
    const cardNumber = elements.getElement(CardNumberElement);

    const token = await stripe.createToken(cardNumber, {
      name: values.card_name,
    });
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardNumber,
      billing_details: {
        name: token.name,
        address: {
          city: token.address_city,
          country: token.country,
          postal_code: token.address_zip,
          state: token.address_state,
        },
      },
    });
    if (error) {
      if (errorMessages.hasOwnProperty(error.code)) {
        setStatus(error.code);
      } else {
        setStatus(error.message);
      }
    } else {
      try {
        values.payment_method = paymentMethod;
        values.token_id = token.token.id;
        values.id = user_id;
        const result = await props.completeRegistration(values, setSubmitting);
        if (result.success) props.next(values);
        else {
          if (result.message === "Your card was declined.") {
            setStatus("card_declined");
          } else if (result.message === "Your card has expired.") {
            setStatus("invalid_expiry_year_past");
          } else if (result.message === "Your card has insufficient funds.") {
            setStatus("insufficient_fund");
          } else if (result.message === "Your card's security code is incorrect.") {
            setStatus("card_security");
          } else if (result.message === "Your card was declined for making repeated attempts too frequently or exceeding its amount limit.") {
            setStatus("card_limit");
          }else if (result.message === "An error occurred while processing your card. Try again in a little bit.") {
            setStatus("card_processing");
          }else if (result === 404) {
            setStatus("not_found");
          } else {
            setStatus("backend");
          }
        }
      } catch (e) {
        const error = await e;
        console.log(error.message);
        setSubmitting(false);
      }
    }
    setSubmitting(false);
  };

  const applyCoupon = async (coupon, setStatus) => {
    setLoading(true);
    setStatus(null);
    if (!coupon) {
      setStatus("coupon");
      setLoading(false);
      return;
    }
    const result = await props.applyDiscount(coupon);
    if (result.success) {
      setDiscount(result.data.amount);
      setDiscountApplied(true);
    } else {
      setStatus("coupon");
    }
    setLoading(false);
  };
  const tooltips = {
    card_name:
      "This field should contain the cardholder's name exactly as it's printed on the card. If you need assistance, please email us at support@procuracon.com.au",
  };
  return (
    <SignupSection>
      <div className="reset-arrow">
        <BackButton
          label={"Back"}
          handleOnClick={(values) => props.prev(values)}
        />
      </div>
      <Heading marginBottom="16px">
        <span>your payment details</span>
      </Heading>
      <p className="instructions">Please enter your payment details below</p>
      <p className="instructions">
        We do not store credit card details on our servers. All transactions are
        securely processed by Stripe, a trusted payment gateway. If you have any
        concerns or questions, please don't hesitate to reach out to us at
        <a className="support-link" href="mailto:support@procuracon.com.au"> support@procuracon.com.au</a>

      </p>

      <Formik
        enableReinitialize
        initialValues={props.data}
        validationSchema={Yup.object({
          card_name: Yup.string()
            .trim()
            .matches(/^[a-zA-Z\s'-]+$/, "Name on card is not valid")
            .matches(
              /^(?=.*[a-zA-Z])[\w\s'-]*$/,
              "Name on card should only contain alphabetic characters (A-Z, a-z), spaces, and appropriate special characters (e.g., hyphens, apostrophes)"
            )
            .max(255, "Name on card should not exceed 255 characters")
            .required(),
          discount_code: Yup.string()
            .trim()
            .max(20, "Discount code must not exceed 20 characters")
            .matches(
              /^[a-zA-Z0-9!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]*$/,
              "Discount code is not valid"
            ),
        })}
        onSubmit={(values, { setStatus, setSubmitting }) => {
          handleSubmit(values, setStatus, setSubmitting);
        }}
      >
        {(props) => {
          const { setFieldValue, isSubmitting, status, values, setStatus } =
            props;
          const onSubmitValidationError = () => {
            setStatus("required");
          };
          return (
            <Form>
              <div className="row">
                <div className="col xl-12">
                  <AmountLabel>
                    <p>Pay Annually</p>
                    <span>
                      ${accountType?.total_amount - discount}
                      <span className="sub"> ex GST</span>
                    </span>
                  </AmountLabel>
                </div>
                <TextInput
                  label="NAME ON CARD"
                  placeholder="Enter Your Name"
                  name="card_name"
                  type="text"
                  required
                  className="col xl-12 text-mr"
                  setFieldValue={setFieldValue}
                  guideLine={tooltips.card_name}
                />
                <PaymentFormWrapper>
                  <div className="col xl-12">
                    <FormLabel>
                      <div className="card-left-content">
                        Credit Card Number&nbsp;<span className="required">*</span> 
                        <div className="guideline">
                          <InfoIcon data-tip data-for="creditCardTooltip" />
                          <ReactTooltip
                            className="guide"
                            id="creditCardTooltip"
                            place="right"
                            arrowColor="transparent"
                          >
                            Please enter a 13 to 19-digit number without spaces
                            or dashes. If you continue to experience issues,
                            feel free to contact us at support@procuracon.com.au
                          </ReactTooltip>
                        </div>
                      </div>
                      <div>
                        <img src={cardsImage} alt="Description of the card" />
                      </div>
                    </FormLabel>
                    <div className="card-number">
                      <CardNumberElement
                        className="stripe_card_field"
                        options={{
                          showIcon: true,
                          placeholder: "xxxx xxxx xxxx xxxx",
                          style: CardStyle,
                          disabled: isSubmitting,
                        }}
                      />
                    </div>
                  </div>

                  <div className="row-fields">
                    <div className="block-field">
                      <FormLabel>
                      <div className="card-left-content">
                        Expiry Date&nbsp;<span className="required">*</span>
                        <div className="guideline">
                          <InfoIcon data-tip data-for="expiryDateTooltip" />
                          <ReactTooltip
                            className="guide"
                            id="expiryDateTooltip"
                            place="right"
                            arrowColor="transparent"
                          >
                            Please check and enter a valid expiry date that is
                            in the future. If you continue to experience issues,
                            please email us at support@procuracon.com.au for
                            further assistance.
                          </ReactTooltip>
                        </div>
                      </div>

                      </FormLabel>
                      <div className="card-number">
                        <CardExpiryElement
                          className="stripe_card_field"
                          options={{
                            placeholder: "mm / yy",
                            style: CardStyle,
                            disabled: isSubmitting,
                          }}
                        />
                      </div>
                    </div>
                    <div className="block-field">
                      <FormLabel>
                      <div className="card-left-content">
                        CVV&nbsp;<span className="required">*</span>
                        <div className="guideline">
                          <InfoIcon data-tip data-for="cvvTooltip" />
                          <ReactTooltip
                            className="guide"
                            id="cvvTooltip"
                            place="right"
                            arrowColor="transparent"
                          >
                            Please ensure CVV number is the 3-digit number found
                            on the back of your card. If you continue to
                            experience issues, please email us at
                            support@procuracon.com.au for further assistance.
                          </ReactTooltip>
                        </div>                        
                      </div>

                      </FormLabel>
                      <div className="card-number">
                        <CardCvcElement
                          className="stripe_card_field"
                          options={{
                            placeholder: "xxx",
                            style: CardStyle,
                            disabled: isSubmitting,
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </PaymentFormWrapper>
                <TextInput
                  label="Discount Code"
                  placeholder="Enter Coupon"
                  name="discount_code"
                  type="text"
                  className="col xl-10 text-mr"
                  setFieldValue={setFieldValue}
                  disabled={discountApplied}
                />
                <ButtonPill
                  color="white"
                  type="button"
                  style={{
                    alignSelf: "center",
                    background: "#0045F5",
                    padding: "10px 20px",
                  }}
                  onClick={() => applyCoupon(values.discount_code, setStatus)}
                  disabled={discountApplied || loading}
                >
                  {discountApplied ? "Applied" : "Apply"}
                </ButtonPill>
                <BottomLinks className="col xl-12">
                  <SubsLabel>
                    <span>
                      Click 'Agree & Subscribe' to start your subscription. Your
                      first payment will be charged right away, and we'll
                      automatically bill you annually. Cancel anytime, with the
                      cancellation taking effect at the end of the current
                      payment period. No refunds or credits for partial
                      subscription periods.
                    </span>
                  </SubsLabel>
                </BottomLinks>
                <div className="col xl-12">
                  {status && (
                    <FlashError
                      heading={
                        errorMessages[status]?.heading ||
                        "Invalid Card Information"
                      }
                      text={errorMessages[status]?.description || status}
                      margin="20px 0px 0px 0px"
                    />
                  )}
                </div>
                <div className="col xl-12">
                  <Submit
                    type="submit"
                    widthExpand
                    isSubmitting={isSubmitting}
                    text="AGREE & SUBSCRIBE"
                    marginTop="26px"
                    submittingText="Sending..."
                  />
                </div>
              </div>
              <OnSubmitValidationError callback={onSubmitValidationError} />
            </Form>
          );
        }}
      </Formik>
    </SignupSection>
  );
};

const errorMessages = {
  required: {
    heading: "* Required Field",
    description: "There are incomplete required fields, Please complete them.",
  },
  backend: {
    heading: "Request Not Processed",
    description:
      "We apologise for the inconvenience. The payment gateway is currently offline and unable to process card details. Please try again in a few minutes. If you continue to experience difficulties, please email us at support@procuracon.com.au",
  },
  insufficient_fund:{
    heading: "Insufficient Funds",
    description:
      "Your card has insufficient funds.",
  },
  coupon: {
    heading: "Invalid Coupon",
    description: "Please enter valid discount code",
  },
  invalid_number: {
    heading: "Invalid Card Number",
    description:
      "The 'Credit Card Number' field appears to be missing a valid credit card number. Please enter a 13 to 19-digit number without spaces or dashes. If you continue to experience issues, feel free to contact us at support@procuracon.com.au",
  },
  incomplete_number: {
    heading: "Incomplete Card Number",
    description:
      "The 'Credit Card Number' field appears to be missing a valid credit card number. Please enter a 13 to 19-digit number without spaces or dashes. If you continue to experience issues, feel free to contact us at support@procuracon.com.au",
  },
  invalid_expiry_year_past: {
    heading: "Invalid Expiry Date",
    description:
      "The expiry date provided for the credit card appears to be in the past. Please check and enter a valid expiry date that is in the future. If you continue to experience issues, please email us at support@procuracon.com.au for further assistance.",
  },
  invalid_expiry_month_past: {
    heading: "Invalid Expiry Date",
    description:
      "The expiry date provided for the credit card appears to be in the past. Please check and enter a valid expiry date that is in the future. If you continue to experience issues, please email us at support@procuracon.com.au for further assistance.",
  },
  incomplete_cvc: {
    heading: "Invalid CVC",
    description:
      "The CVV number you've entered is invalid. Please ensure it is the 3-digit number found on the back of your card. If you continue to experience issues, please email us at support@procuracon.com.au for further assistance.",
  },
  card_declined: {
    heading: "Card Declined",
    description:
      "We're sorry, but your credit card transaction has been declined. Please double-check your card details or try using a different card. If the issue persists, please contact your card issuer for more information or assistance. If you continue to experience difficulties on our end, please email us at support@procuracon.com.au",
  },
  not_found: {
    heading: "Record Not Found",
    description:
      "We're sorry, Your session has expired. Please refresh the page and start again.If you continue to experience difficulties, please email us at support@procuracon.com.au",
  },
  card_security: {
    heading: "Card's security code is incorrect.",
    description: "Your card's security code is incorrect."
  },
  card_limit: {
    heading: "Card exceeding amount limit",
    description: "Your card was declined for making repeated attempts too frequently or exceeding its amount limit."
  },
  card_processing:{
    heading: "An error occurred",
    description: "An error occurred while processing your card. Try again in a little bit."
  }
  
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.signup_details,
  };
};

export const SignUpPaymentDetails = connect(mapStateToProps, {
  completeRegistration,
  applyDiscount,
})(SignUpPaymentDetailsComponent);
