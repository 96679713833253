import React from 'react';
import styled from 'styled-components';
import { Link } from '@reach/router';
import { ControlButton } from '../CompanyResultCard/ControlButton';

export const CompanyCardStyled = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;

    .company-card {
        &__inner {
            margin-bottom: ${(props) => props.theme.space.sm_2};
            border: 2px solid ${(props) => props.theme.colors.snowDark};
            box-shadow: 0px 4px 44px #edeef0;
            border-radius: ${(props) => props.theme.radii.default};
            padding-bottom: ${(props) => props.theme.space.sm_2};
            height: 100%;
        }

        &__header {
            margin-bottom: ${(props) => props.theme.space.xxl};
            position: relative;
        }

        &__subtitle {
            text-align: center;
            font-size: ${(props) => props.theme.fontSizes.sm};
            color: ${(props) => props.theme.colors.grayLight};
            margin: 0;
        }

        &__content {
            padding: ${(props) => props.theme.space.sm_2};
        }
    }
`;

export const CompanyCardTitle = styled(({ linkNewTab, children, ...props }) => {
    let LinkComponent = linkNewTab ? 'a' : Link;
    return (
        <LinkComponent target={linkNewTab ? '_blank' : '_self'} href={linkNewTab ? props.to : ''} {...props}>
            {children}
        </LinkComponent>
    );
})`
    text-align: center;
    font-size: ${(props) => props.theme.fontSizes.xxl};
    display: block;
    font-weight: 500;
    color: ${(props) => props.theme.colors.navy};
    line-height: 1.25;
    margin: 0 0 0.75rem;
    padding: 0 1rem;

    &::hover {
        color: ${(props) => props.theme.colors.royalBlue};
    }
`;

export const RemoveButton = styled(ControlButton)`
    position: absolute;
    top: ${(props) => props.theme.space.xs};
    right: ${(props) => props.theme.space.xs};
    z-index: 2;
`;
