import React from "react";
import styled from "styled-components";
import { Link } from "@reach/router";

import { theme } from "../../theme";

export const TabsStyled = styled.div`
  display: flex;
  border-bottom: 1px solid ${(props) => props.theme.colors.grayBorder};
  margin-bottom: ${(props) => props.theme.space.sm};
  position: sticky;
  top: 0;
  background-color: ${(props) =>
    props.bg ? props.bg : props.theme.colors.snow};
  z-index: 10;

  ${theme.mediaQueries.xxl} {
    ${"" /* margin-bottom: ${(props) => props.theme.space.lg}; */}
  }
`;

export const TabStyled = styled((props) => {
  const isCurrent = ({ isPartiallyCurrent }) => {
    if (isPartiallyCurrent) {
      return {
        style: {
          color: theme.colors.royalBlue,
          borderColor: theme.colors.royalBlue,
        },
      };
    }
  };

  return (
    <Link getProps={isCurrent} {...props}>
      {props.children}
    </Link>
  );
})`
  padding: ${(props) => props.theme.space.xs} ${(props) => props.theme.space.xs};
  color: ${(props) =>
    props.active ? props.theme.colors.royalBlue : props.theme.colors.grayDark};
  font-weight: 700;
  border-bottom: 2px solid
    ${(props) => (props.active ? props.theme.colors.royalBlue : "transparent")};
  transition: ${(props) => props.theme.transitions.default};
  font-size: ${(props) => props.theme.fontSizes.lg};
  margin-right: ${(props) => props.theme.space.xxs};
  &:last-of-type {
    margin-right: 0;
  }

  &::hover {
    color: ${(props) => props.theme.colors.royalBlue};
    border-color: ${(props) => props.theme.colors.royalBlue};
  }
`;

export const TabContent = styled.div`
  padding-left: 48px;
  padding-right: 48px;
  padding-bottom: 48px;
  ${(props) =>
    props.padding &&
    `
        padding: ${props.padding};
    `}
  ${(props) =>
    props.noPadding &&
    `
        padding: 0px;
    `}
    .guidance-note {
    text-align: right;
    p {
      margin: 0;
    }
  }
`;
