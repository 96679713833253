import React from 'react';
import styled from "styled-components";

import { ReactComponent as LockIcon } from '../../icons/lock.svg';

export const AccessRequestStyled = styled.div`
    display: flex;
    align-items: center;
    border: 1px solid ${props => props.theme.colors.snowDark};
    color: ${props => props.theme.grayLight};
    font-weight: 500;
    font-size: ${props => props.theme.fontSizes.xs};
    background-color: white;
    padding: 0.3rem 0.6rem;
    border-radius: ${props => props.theme.radii.default};

    button {
        color: ${props => props.theme.colors.royalBlue};
        display: inline-block;
        margin-left: 0.4rem;
        background: transparent;
        border: none;
        font-weight: inherit;
        padding: 0;
        cursor: pointer;
        transition: ${props => props.theme.transitions.default};

        &::hover {
            color: ${props => props.theme.colors.mintDark};
        }
    }

    svg {
        width: 16px;
        height: 16px;
        display: inline-block;
        margin-right: 0.3rem;

        path {
            stroke: ${props => props.theme.colors.grayLight};
        }
    }
`;

export const AccessRequest = props => {
    return (
        <AccessRequestStyled>
            <LockIcon />
            This document is locked. <button>Request access</button>
        </AccessRequestStyled>
    );
};
