import React, { useEffect, useRef, useState } from "react";
import { useField } from "formik";
import ReactTooltip from "react-tooltip";
import styled, { css } from "styled-components";
import { ReactComponent as OpenEye } from "./../../../icons/eye.svg";
import { ReactComponent as CloseEye } from "./../../../icons/close-eye.svg";
import { ButtonWithIcon } from "../../Button";
import { Error } from "../Error";
import { ReactComponent as Pencil } from "./../../../icons/pencil.svg";

import { ReactComponent as InfoIcon } from "./../../../icons/info.svg";

export const FormLabel = styled.label`
  font-size: ${(props) => props.theme.fontSizes.xs};
  color: ${(props) => props.theme.colors.grayLight};
  letter-spacing: 1.25px;
  text-transform: uppercase;
  font-weight: 700;
  display: flex;
  flex-direction: row;
  margin-bottom: ${(props) => props.theme.space.xxs};

  > span {
    color: #dc2538;
    margin-left: 3px;
  }

  > svg {
    margin-left: 4px;
    cursor: pointer;
  }
  .guideline {
    svg {
      position: inherit;
      margin-left: 5px;
    }
  }
  .guide {
    border: 0.5px solid #8492a6;
    border-radius: 4px;
    letter-spacing: 0.1px;
    font-family: ${(props) => props.theme.fonts.open_sauce_two};
    font-size: 13px;
    text-transform: none;
    padding: 4px 8px;
    font-weight: normal;
    max-width: 400px;
  }
`;

export const TextInputStyles = css`
  background: white;
  border: 1px solid #e7eaee;
  border-radius: 5px;
  padding: 1rem;
  width: 100%;
  margin-bottom: ${(props) =>
    props.marginBottom ? props.marginBottom : "1.75rem"};
  margin-top: ${(props) => (props.marginTop ? props.marginTop : "0px")};
  color: ${(props) => props.theme.colors.gray};
  transition: ${(props) => props.theme.transitions.default};
  height: 60px;
  ::-webkit-input-placeholder {
    color: #6f7c8f;
    font-size: ${(props) =>
      props.placeholderSize ? props.placeholderSize : props.theme.fontSizes.lg};
  }
  :disabled {
    cursor: not-allowed;
    &:hover,
    &:focus {
      background-color: #d7d7d7;
    }
  }

  &:focus,
  &::hover {
    outline: none;
    border-color: ${(props) => props.theme.colors.royalBlueLight};
  }
`;

export const TextInputStyled = styled.input`
  ${TextInputStyles}
`;

export const TextInputWrapper = styled.div`
  position: relative;
  transition: 0.15s all ease;
  .change-password {
    position: absolute;
    right: 32px;
    top: 35px;
  }
  .svg-icon {
    position: absolute;
    right: 30px;
    top: 40px;
    cursor: pointer;
  }

  ${(props) =>
    props.error &&
    `
        padding-bottom: 0.5rem;
        border:${props.theme.colors.danger};
        input{
            border:1px solid ${props.theme.colors.danger};
        }
    `}
  flex-grow:${(props) => (props.flexGrow ? props.flexGrow : 0)};
`;
export const TextInput = ({
  label,
  placeholderSize,
  required,
  placeholder,
  className,
  icon,
  type,
  formatValue,
  disableToggler,
  errorText,
  guideLine,
  ...props
}) => {
  const [field, meta] = useField(props);
  const [visible, setVisible] = useState(false);
  const [pHolder, setPHolder] = useState(placeholder);
  const inputElement = useRef();
  const [toggleButton, setToggleButton] = useState(
    disableToggler || props.disabled ? true : false
  );

  useEffect(() => setToggleButton(props.disabled), [props.disabled]);
  const onKeyUp = (e) => {
    if (formatValue) {
      let val = e.target.value;
      let key = e.keyCode || e.charCode;
      if (key === 8) return;
      val = formatValue(val);
      props.setFieldValue(props.name, val);
    }
  };
  return (
    <TextInputWrapper
      error={(meta && meta.error && meta.touched) || errorText}
      flexGrow={props.flexGrow}
      className={className}
    >
      {label && (
        <FormLabel htmlFor={props.id || props.name}>
          {label}
          {required && <span>*</span>}
          {guideLine && (
            <div className="guideline">
              <InfoIcon data-tip data-for={props.name} />
              <ReactTooltip
                className="guide"
                id={props.name}
                place="right"
                arrowColor="transparent"
              >
                {guideLine}
              </ReactTooltip>
            </div>
          )}
        </FormLabel>
      )}
      {disableToggler && toggleButton && (
        <div className="change-password">
          <ButtonWithIcon
            icon={<Pencil />}
            text={"CHANGE"}
            btntype={"secondary"}
            width={"8rem"}
            onClick={() => {
              props.handleToggler(!toggleButton);
              setToggleButton(!toggleButton);
              setPHolder("");
              inputElement.current.focus();
            }}
          />
        </div>
      )}

      {icon && !toggleButton ? (
        !visible ? (
          <OpenEye onClick={() => setVisible(true)} className="svg-icon" />
        ) : (
          <CloseEye onClick={() => setVisible(false)} className="svg-icon" />
        )
      ) : null}
      <TextInputStyled
        ref={inputElement}
        onKeyUp={onKeyUp}
        placeholder={props?.fixedPlaceholder ? placeholder : pHolder}
        placeholderSize={placeholderSize}
        type={icon && visible ? "text" : type}
        {...field}
        {...props}
      />
      <Error meta={meta} text={errorText} />
    </TextInputWrapper>
  );
};
