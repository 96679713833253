import * as constants from '../actions/actionTypes';

const INITIAL_ACCOUNT_TYPE_STATE = {
    transactionData: [],
    companyUsers: []
};

export const accountManagementReducer = (state = INITIAL_ACCOUNT_TYPE_STATE, action) => {
    switch (action.type) {
        case constants.TRANSACTION_DETAILS:
            return {
                ...state,
                transactionData: action.payload
            };
        case constants.GET_COMPANY_USERS:
            return {
                ...state,
                companyUsers: action.payload
            };
        case constants.USER_DRAFT:
            return {
                ...state,
                usersDraft: action.payload
            };
        default:
            return state;
    }
};
