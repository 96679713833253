import { HeaderCollumn, HeaderRow } from "components/CustomTable";
import React from "react";

const InvoiceHeaderRow = () => {
  return (
    <HeaderRow className="section-header">
      <HeaderCollumn className="header-cell md-1 first-item">
        <span>Invoice Number</span>
      </HeaderCollumn>
      <HeaderCollumn className="header-cell md-2">
        <span>date</span>
      </HeaderCollumn>
      <HeaderCollumn className="header-cell md-3">
        <span>subscription</span>
      </HeaderCollumn>
      <HeaderCollumn className="header-cell md-3">
        <span>amount</span>
      </HeaderCollumn>
      <HeaderCollumn className="header-cell md-3">
        <span>transaction status</span>
      </HeaderCollumn>
    </HeaderRow>
  );
};

export default InvoiceHeaderRow;
