import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import LogRocket from "logrocket";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import configureStore from "./configureStore";

const store = configureStore();

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);

if (module.hot) {
  module.hot.accept("./App", () => {
    ReactDOM.render(
      <Provider store={store}>
        <App />
      </Provider>,
      document.getElementById("root")
    );
  });
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

console.info("[LogRocket]", 'Initialised "qlpwa6/procuracon"');
LogRocket.init("qlpwa6/procuracon");

// userback code
const Userback = window.Userback || {};
Userback.access_token = "10882|22399|rtG9AvxiLE9HeYKBKruB5db8d";
(function (d) {
  var s = d.createElement("script");
  s.async = true;
  s.src = "https://static.userback.io/widget/v1.js";
  (d.head || d.body).appendChild(s);
})(document);
