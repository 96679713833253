import React from 'react';
import styled from 'styled-components';
import { Link } from '@reach/router';

import { theme } from '../../theme';

export const TabNavStyled = styled.div`
    display: flex;
    flex-wrap: nowrap;
`;

export const TabsStyled = styled.div`
    display: flex;
    margin-bottom: ${(props) => props.theme.space.sm};
    position: sticky;
    top: 0;
    background-color: ${(props) => (props.bg ? props.bg : props.theme.colors.snow)};
    z-index: 10;
`;

export const Tab = styled((props) => {
    const isCurrent = ({ isPartiallyCurrent }) => {
        if (isPartiallyCurrent) {
            props.setActiveState(props.currentTab);
            return {
                style: { borderBottom: 'none', opacity: 1, color: theme.colors.white,background:theme.colors.royalBlue }
            };
        }
    };

    return (
        <Link getProps={isCurrent} {...props}>
            {props.children}
        </Link>
    );
})`
    padding: ${(props) => props.theme.space.xxs} ${(props) => props.theme.space.xs};
    color: ${(props) => props.theme.colors.royalBlue};
    font-weight: 700;

    margin-right: ${(props) => props.theme.space.xs};
    background: #F7F8F9;
    width:166px;
    height:48px;
    border-bottom: 1px solid ${(props) => props.theme.colors.snowDark};
    border-radius: 5px 5px 0px 0px;
    color: ${(props) => props.theme.colors.royalBlue};
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;

    &:last-of-type {
        margin-right: 0;
    }

    span {
        background: ${(props) => props.isActive?props.theme.colors.white:props.theme.colors.royalBlue};
        color: ${(props) => props.isActive?props.theme.colors.royalBlue:props.theme.colors.white};
        border-radius: 50%;
        width: 30px;
        height: 30px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        margin-left: 0.65rem;
    }
`;
