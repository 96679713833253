import { rgba } from "polished";
import { theme as chakraUiTheme } from "@chakra-ui/react";

export const breakpoints = ["576px", "768px", "992px", "1290px", "1600px"];

export const theme = {
  ...chakraUiTheme,
  colors: {
    ...chakraUiTheme.colors,
    white: "#FFF",
    black: "#000",
    royalBlue: "#0045F5",
    royalBlueLight: "#336AF7",
    blue: "#B0CEFF",
    blueLight: "#336AF7",
    blueGreyLight: "#EFF2F7",
    blueDark: "#8CB4F4",
    mint: "#0FE3C7",
    mintDark: "#0BD0B6",
    orange: "#FF7A69",
    navy: "#263859",
    gray: "#5B6B88",
    grayLight: "#8492A6",
    grayDark: "#273444",
    grayBorder: rgba("#638BC8", 0.15),
    snowDark: "#E9ECF1",
    snowDarkOpacity: rgba(233, 236, 241, 0.98),
    snow: "#F4F5F7",
    red: "#f47979",
    redDark: "#d24646",
    textDark: "#61728b",
    danger: "#E11900", 
    succession: "#656D78",
    grey: "#6F7C8F",
    textGrey: "#6F7782",
    required: "#DC2538"
  },
  fonts: {
    ...chakraUiTheme.fonts,
    serif: "Courier New",
    sans_serif: "Roboto",
    sans_serif_heading: "Abolition-Regular",
    open_sauce_two: "Open Sauce Two",
  },
  fontSizes: {
    ...chakraUiTheme.fontSizes,
    xs: "0.75rem", //12px
    sm: "0.875rem", //14px
    md: "1rem", //16px
    lg: "1.125rem", //18px
    xl: "1.25rem", //20px
    xxl: "1.375rem", //22px
  },
  space: {
    ...chakraUiTheme.space,
    xxs: "0.5rem", //8px
    xs: "1rem", //16px
    sm: "1.75rem", //24px
    sm_2: "1.875rem", //30px
    md: "2.5rem", //40px
    lg: "3.5rem", //56px
    xl: "4.5rem", //72px
    xxl: "6rem", //96px
  },
  lineHeights: {
    ...chakraUiTheme.lineHeights,
    base: "1.5",
  },
  transitions: {
    ...chakraUiTheme.transitions,
    default: "all 0.25s ease",
  },
  radii: {
    ...chakraUiTheme.radii,
    default: "4px",
    sm: "4px",
    md: "10px",
    lg: "16px",
    xl: "30px",
  },
  mediaQueries: {
    ...chakraUiTheme.mediaQueries,
    sm: `@media screen and (min-width: ${breakpoints[0]})`,
    md: `@media screen and (min-width: ${breakpoints[1]})`,
    lg: `@media screen and (min-width: ${breakpoints[2]})`,
    xl: `@media screen and (min-width: ${breakpoints[3]})`,
    xxl: `@media screen and (min-width: ${breakpoints[4]})`,
  },
};
