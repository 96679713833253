import styled from 'styled-components';
import { theme } from '../../theme';


export const MessageWrapper = styled.div `


        color: ${theme.colors.grayLight};
        font-family:'Roboto';
        h{
            font-size: ${theme.fontSizes.xxl};
            font-weight: 600;
        }
        p{
            margin: 0px;
            font-size: ${theme.fontSizes.lg};
        }
        .bold{
            font-weight: 600;
        }
        .container{
            position: relative;
        }

        .box{
            width: 40%;
            margin: 5% auto;
            position: relative;
            // padding: 5% 0;

        }

        .link{
            font-size: ${theme.fontSizes.lg}
        }
        
       

    }
   
`;

