import React from "react";
import { TagContainer } from "./TagContainer";
import { ImageBanner, CompanySectorStyled } from "./styled";

export const CompanySectors = ({ company, ...props }) => {
  const sectors = company.sector;
  const type = company.profile_type.id;

  const labelType = () => {
    if (type === 2) return "SPECIALTY";
    if (type === 3) return "TRADE";
    if (type === 4) return "DISCIPLINE/S";
  };
  const typeDatas = () => {
    if (type === 2) return company.speciality;
    if (type === 3) return company.trade;
    if (type === 4) return company.disciplines;
    return null;
  };

  return (
    <CompanySectorStyled>
      {company.banner_path && <ImageBanner src={company.banner_path} />}
      <h2>{"Relevant Sectors & Experience".toUpperCase()}</h2>
      <TagContainer datas={sectors} />
      {type === 4 && company.disciplinesFormatted.length > 0 && (
        <h2>EXPERTISE : {company.disciplinesFormatted[0].expertise}</h2>
      )}
      {type === 3 && (
        <TagContainer label={"SPECIALTY"} datas={company.speciality} />
      )}
      {typeDatas() && <TagContainer label={labelType()} datas={typeDatas()} />}
    </CompanySectorStyled>
  );
};
