import React from 'react';
import styled from 'styled-components';
import { ToastContainer, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import {ReactComponent as CrossIcon} from '../../icons/cross.svg';

const WrappedToastContainer = ({ className, ...rest }) => (
    <div className={className}>
        <ToastContainer {...rest} />
    </div>
);

export const CustomToast = styled(WrappedToastContainer).attrs({
    transition: Slide,
    autoClose: 3000,
    hideProgressBar: true,
    containerId: 'custom',
    enableMultiContainer: true,
    closeButton: <CrossIcon />
})`
    .Toastify__toast-container {
        height: 192px;
        width: 520px;
    }

    .Toastify__close-button {
        top: -5px;
    }

    .Toastify__toast {
        background-color: ${(props) => props.theme.colors.mint};

        &-body {
            margin: 0;
            height: 192px;
            width: 100%;
            padding: 32px;
            font-weight: 500;
            color: ${(props) => props.theme.colors.navy};
            font-weight: 400;

            h2{
                font-size: 32px;
                font-weight: 400;
                margin-top: 0px;
                font-family: ${(props) => props.theme.fonts.sans_serif_heading};
                text-transform: uppercase;
                margin-bottom: 16px;
            }

            p{
                font-size: 22px;
                line-height: 32px;
                font-family: ${(props) => props.theme.fonts.sans_serif};
                margin: 0px;
            }
        }

        svg{
            color: ${(props) => props.theme.colors.navy};
            height: 16px;
            width: 16px;
            position: absolute;
            top: 32px;
            right: 32px;
            path {
                stroke-width: 3px;
            }

        }
    }
`;
