import React from 'react';

import { IconBoxStyled, IconStyled } from './styled';

export const IconBox = ({ to, children, icon, color, logo, ...rest }) => {
    return (
        <IconBoxStyled {...rest} color={color} logo={logo}>
            <IconStyled color={color}>{icon}</IconStyled>
        </IconBoxStyled>
    );
};
