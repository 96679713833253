import React from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";

import { ViewRequestForm } from "./ViewRequestForm";
import { updateDocumentRequest } from "../../actions/companyActions";

export const ViewRequestModalComponent = ({
  companyId,
  current_company,
  accessRequest,
  onSuccessfulSubmit,
  updateDocumentRequest,
  ...props
}) => {
  const handleSubmit = async (values, { setErrors }) => {
    try {
      await updateDocumentRequest(companyId, values);
      onSuccessfulSubmit(); //closeModal
      if (values.is_approved === 0) {
        toast.success("Document Access Declined.");
      } else {
        toast.success("Document Access Approved.");
      }
    } catch (e) {
      const error = e.response.json();
      setErrors(error.errors);
      toast.error(error.message);
    }
  };

  return (
    <ViewRequestForm
      current_company={current_company}
      accessRequest={accessRequest}
      handleSubmit={handleSubmit}
      title="Request for Document Access"
    />
  );
};

const mapStateToProps = (state) => {
  const current_company = state.auth.current_company;
  const companyId = state.search.activeCompany.id;

  return {
    companyId: companyId,
    current_company: current_company,
  };
};

export const ViewRequestModal = connect(mapStateToProps, {
  updateDocumentRequest,
})(ViewRequestModalComponent);
