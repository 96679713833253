import React, { useEffect } from "react";

import { connect } from "react-redux";
import { Link } from "@reach/router";
import { MessageWrapper } from "./styled";
import { getCompany, updateSubscription } from "actions/companyActions";
import { getAccountTypes } from "actions/accountTypeActions";
import Skeleton from "react-loading-skeleton";

const PlanMessageComponent = ({
    nextBillingDate,
    accountTypes,
    accountTypeId,
    getAccountTypes,
    ...props
}) => {
    const company_id = localStorage.getItem("company_id");
    const newPrice = accountTypes.accountTypeData[accountTypeId - 1]?.total_amount;
    const prevIdState = props.location.state.idClicked;


    useEffect(() => {
        getAccountTypes();
        console.log("parse acc id prev", prevIdState);
        console.log("parse acc id", accountTypeId);
      },[getAccountTypes,accountTypeId, prevIdState]);


    return accountTypes.accountTypeData.length > 0 ? (

        <MessageWrapper>
            <div className="container">
            <div className="box">
            <h>{parseInt(prevIdState) === parseInt(accountTypeId)?"You have selected the same subscription plan!":"Thank you for Selecting a New Plan!"}</h>
            <br />
            {console.log("Same ID", typeof parseInt(prevIdState), typeof parseInt(accountTypeId))}
            {parseInt(prevIdState) !== parseInt(accountTypeId) && <p>Your new plan will be active in the next few minutes.</p>}
            <br />
            <p className="bold">Next Billing Date:</p>
            <p>Starting from your next billing date, 
                <span className="bold"> {nextBillingDate?nextBillingDate:" - "} </span>,
                you will be charged the full amount of 
                <span className="bold"> ${newPrice}</span> for your new subscription plan.
            </p>
            <br />
            <p>We value your continued partnership and are here to assist you in any
                way possible. If you have any questions or
                need further clarification, please do not hesitate to contact our support team.
            </p>
        <Link className="link" to={`/account/${company_id}/company-profile/account-management`}>Return to Account Management page</Link>
        </div>            
        </div>
        </MessageWrapper>)
        :(
            <Skeleton count={5} duration={0.5} />
        )
}
const mapStateToProps = (state) => {
    const next_billing_date = state.search.activeCompany.next_billing_date;
    const account_type = state.accountTypes;
    const account_type_id = state.search.activeCompany.account_type_id;
    console.log("amount", state.search.prorateAmount)
    return {
        nextBillingDate: next_billing_date,
        accountTypes: account_type,
        accountTypeId: account_type_id
    };
};

export const PlanMessage = connect(mapStateToProps, {getCompany, getAccountTypes, updateSubscription})(
    PlanMessageComponent
);