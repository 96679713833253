import React, { useState } from 'react';

import { TabNavStyled, Tab } from './styled';

export const TabNav = ({ data, tabs, ...rest }) => {
    const [activeState,setActiveState] = useState();
    return (
        <TabNavStyled {...rest}>
            {tabs.map((tab, i) => {
                return (
                    <Tab to={tab.link} isActive={activeState===i?true:false} key={i} currentTab={i} setActiveState={setActiveState}>
                        {tab.title} <span>{tab.count}</span>
                    </Tab>
                );
            })}
        </TabNavStyled>
    );
};
