import React from 'react';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import styled from 'styled-components';
import { useField, useFormikContext } from 'formik';

import { ReactComponent as SearchIcon } from './search.svg';
import { Error } from '../Error';

import { FormLabel } from '../TextInput';

export const InputWrap = styled.div`
    position: relative;

    svg {
        position: absolute;
        right: 1.25rem;
        top: 50%;
        transform: translateY(-50%);

        path {
            stroke: #336AF7;
        }
    }
`;

export const LocationStyled = styled.input`
    display: block;
    border-radius: ${(props) => props.theme.radii.default};
    background:${props=>props.disabled?'#f9f9f9':'white'};
    border: 1px solid #e7eaee;
    padding: 1rem;
    width: 100%;
    margin-bottom: 1rem;
    color: ${(props) => props.theme.colors.gray};
    position: relative;
    padding-right: 50px;
    height: 60px;
    border-color: ${props=>props.isError ? '#E11900' : ''};
    & > .google-places-autocomplete {
        font-size: 24px;
        color: rgb(255, 0, 0);
    }
`;

export const Suggestions = styled.div`
    position: absolute;
    top: 5.4rem;
    padding: 0.5rem 0;
    display: block;
    border-bottom-left-radius: ${(props) => props.theme.radii.default};
    border-bottom-right-radius: ${(props) => props.theme.radii.default};
    border: 1px solid #e7eaee;
    border-top: none;
    background: white;
    border: 1px solid #e7eaee;
    width: 100%;
    color: inherit;
    z-index: 3;
    width: calc(100% - 30px);
    font-size: ${(props) => props.theme.fontSizes.md};
    display: ${(props) => (props.children[1].length ? 'block' : 'none')};
`;

export const SuggestionItem = styled.div`
    cursor: pointer;
    transition: ${(props) => props.theme.transitions.default};
    padding: 0.65rem 1rem;

    &::hover {
        background-color: #deebff;
    }
`;
export const LocationWrapper = styled.div`
    transition: 0.15s all ease;
    margin-bottom: ${(props) => props.error ? '0.8rem' : '0.5rem'};
    padding-bottom: 2px;
    
`
export const Location = ({ label, required,errorBottom, name,disabled, initialValue = '',projectForm, ...props }) => {
    //eslint-disable-next-line
    const [field, meta] = useField(name);

    const { setFieldValue } = useFormikContext();

    const handleChange = (address = '') => {
        setFieldValue(name, { value: address, latitude:field.value?.latitude?.latitude,longitude:field.value?.longitude?.longitude });
    };

    const handleSelect = (address) => {
        setFieldValue(name, { value: address, latitude:field.value?.latitude?.latitude,longitude:field.value?.longitude?.longitude });

        let postcode = '';
        let streetNumber = '';
        let route = '';

        let addressSplit = address.split(',');
        let addressShortened = Array.isArray(addressSplit) ? addressSplit.splice(0, 2).join() : '';

        geocodeByAddress(address)
            .then((results) => {
                postcode = results[0].address_components.find((a) => a.types[0] === 'postal_code').short_name || '';
                streetNumber = results[0].address_components.find((a) => a.types[0] === 'street_number')
                    ? results[0].address_components.find((a) => a.types[0] === 'street_number').short_name
                    : '';
                route = results[0].address_components.find((a) => a.types[0] === 'route')
                    ? results[0].address_components.find((a) => a.types[0] === 'route').long_name
                    : '';

                let city = 'city';
                let state = 'state';
                let postcodeField = 'postcode';
                if(name.includes("[") && name.includes("]")){
                    let toFind = name.slice(0,name.indexOf("["));
                    city = name.replace(toFind,city);
                    state = name.replace(toFind,state);
                    postcodeField = name.replace(toFind,postcodeField);
                }
                if(!projectForm){
                    setFieldValue('address_line_1', streetNumber.length > 0 ? streetNumber + ' ' + route : route);
                    setFieldValue(
                        city,
                        results[0].address_components.find((a) => a.types[0] === 'locality').short_name || ''
                    );
                    setFieldValue(
                        state,
                        results[0].address_components.find((a) => a.types[0] === 'administrative_area_level_1')
                            .short_name || ''
                    );
                    setFieldValue(postcodeField, postcode);
                }
                return getLatLng(results[0]);
            })
            .then((loc) => {
                setFieldValue(name, {
                    value: addressShortened,
                    latitude: loc.lat ? loc.lat : field.value?.latitude ,
                    longitude: loc.lng ? loc.lng :  field.value?.longitude
                });
            })
            .catch((error) => console.error('Error', error));
    };

    //commenting this out to allow for custom addresses beyond suggestions
    // const handleOnBlur = (event) => {
    //     setFieldValue(name, field.value.latitude !== undefined ? field.value : '');
    // };

    const searchOptions = {
        componentRestrictions: {}
    };


    return (
        <LocationWrapper error={meta.error} className={props.className}>
            <PlacesAutocomplete
                value={field.value?.value ? field.value.value : ''}
                onChange={handleChange}
                onSelect={handleSelect}
                searchOptions={searchOptions}
                autoComplete={false}
                highlightFirstSuggestion={true}
                
            >
                {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (<>
                        {label && (
                            <FormLabel htmlFor={props.id || props.name}>
                                {label}
                                {required && <span>*</span>}
                            </FormLabel>
                        )}
                        <InputWrap>
                            <LocationStyled isError = {meta.touched && meta.error}
                                {...getInputProps({
                                    placeholder: 'Search address',
                                    className: 'location-search-input',
                                    autoComplete: 'new-password',
                                    list: 'autocompleteOff',
                                    disabled:disabled
                                })}
                            ></LocationStyled>
                            <SearchIcon />
                        </InputWrap>

                        <Suggestions>
                            {loading && <p>Loading...</p>}
                            {suggestions.map((suggestion, index) => {
                                const className = suggestion.active ? 'suggestion-item--active' : 'suggestion-item';
                                // inline style for demonstration purpose

                                return (
                                    <SuggestionItem
                                        key={index}
                                        {...getSuggestionItemProps(suggestion, {
                                            className,
                                            key: index
                                        })}
                                    >
                                        <span>{suggestion.description}</span>
                                    </SuggestionItem>
                                );
                            })}
                        </Suggestions>

                        <Error errorBottom={errorBottom} meta={meta} />
                        </>
                )}
            </PlacesAutocomplete>
        </LocationWrapper>
    );
};
