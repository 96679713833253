import React from 'react';

import { FormPage } from 'components/FormPage';
import { SupplierSearchForm } from 'components/SupplierSearchForm';

export const SearchForm = ({ searchCompanies, projectId, ...props }) => {
    return (
        <FormPage heading='Search for a supplier' marginBottom="20px">
            <SupplierSearchForm searchCompanies={searchCompanies} projectId={projectId} />
        </FormPage>
    );
};
