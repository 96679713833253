import styled from 'styled-components';

export const AddressCardWrap = styled.div`
    .address-header{
        margin-top:${props=>props.theme.space.sm};
        span{
            font-size:${props=>props.theme.fontSizes.sm};
            padding-left:${props=>props.theme.fontSizes.sm};
            color:${props=>props.theme.colors.grayLight};
            font-weight:bold;
        }
    }
    .address-wrap{
        margin-top:7px;
        background-color:${props=>props.theme.colors.white};
        box-shadow: 0px 4px 16px rgba(208, 210, 212, 0.1);
        padding:15px 15px;
        .address-data{
            border-right: 1px solid ${props=>props.theme.colors.snowDark};
            padding: 20px 0px;
            
            span{
                padding-left:10px;
                display:inline-block;
                color:${props=>props.theme.colors.gray};
                font-size: 14px;
            }
        }
        .last{
            border-right:none;
        }
    }
`