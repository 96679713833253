import styled from 'styled-components';

import { theme } from '../../theme';

export const ProjectLinkStyled = styled.div`
    display: table-row;
    background: white;
    box-shadow: 0px 4px 16px rgba(208, 210, 212, 0.1), 0px 4px 44px #edeef0;
    border-collapse: separate;
    border-radius: 15px;

    .project-link {
        &__inner {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            align-items: center;
            padding: 8px;
        }

        &__title {
            margin: 0 0 0 1.75rem;
            color: ${(props) => props.theme.colors.grayDark};
            font-size: ${(props) => props.theme.fontSizes.md};
            display: table-cell;
        }

        &__specs {
            display: flex;
            align-items: center;
            margin-left: auto;
            padding-right: 1rem;
        }

        &__btn-cell {
            text-align: right;
        }

        &__cell {
            display: table-cell;
            padding: 8px 0;
            vertical-align: middle;

            &:first-of-type {
                border-top-left-radius: ${(props) => props.theme.radii.default};
                border-bottom-left-radius: ${(props) => props.theme.radii.default};
                padding-left: 16px;
            }

            &:last-of-type {
                border-top-right-radius: ${(props) => props.theme.radii.default};
                border-bottom-right-radius: ${(props) => props.theme.radii.default};
                padding-right: 16px;
                width: 150px;
            }

            &--img {
                width: 90px;
                min-width: 90px;
            }

            &--title {
                padding: 8px ${(props) => props.theme.space.sm};
                font-weight: 600;

                ${theme.mediaQueries.xxl} {
                    width: 20%;
                }
            }

            &--spec {
                padding: 8px 1.25rem 8px 0;

                .project-link__cell-inner {
                    border-left: 1px solid ${(props) => props.theme.colors.grayBorder};
                    padding: 0 0 0 1.25rem;
                    display: flex;
                    flex-direction: column;
                    text-transform: capitalize;
                }

                & + .project-link__cell--spec {
                    margin-left: 1.25rem;
                }

                h5,
                p {
                    margin: 0;
                    font-size: ${(props) => props.theme.fontSizes.xs};
                }

                h5 {
                    color: ${(props) => props.theme.colors.gray};
                    font-weight: 400;
                    margin-bottom: 0;
                }

                p {
                    color: ${(props) => props.theme.colors.grayDark};
                    font-weight: 500;
                }
            }
        }
    }
`;
