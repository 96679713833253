import React from "react";
import * as Yup from "yup";
import { Formik, Form } from "formik";

import { Heading } from "../Heading";
import { TextInput } from "../FormInputs/TextInput";
import { Submit } from "../FormInputs/Submit";

import { OnSubmitValidationError } from "../../utils/OnSubmitValidationError";
import { PaymentFormWrapper, FormLabel } from "../SignUpForm/styled";
import { UpdateCardDetailsWrapper } from "./styled";
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";
import { FlashError } from "../FormInputs/FlashError";
import { navigate } from "@reach/router";

export const UpdateCardDetails = ({ ...props }) => {
  const stripe = useStripe();
  const elements = useElements();
  const user_id = props.user.id ? props.user.id : null;
  const CardStyle = {
    base: {
      fontSize: "18px",
      color: "#6F7C8F",
      border: "1px solid rgba(132, 146, 166, 0.2)",
      "::placeholder": {
        color: "#6F7C8F",
      },
    },
    invalid: {
      color: "#6F7C8F",
    },
    complete: {
      color: "#6F7C8F",
    },
  };

  const handleSubmit = async (values, setStatus, setSubmitting) => {
    setSubmitting(true);
    setStatus(null);

    if (!stripe || !elements) {
      setSubmitting(false);
      return;
    }
    const cardNumber = elements.getElement(CardNumberElement);

    const token = await stripe.createToken(cardNumber, {
      name: values.card_name,
    });
    const { paymentMethod, error } = await stripe.createPaymentMethod({
      type: "card",
      card: cardNumber,
      billing_details: {
        name: token.name,
        address: {
          city: token.address_city,
          country: token.country,
          postal_code: token.address_zip,
          state: token.address_state,
        },
      },
    });
    if (error) {
      setStatus(error.message);
    } else {
      try {
        values.token_id = token.token.id;
        values.id = user_id;
        const result = await props.handleEditDetails(
          values,
          setSubmitting,
          paymentMethod.card.last4
        );
        if (!result.success) setStatus(result.message);
        if (props.company.account_status === "cancelled") {
          navigate("account-management");
        }
      } catch (e) {
        const error = await e;
        console.log(error);
        setSubmitting(false);
      }
    }
    setSubmitting(false);
  };

  return (
    <UpdateCardDetailsWrapper>
      <Heading marginBottom="16px">
        <span>Update Card details</span>
      </Heading>
      <p className="instructions">Please enter your card details below</p>

      <Formik
        enableReinitialize
        initialValues={{ card_value: "" }}
        validationSchema={Yup.object({
          card_name: Yup.string().required(),
        })}
        onSubmit={(values, { setStatus, setSubmitting }) => {
          handleSubmit(values, setStatus, setSubmitting);
        }}
      >
        {(props) => {
          const { setFieldValue, isSubmitting, status, setStatus } = props;
          const onSubmitValidationError = () => {
            setStatus("required");
          };
          return (
            <Form>
              <div className="row">
                <TextInput
                  label="NAME ON CARD"
                  placeholder="Enter Name On Card"
                  name="card_name"
                  type="text"
                  required
                  className="col xl-12 text-mr"
                  setFieldValue={setFieldValue}
                />
                <PaymentFormWrapper>
                  <div className="col xl-12">
                    <FormLabel>Credit Card Number *</FormLabel>
                    <div className="card-number">
                      <CardNumberElement
                        className="stripe_card_field"
                        options={{
                          showIcon: true,
                          placeholder: "xxxx xxxx xxxx xxxx",
                          style: CardStyle,
                          disabled: isSubmitting,
                        }}
                      />
                    </div>
                  </div>

                  <div className="row-fields">
                    <div className="block-field">
                      <FormLabel>Expiry Date *</FormLabel>
                      <div className="card-number">
                        <CardExpiryElement
                          className="stripe_card_field"
                          options={{
                            placeholder: "mm / yy",
                            style: CardStyle,
                            disabled: isSubmitting,
                          }}
                        />
                      </div>
                    </div>
                    <div className="block-field">
                      <FormLabel>CVV *</FormLabel>
                      <div className="card-number">
                        <CardCvcElement
                          className="stripe_card_field"
                          options={{
                            placeholder: "xxx",
                            style: CardStyle,
                            disabled: isSubmitting,
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </PaymentFormWrapper>
                <div className="col xl-12">
                  {status && (
                    <FlashError
                      heading={
                        errorMessages[status]?.heading ||
                        "Invalid Card Information"
                      }
                      text={errorMessages[status]?.description || status}
                      margin="20px 0px 0px 0px"
                    />
                  )}
                </div>
                <div className="col xl-12">
                  <Submit
                    type="submit"
                    widthExpand
                    isSubmitting={isSubmitting}
                    text="Update Card Details"
                    marginTop="26px"
                    submittingText="Sending..."
                  />
                </div>
              </div>
              <OnSubmitValidationError callback={onSubmitValidationError} />
            </Form>
          );
        }}
      </Formik>
    </UpdateCardDetailsWrapper>
  );
};

const errorMessages = {
  required: {
    heading: "* Required Field",
    description: "There are incomplete required fields, Please complete them.",
  },
  backend: {
    heading: "Request Not Processed",
    description: "Unable to process your request",
  },
};
