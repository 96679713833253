import ReactToPrint from "react-to-print";
import { useLocation } from "@reach/router";
import React, { useEffect } from "react";
import { PrintableInvoice } from "./PrintableInvoice";
import { ReactComponent as PrintIcon } from "../../icons/print.svg";

export const Invoice = ({ company }) => {
  const location = useLocation();
  const target_invoice = location.state?.invoice;
  const prevItem = location.state?.prevItem;
  const prevLabel = location.state.prevLabel;
  const invoiceLabel = location.state.invoiceLabel;
  const componentRef = React.useRef();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const styleButton = {
    display: "flex",
    justifyContent: "end",
    marginRight: "30px",
    cursor: "pointer",
  };

  return (
    <div>
      <ReactToPrint
        trigger={() => (
          <div id="icon-print" style={styleButton}>
            <PrintIcon />
          </div>
        )}
        content={() => componentRef.current}
      />
      <PrintableInvoice
        componentRef={componentRef}
        ref={componentRef}
        target_invoice={target_invoice}
        company={company}
        prevItem={prevItem}
        prevLabel={prevLabel}
        invoiceLabel={invoiceLabel}
      />
    </div>
  );
};
