/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect, useRef } from "react";
import { useDropzone } from "react-dropzone";
import isUrl from "is-url";
import { useField } from "formik";
import { toast } from "react-toastify";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import { ImageUploadStyled, ImageContainer, DeleteStyled } from "./styled";
import { FormLabel } from "../TextInput";
import { Error } from "../Error";
import { reorderList } from "utils/reorderList";

export const Gallery = ({ setFieldValue, name, images, max, isSubmitting, ...props }) => {
  const [files, setFiles] = useState(images || []);
  //eslint-disable-next-line
  const [field, meta] = useField(name);
  const [isDragging,setIsDragging] = useState(false)

  const imageChecker = (arr1,arr2) => arr2.join("") === arr1.join("")
  const fileChecker = () => files?.some((file)=> file instanceof File)

  //set the formik field value only once the internal state has been set
  useEffect(() => {
    if(!imageChecker(files,field.value)){
      setFieldValue(name, files);
    }// eslint-disable-next-line
  }, [files]);

  useEffect(()=>{
    if(!imageChecker(files,field.value) && fileChecker()){
      setFiles(field.value)
    }// eslint-disable-next-line
  },[field.value])

  const imageEl = useRef(null);

  const handleImgClick = (event, id) => {
    event.stopPropagation();

    //copy state array and remove index, then re-set
    let filesModified = [...files];
    filesModified.splice(id, 1);
    setFiles(filesModified);
  };

  const maxFileSize = 10485760;

  function nameSpecialValidator(file) {
    const specialRegex = /[!@#$%^&*+{}[\]:;<>,?~\\/]/
    if (specialRegex.test(file.name)) {
      return {
        code: "special-characters",
        message: `File name have specials characters`
      };
    }
    return null
  }

  const { getRootProps, getInputProps } = useDropzone({
    multiple: true,
    disabled: isSubmitting,
    accept: "image/jpg",
    maxSize: maxFileSize,
    noClick: isDragging,
    validator : nameSpecialValidator,
    onDrop: (acceptedFiles, rejectedFiles) => {
      if (max && field.value.length + acceptedFiles.length > max) {
        return toast.error(`Maximum of ${max} files allowed.`);
      }

      
      rejectedFiles.forEach((file) => {
        file.errors &&
          file.errors.forEach(
            (error) =>{
              console.log("uploaderror",error)
              if(error.code === "file-too-large"){
                toast.error(`Maximum file size is ${maxFileSize / 1048576}MB.`)
              }else if(error.code === "special-characters"){
                toast.error(`Image upload unsuccessful. Please ensure image file names do not contain any special characters.`)
              }
            } 
          );
      });

      setFiles([
        ...files,
        ...acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        ),
      ]);
    },
  });

  return (
    <div className={props.className}>
      <FormLabel>{props.label}</FormLabel>
      <DragDropContext  
      onDragEnd={(result) => { reorderList(files,result,setFiles); setIsDragging(false) }}
      onDragStart={()=> { setIsDragging(true) }}
      >
        <Droppable droppableId="droppable-image" direction="horizontal">
        {(provided) => (
          <ImageUploadStyled 
          gallery {...getRootProps({ className: "dropzone",onDragLeave: e => e.stopPropagation() })} 
          isSubmitting={isSubmitting}
          ref={provided.innerRef}
          {...provided.droppableProps}
          >
          <input {...getInputProps()} />
  
          {files && files.length
            ? files.map((file, i) => {
                //use isUrl function to check if the value is just a plain image URL which has been previously uploaded
                // in that case, it doesn't have a preview property
                return (
                  <Draggable
                       key={i}
                       draggableId={`${i}`}
                       index={i}
                     >
                      {(provided)=>(
                        <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                        <ImageContainer
                        key={i}
                        ref={imageEl}
                      >
                        <img
                          src={isUrl(file) ? file : file?.preview}
                          alt={file?.name}
                        />
                        <DeleteStyled onClick={(e) => {if (!isSubmitting) handleImgClick(e, i)}} />
                      </ImageContainer>
                      </div>
                      )}

                  </Draggable>
                  
                );
              })
            : "Drag and drop an image here, or click to select."}
        </ImageUploadStyled>
  )}
        </Droppable>
      </DragDropContext>
      
      <p style={{ marginBottom: "20px", fontSize: "13px" }}>
        A maximum of {max} images can be uploaded for each project. The file
        size for each image should not exceed {maxFileSize / 1048576}MB.
      </p>

      <Error meta={meta} />
    </div>
  );
};
