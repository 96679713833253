import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Formik } from "formik";

import {
  SearchBoxSubmit,
  SearchBoxInputContainer,
  SearchBoxInputStyled,
  SectorsStyled,
  FormStyled,
  SearchBoxButtons,
  CancelButton,
  SearchBoxContainer,
  Results,
} from "./styled";
import { ReactComponent as SearchIcon } from "../../icons/search-2.svg";
import { ReactComponent as CancelIcon } from "../../icons/cross.svg";
import { getSectors } from "../../actions/sectorActions";

const SearchBoxComponent = ({
  handleFormSubmit,
  getSectors,
  sectors,
  searchInput,
  sectorFilter,
  keywordFilter,
  showPorojectsCount,
  multiSector,
  className,
  whiteInput,
  resultsCount,
  companyId,
  ...props
}) => {
  const initialValues = props.initialValues;

  useEffect(() => {
    getSectors(companyId);
  }, [getSectors, companyId]);

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={(values) => {
        handleFormSubmit(values);
      }}
    >
      {(props) => {
        const { setFieldValue, values, handleSubmit } = props;

        //seems like the only way to re-submit form with altered values
        const cancelClick = () => {
          setFieldValue("search", "", false);
          handleFormSubmit({ ...values, search: "" });
        };

        const removeKeyword = () => {
          setFieldValue("keyword", "", false);
          handleFormSubmit({ ...values, keyword: "" });
        };

        return (
          <SearchBoxContainer className={className}>
            {resultsCount > 0 && (
              <Results>
                {resultsCount} project{resultsCount > 1 && "s"} found
              </Results>
            )}

            <FormStyled>
              {searchInput && (
                <SearchBoxInputContainer>
                  <SearchBoxInputStyled
                    type="text"
                    name="search"
                    placeholder="Search by name"
                    white={whiteInput}
                  />

                  <SearchBoxButtons>
                    {values.search && (
                      <CancelButton type="button" onClick={cancelClick}>
                        <CancelIcon />
                      </CancelButton>
                    )}

                    <SearchBoxSubmit type="submit" name="submit">
                      <SearchIcon />
                    </SearchBoxSubmit>
                  </SearchBoxButtons>
                </SearchBoxInputContainer>
              )}
              {keywordFilter && (
                <SearchBoxInputContainer>
                  <SearchBoxInputStyled
                    type="text"
                    name="keyword"
                    placeholder="Search by keyword"
                    white={whiteInput}
                  />

                  <SearchBoxButtons>
                    {values.keyword && (
                      <CancelButton type="button" onClick={removeKeyword}>
                        <CancelIcon />
                      </CancelButton>
                    )}

                    <SearchBoxSubmit type="submit" name="submit">
                      <SearchIcon />
                    </SearchBoxSubmit>
                  </SearchBoxButtons>
                </SearchBoxInputContainer>
              )}
              {sectorFilter && (
                <SectorsStyled
                  noLabel
                  name="sector"
                  setFieldValue={setFieldValue}
                  defaultValue={initialValues.sector}
                  values={values}
                  singleValue={!multiSector}
                  placeholder={`Filter by sector${multiSector ? "s" : ""}`}
                  clearable
                  showPorojectsCount={showPorojectsCount}
                  onChange={(option) => {
                    if (multiSector) {
                      const valuesArray = option
                        ? option.map((option) => option.value)
                        : [];
                      setFieldValue("sector", valuesArray);
                    } else {
                      setFieldValue("sector", option && option.value);
                    }
                    handleSubmit();
                  }}
                />
              )}
            </FormStyled>
          </SearchBoxContainer>
        );
      }}
    </Formik>
  );
};

const mapStateToProps = (state) => {
  const sectorData = state.sectors.sectorData;

  return {
    sectors: sectorData,
    initialValues: {
      search: "",
      sector: "",
      keyword: "",
    },
  };
};

export const SearchBox = connect(mapStateToProps, { getSectors })(
  SearchBoxComponent
);
