import React, { useCallback, useState } from "react";
import api from "api";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import styled from "styled-components";
import _debounce from "lodash.debounce";

import { TextInput } from "../TextInput";
import { Location } from "../Location";
import { Sectors } from "../Sectors";
import { FormHeading } from "../../FormHeading";
import { Submit } from "../Submit";
import { Currency } from "../Currency";
import { Autosuggest } from "../Autosuggest";

function getApiWithToken() {
  const token = localStorage.getItem("access_token");
  return api.extend({
    hooks: {
      beforeRequest: [
        (request) => {
          request.headers.set("Authorization", `Bearer ${token}`);
        },
      ],
    },
  });
}

export const ResourceProjectFormStyled = styled.div`
  background-color: white;
  padding: ${(props) => props.theme.space.sm_2};
`;

export const ResourceProjectForm = ({
  document,
  onSubmit,
  isSubmitting,
  initialValues,
  values,
  title,
  index,
  onSave,
  ...props
}) => {
  const [projectsSearchResults, setProjectsSearchResults] = useState([]);

  const companyId = window.location.pathname.split("/")[2];
  // eslint-disable-next-line
  const handleProjectNameChange = useCallback(
    _debounce(async (value) => {
      const response = await getApiWithToken()
        .get(`past-projects?company_id=${companyId}&search=${value}`)
        .json();
      setProjectsSearchResults(response.data);
    }, 250),
    []
  );

  return (
    <ResourceProjectFormStyled>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={Yup.object({
          name: Yup.string().required(),
          role: Yup.string().required(),
          sector_id: Yup.string().required(),
          location: Yup.object().required(),
          value: Yup.string().required(),
        })}
        onSubmit={onSubmit}
      >
        {(props) => {
          const { values, isSubmitting, setFieldValue } = props;
          const setProjectFieldValues = (project) => {
            project?.sector?.id &&
              setFieldValue("sector_id", project.sector.id);
            project?.location && setFieldValue("location", project.location);
            project?.value.toString() &&
              setFieldValue("value", project.value.toString());
          };

          return (
            <Form>
              <FormHeading text={title || "Document Detail"} />

              <div className="row">
                <Autosuggest
                  label="Project Name"
                  name="name"
                  type="text"
                  className="col xl-12"
                  queryFunction={(value) => handleProjectNameChange(value)}
                  searchResults={
                    projectsSearchResults && projectsSearchResults.slice(0, 5)
                  }
                  handleClick={setProjectFieldValues}
                />

                <Sectors
                  label="Primary Sector"
                  required="true"
                  name="sector_id"
                  values={values}
                  singleValue
                  className="col xl-12"
                />

                <TextInput
                  label="Your Role on the Project"
                  required="true"
                  name="role"
                  type="text"
                  className="col xl-12"
                />

                <Location
                  label="Project Location"
                  required="true"
                  name="location"
                  initialValue={initialValues.location}
                  className="col xl-12"
                />

                <Currency
                  label="Project Construction Value (AUD)"
                  required="true"
                  name="value"
                  type="number"
                  lang="en_EN"
                  setFieldValue={setFieldValue}
                  values={values}
                  className="col xl-12"
                />
              </div>

              <Submit
                type="submit"
                isSubmitting={isSubmitting}
                text="Continue"
                submittingText="Processing..."
              />
            </Form>
          );
        }}
      </Formik>
    </ResourceProjectFormStyled>
  );
};
