import React from 'react';
import { SearchTagStyled } from './styled';

export const SearchTag = ({ title, value, list }) => {
    if (!value || value === undefined) {
        return '';
    }

    return (
        <SearchTagStyled>
            <p className='title'>{title}</p>

            <p className='title title--normal'>
                {value && Array.isArray(value) && value.length
                    ? // eslint-disable-next-line
                      value.map((item, index) => {
                          if (item !== undefined) {
                              return `${item.label}${index < value.length - 1 ? ', ' : ''}`;
                          }
                      })
                    : value}
            </p>
        </SearchTagStyled>
    );
};
