import React, { useState } from "react";
import { connect } from "react-redux";
import { navigate } from "@reach/router";
import { toast } from "react-toastify";

import { ProjectForm } from "./ProjectForm";
import {
  editPastProject,
  deletePastProject,
} from "../../actions/companyActions";
import { ConfirmDelete } from "../ConfirmDelete";
import { getTradeValues} from "../../actions/companyActions";
import { useEffect } from "react";

export const EditProjectComponent = ({
  profileTypeId,
  pastProjects,
  projectId,
  deletePastProject,
  companyId,
  ...props
}) => {
  const [initialValues,setInitialValues] = useState(props.initialValues);
  const [autoUpdateForm, setAutoUpdateForm] = useState(false);
  const [confirmModalIsOpen, setConfirmModalIsOpen] = useState(false);
  const [errorStatus, setErrorStatus] = useState(false);
  const [savedField, setSavedField] = useState(props.initialValues);

  useEffect(() => {
    getTradeValues();
  },[])

  useEffect(() => {
    if(JSON.stringify(savedField.images) !== JSON.stringify(initialValues.images)){
      setInitialValues(savedField)
    }
    if(JSON.stringify(savedField.testimonials) !== JSON.stringify(initialValues.testimonials)){
      setInitialValues(savedField)
    }
    if(JSON.stringify(savedField.trade_ids) !== JSON.stringify(initialValues.trade_ids)){
      setInitialValues(savedField)
    }
  },[savedField,initialValues])


  const handleSubmit = async (values, { setErrors, setStatus }) => {
    try {
      await props.editPastProject(projectId, values, autoUpdateForm, setSavedField,companyId,props.tradeValues,);
    } catch (error) {
      toast.error("Sorry, an error has occurred.");
      setErrorStatus(true);
      setStatus("Sorry, an error has occurred.");
    }
  };

  const handleDelete = async () => {
    try {
      await deletePastProject(projectId);
      navigate("../");
      toast.success("Project deleted successfully.");
    } catch (e) {
      toast.error("Sorry, an error has occurred.");
    }
  };

  return (
    <>
      <ProjectForm
        initialValues={initialValues}
        handleSubmit={handleSubmit}
        handleDelete={() => setConfirmModalIsOpen(true)}
        isRequesting={props.isRequesting}
        profileTypeId={profileTypeId}
        setAutoUpdateForm={setAutoUpdateForm}
        errorStatus={errorStatus}
        setErrorStatus={setErrorStatus}
        autoUpdateForm={autoUpdateForm}
        editForm={true}
        savedField={savedField}
        setInitialValue={setInitialValues}
        setSavedField={setSavedField}
      />

      <ConfirmDelete
        isOpen={confirmModalIsOpen}
        onRequestClose={() => setConfirmModalIsOpen(false)}
        onConfirm={() => handleDelete()}
        onCancel={() => setConfirmModalIsOpen(false)}
        itemName="project"
        btnText={["Delete", "Deleting..."]}
      />
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  const id = ownProps.projectId;
  const { pastProjects, tradeValues } = state.companyProfile;

  const values =
    pastProjects.find((project) => parseInt(project.id) === parseInt(id)) || {};
    const checkTrade = () => {
      const optionIndex = tradeValues.findIndex((option) => (
          values.trade_value === option.name
      ))
      return optionIndex === -1? 1 :tradeValues[optionIndex].id;
    }
  return {
    companyId : state.search.activeCompany.id,
    pastProjects: pastProjects,
    tradeValues: tradeValues,
    isRequesting: state.companyProfile.isRequesting,
    initialValues: {
      name: values.name || "",
      location: values.location || "",
      summary: values.summary?.replace(/\r/g, '') || "",
      value: values.value?.toString() || "0",
      trade_value: checkTrade() || 1,
      year_completed: values.year_completed?.toString() || "",
      funding_type: values.funding_type || "",
      site_area: values.site_area || "",
      gross_floor_area: values.gross_floor_area || "",
      scope: values.scope || [],
      client_name: values.client_name || "",
      head_contractor: values.head_contractor || "",
      sector_id: values.sector ? values.sector.id : "",
      images: values.images || [],
      innovation_value_summary: values.innovation_value_summary?.replace(/\r/g, '') || "",
      awards: values.awards?.replace(/\r/g, '') || "",
      testimonials: values.testimonials || "",
      status: values.status,
      procurement_method_ids: Array.isArray(values.procurement_methods)
        ? values.procurement_methods.map((method) => method.id)
        : [],
      trade_ids: Array.isArray(values.trades)
        ? values.trades.map((trade) => trade.id)
        : [],
    },
  };
};

export const EditProject = connect(mapStateToProps, {
  editPastProject,
  deletePastProject,
})(EditProjectComponent);
